import { useCallback, useContext, useEffect, useState } from 'react';
import Button from '../../components/bootstrap/Button';
import { useSocket } from '../../contexts/socketContext';
import { unreadNotificationsFunc } from '../../utils/unreadNotifications';

import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import { demoPages } from '../../menu';
import { getAllUsers } from '../../odooApi/employee';
import { fetchChatFromAPI, getMessage, getUserChats } from '../../reminderApi/Chat';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';

const ChatHeaderIcon = () => {
  const { userData } = useContext(AuthContext);
  const { socket, setNotifications, notifications } = useSocket();
  const [allUsers, setAllUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);
  const [chats, setChats] = useState([]);
  const [newMsg, setNewMsg] = useState('');
  const [firstInteraction, setFirstInteraction] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [currentMessages, setCurrentMessages] = useState([]);
  const navigate = useNavigate();
  const { chatId } = useParams(0); // Extract chatId from URL params

  useEffect(() => {
    if (userData?.id && userData?.password) {
      getAllUsers(userData?.password, (users) => {
        setAllUsers(users);
        setLoading(false);
      }, (error) => {
        console.error("Failed to fetch all users:", error);
        setLoading(false);
      });

      getUserChats(userData, (data) => {
        setChats(data);
      });
    }
  }, [userData]);
  useEffect(() => {
    if (!socket) return;

    const handleUpdateUnreadCounts = (updatedCounts) => {
      setUnreadCounts(updatedCounts);
    };

    socket.on('updateUnreadCounts', handleUpdateUnreadCounts);

    return () => {
      socket.off('updateUnreadCounts', handleUpdateUnreadCounts);
    };
  }, [socket]);
  useEffect(() => {
    if (currentChat) {
      const chatNotifications = notifications.filter(n => n.chatId === currentChat._id);
      const unreadCount = chatNotifications.filter(n => !n.isRead).length;
      setUnreadCounts(prevCounts => ({
        ...prevCounts,
        [currentChat._id]: unreadCount
      }));
    }
  }, [currentChat, notifications]);



  useEffect(() => {
    if (!socket) return;

    const handleNotification = (notification) => {
      setNotifications(prev => {
        const isDuplicate = prev.some(notif =>
          notif.senderId === notification.senderId && notif.date === notification.date
        );

        if (isDuplicate) {
          return prev;
        }

        const isChatOpen = currentChat?.members?.some(memberId => memberId === notification.senderId) ?? false;

        return isChatOpen
          ? [{ ...notification, isRead: true }, ...prev]
          : [notification, ...prev];
      });
    };


    socket.on('getNotification', handleNotification);

    return () => {
      socket.off('getNotification', handleNotification);
    };
  }, [socket, setNotifications, currentChat]);

  const unreadNotifications = unreadNotificationsFunc(notifications);

  const modifiedNotifications = notifications?.map((n) => {
    if (loading) {
      return {
        ...n,
        senderName: 'Loading...',
      };
    }

    const sender = (allUsers || []).find((user) => user.id.toString() === n.senderId.toString());

    if (!sender) {
      return {
        ...n,
        senderName: 'Unknown User',
      };
    }

    return {
      ...n,
      senderName: sender.name,
    };
  }) || [];

  /*const toggleNotificationBox = () => {
    setIsOpen(!isOpen);
  };*/
  const toggleOffCanvas = () => {
    //console.log("Toggling OffCanvas");
    setIsOpen(prev => !prev);
  };

  const openChat = async (chat) => {
    console.log("Opening chat:", chat);

    if (!chat || !chat._id) {
      console.error('Invalid chat object:', chat);
      return;
    }

    setNewMsg(""); // Reset new message input
    setCurrentChat(chat); // Set current chat
    setUnreadCounts(prevCounts => ({
      ...prevCounts,
      [chat._id]: 0
    }));

    try {
      // Fetch messages and handle success with a callback
      await new Promise((resolve, reject) => {
        getMessage(chat, (messages) => {
          console.log("Fetched messages:", messages);
          setCurrentMessages(messages); // Update state with fetched messages
          resolve();
        });
      });
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  const handleNotificationClick = async (notification) => {
    if (!userData?.id) {
      console.error('User data is missing');
      return;
    }

    const existingChat = chats.find(chat =>
      chat.members.includes(parseInt(notification.senderId)) &&
      chat.members.includes(parseInt(userData.id))
    );

    if (existingChat) {
      await openChat(existingChat);
      console.log('exiiiiiiiiiiiiiist', existingChat._id)
      navigate(`/chat/${existingChat._id}`); // Navigate to the chat page
    } else {
      try {
        const newChat = await fetchChatFromAPI(userData.id, notification.senderId);
        if (newChat) {
          await openChat(newChat);
          navigate(`/chat/${newChat._id}`); // Navigate to the new chat page
        } else {
          console.error('Failed to fetch or create a new chat.');
        }
      } catch (error) {
        console.error('Failed to fetch chat:', error);
      }
    }

    markNotificationsAsRead(notification, chats, notifications);
    socket.emit('updateUnreadCounts', unreadCounts); // Notify other components
    setIsOpen(false);
  };
  useEffect(() => {
    const handleUserInteraction = () => {
      setFirstInteraction(true);
    };
    document.addEventListener('click', handleUserInteraction);

    if (firstInteraction && unreadNotificationsFunc(notifications).length) {
      const audio = new Audio(require('../../assets/Chocolate_Notification_Tone.mp3'));
      audio.type = 'audio/mp3';
      audio.preload = 'auto';
      audio.play();
    }

    return () => document.removeEventListener('click', handleUserInteraction);
  }, [notifications, firstInteraction]);

  const handleGoToChatPage = () => {
    setIsOpen(false);
    navigate(`../${demoPages.chat.subMenu.withListChat.path}`);
  };

  const markNotificationsAsRead = useCallback((notification, userChats, notifications) => {
    if (!Array.isArray(userChats) || !Array.isArray(notifications)) {
      console.error("userChats or notifications is not an array");
      return;
    }

    if (!notification || !notification.senderId) {
      console.error("Notification or notification.senderId is missing");
      return;
    }

    const senderIdStr = notification.senderId.toString();
    const userIdStr = userData.id.toString();

    const desiredChat = userChats.find((chat) => {
      if (!chat || !Array.isArray(chat.members)) {
        console.warn("Chat or chat.members is invalid for chat:", chat);
        return false;
      }

      const chatMembers = chat.members
        .map(member => member ? member.toString() : null)
        .filter(member => member !== null);

      const chatContainsBoth = chatMembers.includes(userIdStr) && chatMembers.includes(senderIdStr);
      return chatContainsBoth;
    });

    const updatedNotifications = notifications.map(n =>
      n.senderId ? n.senderId.toString() === senderIdStr
        ? { ...n, isRead: true }
        : n
        : n
    );

    setNotifications(updatedNotifications);
    setCurrentChat(desiredChat || null);
  }, [setNotifications, userData.id, unreadCounts]);

  return (
    <div className="col-auto mt-4">
      <div className=" position-relative animate__animated animate__infinite animate__slower" onClick={toggleOffCanvas}>
        <Button
          size={'lg'}
          hoverShadow={'default'}
          isLight
          icon={'Chat'}
          color="primary"
          className="btn-only-icon"
        />
        {unreadNotifications.length > 0 && (
          <span className="notification-badge badge bg-danger rounded-circle" style={{ top: '2px' }}>
            <span>{unreadNotifications.length}</span>
          </span>
        )}
      </div>
      <OffCanvas
        id='notifications-offcanvas'
        placement='end'
        show={isOpen}
        onHide={() => setIsOpen(false)}
      >
        <OffCanvasHeader closeButton>
          <OffCanvasTitle id='notifications-offcanvas-title'>
            Notifications
          </OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          {isOpen && (
            <div >
              {modifiedNotifications.length === 0 ? (
                <span className="notification">No notifications yet...</span>
              ) : (
                modifiedNotifications.map((n, idx) => (
                  <div
                    key={n.id || idx}
                    className={n.isRead ? 'notification' : 'notification not-read'}
                    onClick={() => handleNotificationClick(n)}
                  >
                    <span>{`${n.senderName} sent you a message`}</span>
                    <span className="notification-time">
                      {moment(n.date).calendar()}
                    </span>
                  </div>
                ))
              )}
              <div className="go-to-chat">
                <Button onClick={handleGoToChatPage}>
                  Go to Chat Page
                </Button>
              </div>
            </div>
          )}
        </OffCanvasBody>

      </OffCanvas>
    </div>
  );

};
export default ChatHeaderIcon;
