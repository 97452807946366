import Footer from '../layout/Footer/Footer';
import { demoPages, layoutMenu } from '../menu';

const footers = [
	{ path: layoutMenu.blank.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: demoPages.knowledge.subMenu.grid.path, element: null, exact: true },
	{ path: '/pointVente/caisse/:id', element: null, exact: true },
	{ path: '/Pointage', element:null,exact:true},
	{ path: '*', element: <Footer /> },
];

export default footers;
