import { searchProduct } from '../../../../../../odooApi/product';
import { searchProductAttribute } from '../../../../../../odooApi/productAttribute';
import { getSingleProductTemplate } from '../../../../../../odooApi/productTemplate';
import formInputsTypes from '../../../simulatorConstant';
import { CATEG_ID_AXE } from '../axe';
import { PRODUCT_ID_TABLIER_TRADI, PRODUCT_TEMPL_ID_LAME_FINAL } from '../tablier';
import marqueMoteur from './electrique';
import manoeuvreManuel from './manuel';

//const PRODUCT_ID_VR_COMPLET_TRADI = 10020
const PRODUCT_TEMPL_ID_LAME = 123;
const PRODUCT_TEMPL_ID_COULISSE = 125;
const COMPLETE_LAME_LARGEUR = 6500;
const SEUIL = 1200;
export const CATEG_ID_VERROU_SOUPLE = 151;
export const CATEG_ID_VERROU_REGIDE = 152;
export const PRODUCT_TEMPL_ID_TAB = 24;

const typeManoeuvre = {
	id: 'VR-TR-VRC-TM',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'typeManoeuvre',
	className: 'col-6',
	label: 'Type de manoeuvre',
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'VR-TR-VRC-TM-MNU',
			label: 'Manuel',
			value: 'Manuel',
			nextStep: manoeuvreManuel,
		},
		{
			id: 'VR-TR-VRC-TM-ELE',
			label: 'Èlectrique',
			value: 'Èlectrique',
			nextStep: marqueMoteur,
		},
	],
};

const agraffe = {
	id: 'agraffe',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Agraffe',
	name: 'agraffe',
	nextStepData: typeManoeuvre,
	nextStep: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', currentFormValue?.typeAgraffe?.value]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => ({
					id: item?.id,
					label: item?.display_name,
					value: item?.id,
					lst_price: parseFloat(
						(
							item?.lst_price *
							(Math.ceil(currentFormValue?.largeur / 1000) + 1)
						).toFixed(2),
					),
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const typeAgraffe = {
	id: 'typeAgraffe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Type de verrou',
	name: 'typeAgraffe',
	className: 'col-6',
	nextStepData: agraffe,
	nextStep: false,
	options: [
		{
			id: 'regide',
			label: 'Regide',
			value: CATEG_ID_VERROU_REGIDE,
			nextStep: false,
		},
		{
			id: 'souple',
			label: 'Souple',
			value: CATEG_ID_VERROU_SOUPLE,
			nextStep: false,
		},
	],
};

const isWithAgraffe = {
	id: 'isWithAgraffe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Avec Verrou ?',
	name: 'isWithAgraffe',
	className: 'col-6 d-none',
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'with-agraffe',
			label: 'Oui',
			value: 'oui',
			checked: true,
			nextStep: typeManoeuvre,
		},

	],
};

const axeVoletRoulant = {
	id: 'VR-TR-AXE-API',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner un axe',
	name: 'axe',
	nextStepData: isWithAgraffe,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', CATEG_ID_AXE]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => {
					const { product_template_attribute_value_ids } = item;
					let name = item?.name + ' ';
					product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
					return {
						id: item?.id,
						label: name,
						value: item?.id,
						lst_price: item?.lst_price,
						diametre: item?.diametre,
					};
				})
				: [];
			// const options = elements?.length
			// 	? elements.map((item) => ({
			// 			id: item?.id,
			// 			label: item?.display_name,
			// 			value: item?.id,
			// 			lst_price: item?.lst_price,
			// 			diametre: item?.diametre,
			// 	  }))
			// 	: [];
			fnSuccess(options);
		});
	},
};

const coulisse = {
	id: 'VR-TR-TB-CLIS',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'coulisse',
	className: 'col-6',
	label: 'Coulisse',
	defaultValue: 0,
	nextStepData: axeVoletRoulant,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSucces) => {
		const generateFilter = () => {
			const filters = [
				'&',
				['product_tmpl_id', '=', PRODUCT_TEMPL_ID_COULISSE],
				'&',
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.coulisseColor?.label,
				],
				'&',
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameTaille?.label == 'BP43R' ? '43' : '55',
				],
				['product_template_attribute_value_ids.name', '=', 'Tradi'],
			];
			return filters;
		};
		//console.log("generateFiltergenerateFilter",generateFilter());
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			//console.log("resultsresultsresultsresults",results);
			const options = elements?.length
				? elements.map((item) => {
					const { product_template_attribute_value_ids } = item;
					let name = 'Coulisses aluminium ';
					product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
					return {
						id: item?.id,
						label: name,
						value: item?.id,
						checked: true,
						lst_price: parseFloat(
							((item?.lst_price * currentFormValue?.hauteur) / 1000).toFixed(2),
						),
					};
				})
				: [];
			// const options = elements?.length
			// 	? elements.map((item) => {
			// 			return {
			// 				id: item?.id,
			// 				label: item?.display_name,
			// 				value: item?.id,
			// 				checked: true,
			// 				lst_price: parseFloat(
			// 					((item?.lst_price * currentFormValue?.hauteur) / 1000).toFixed(2),
			// 				),
			// 			};
			// 	  })
			// 	: [];
			fnSucces(options);
		});
	},
};

const coulisseColor = {
	id: 'VR-TR-TB-CC',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'coulisseColor',
	classGroup: "Simulateur_color",
	label: 'Couleur de coulisse',
	defaultValue: 0,
	nextStepData: coulisse,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleProductTemplate(tokenUserCurent, PRODUCT_TEMPL_ID_TAB, ({ data }) => {
			const options = data.attribute_line_ids[0]?.value_ids?.length
				? data.attribute_line_ids[0]?.value_ids.map((item) => {
					return {
						id: item?.id,
						label: item?.name,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						value: item?.id,
						color: item?.html_color,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const lameFinal = {
	id: 'VR-TR-TB-LAMEF',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameFinal',
	className: 'col-6',
	label: 'Lame Finale',
	defaultValue: 0,
	nextStepData: coulisseColor,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSucces) => {
		const generateFilter = (productTemplateId) => {
			const filters = [
				'&',
				['product_tmpl_id', '=', productTemplateId],
				'&',
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameFinalColor?.label,
				],
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameTaille?.label == 'BP43R' ? 'BPFIN948' : 'BPFIN304',
				],
			];
			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(PRODUCT_TEMPL_ID_LAME_FINAL),
			async ({ elements }) => {
				const options = elements?.length
					? elements.map((item) => {
						const { product_template_attribute_value_ids } = item;
						let name = 'Lame Finale ';
						product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
						return {
							id: item?.id,
							label: name,
							value: item?.id,
							weight: item?.weight,
							checked: true,
							lst_price: parseFloat(
								((item?.lst_price * currentFormValue?.largeur) / 1000).toFixed(
									2,
								),
							),
						};
					})
					: [];
				// const options = elements?.length
				// 	? elements.map((item) => {
				// 			return {
				// 				id: item?.id,
				// 				label: item?.display_name,
				// 				value: item?.id,
				// 				weight: item?.weight,
				// 				checked: true,
				// 				lst_price: parseFloat(
				// 					((item?.lst_price * currentFormValue?.largeur) / 1000).toFixed(
				// 						2,
				// 					),
				// 				),
				// 			};
				// 	  })
				// 	: [];
				fnSucces(options);
			},
		);
	},
};

const lameFinalColor = {
	id: 'VR-TR-TB-LC',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameFinalColor',
	label: 'Couleur de la lame Final',
	classGroup: "Simulateur_color",
	defaultValue: 0,
	nextStepData: lameFinal,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleProductTemplate(tokenUserCurent, PRODUCT_TEMPL_ID_TAB, ({ data }) => {
			const options = data.attribute_line_ids[0]?.value_ids?.length
				? data.attribute_line_ids[0]?.value_ids.map((item) => {
					return {
						id: item?.id,
						label: item?.name,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						value: item?.id,
						color: item?.html_color,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const Lames = {
	id: 'VR-TR-VRC-LAME',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lames',
	className: 'col-6',
	label: 'Lames',
	defaultValue: 0,
	nextStepData: lameFinalColor,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSucces) => {
		const generateFilter = () => {
			const filters = [
				'&',
				['product_tmpl_id', '=', PRODUCT_TEMPL_ID_LAME],
				'&',
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameColor?.label,
				],
				[
					'product_template_attribute_value_ids.name',
					'=',
					currentFormValue?.lameTaille?.label,
				],
			];
			return filters;
		};

		searchProduct(tokenUserCurent, generateFilter(), ({ elements }) => {
			const calculeTablier = (item) => {
				const restParLame = COMPLETE_LAME_LARGEUR % currentFormValue?.largeur;

				const nbrLameCompolet = Math.ceil(
					Math.ceil(
						currentFormValue?.hauteur /
						(currentFormValue?.lameTaille?.name == 'BP43R' ? 43 : 55),
					) / Math.floor(COMPLETE_LAME_LARGEUR / currentFormValue?.largeur),
				);

				var prixFTablier = 0;
				prixFTablier = parseFloat(
					(nbrLameCompolet * COMPLETE_LAME_LARGEUR * item?.lst_price) / 1000,
				).toFixed(2);

				if (restParLame > SEUIL) {
					prixFTablier = parseFloat(
						prixFTablier - (restParLame * nbrLameCompolet * item?.lst_price) / 1000,
					).toFixed(2);
				}
				return prixFTablier;
			};
			const options = elements?.length
				? elements.map((item) => {
					const { product_template_attribute_value_ids } = item;
					let name = 'Lame aluminium ';
					product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
					return {
						id: item?.id,
						label: name,
						value: item?.id,
						lst_price: parseFloat(calculeTablier(item)),
						weight: item?.weight,
						checked: true,
						id: item?.id,
						label: name,
						value: item?.id,
					};
				})
				: [];
			// const options = elements?.length
			// 	? elements.map((item) => {
			// 			return {
			// 				id: item?.id,
			// 				label: item?.display_name,
			// 				value: item?.id,
			// 				lst_price: parseFloat(calculeTablier(item)),
			// 				weight: item?.weight,
			// 				checked: true,
			// 			};
			// 	  })
			// 	: [];
			fnSucces(options);
		});
	},
};

const LameColor = {
	id: 'VR-TR-VRC-LC',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameColor',
	classGroup: "Simulateur_color",
	label: 'Couleur de la lame',
	defaultValue: 0,
	nextStepData: Lames,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleProductTemplate(tokenUserCurent, PRODUCT_TEMPL_ID_TAB, ({ data }) => {
			const options = data.attribute_line_ids[0]?.value_ids?.length
				? data.attribute_line_ids[0]?.value_ids.map((item) => {
					return {
						id: item?.id,
						label: item?.name,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						value: item?.id,
						color: item?.html_color,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const LameTaille = {
	id: 'VR-TR-VRC-LT',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'lameTaille',
	className: 'col-12',
	label: 'Type de la lame',
	defaultValue: 0,
	nextStepData: LameColor,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['name', '=', 'Type Lame']];
			return filters;
		};
		searchProductAttribute(
			tokenUserCurent,
			generateFilter(currentFormValue),
			async ({ elements }) => {
				const options = elements[0]?.value_ids?.length
					? elements[0]?.value_ids.map((item) => ({
						id: item?.id,
						label: item?.name,
						value: item?.id,
					}))
					: [];
				fnSuccess(options);
			},
		);
	},
};

/* const typePose = {
	id:"VR-TR-VRC-TP",
	type:formInputsTypes?.RADIO_INPUT,
	name:"typePose",
	className:"col-6",
	label:"Type de pose",
	nextStepData:LameTaille,
	nextStep:false,
	options:[
		{
			id:"VR-TR-VRC-SL",
			label:"Pose sous linteau",
			value:"Pose sous linteau",
			nextStep:false
		},
		{
			id:"VR-TR-VRC-EA",
			label:"Pose en applique contre façade",
			value:"Pose en applique contre façade",
			nextStep:false
		}
	]
    
} */

const hieghtTablier = {
	id: 'VR-TR-VRC-H',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'hauteur',
	className: 'col-6',
	label: 'Hauteur entre murs (mm)',
	defaultValue: 0,
	nextStepData: LameTaille,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const TypeMesureHaut = {
	id: 'VR-TR-VRC-TMH',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'typeMesureHauteur',
	className: 'col-6',
	label: 'Mesure selon hauteur',
	nextStepData: hieghtTablier,
	nextStep: false,
	options: [
		{
			id: 'VR-TR-TB-TMH-FN',
			label: 'FINI',
			value: 'FINI',
			nextStep: false,
		},
		{
			id: 'VR-TR-TB-TMH-E',
			label: '+E',
			value: '+E',
			nextStep: false,
		},
	],
};

const widthTablier = {
	id: 'VR-TR-VRC-W',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'largeur',
	className: 'col-6',
	label: 'Largeur entre murs (mm)',
	defaultValue: 0,
	nextStepData: TypeMesureHaut,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const TypeMesureLarg = {
	id: 'VR-TR-VRC-TMW',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'typeMesureLargeur',
	className: 'col-6',
	label: 'Mesure selon largeur',
	nextStepData: widthTablier,
	nextStep: false,
	options: [
		{
			id: 'VR-TR-VRC-TMW-FN',
			label: 'FINI',
			value: 'FINI',
			nextStep: false,
		},
		{
			id: 'VR-TR-VRC-TMW-DC',
			label: 'D.C',
			value: 'D.C',
			nextStep: false,
		},
	],
};

export default TypeMesureLarg;
