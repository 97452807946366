import firebase from 'firebase/compat/app';
import React, { useContext, useEffect, useState } from 'react';
import { firestore } from '../../apis/firebase/config';
import AuthContext from '../../contexts/authContext';
import Alert, { AlertHeading, AlertLink } from '../bootstrap/Alert';
import Button from '../bootstrap/Button';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import Icon from '../icon/Icon';

const OffcetNotifications = ({
	setOffcanvasStatus,
	offcanvasStatus,
	unreadNotifications,
	pose,
}) => {
	const { userData } = useContext(AuthContext);
	const [notifications, setNotifications] = useState([]);

	useEffect(() => {
		if (userData && userData?.id && userData?.password) {
			firestore
				.collection('notifications')
				.where('recieverMembers', 'array-contains', userData?.id)
				.where('memberNotVu', 'not-in', [userData?.id])
				.orderBy('createdAt', 'desc')
				//.limit(15)
				//.where("unreadMsgs",">",0)
				.onSnapshot((snapshot) => {
					const notification = [];
					snapshot.docs.filter((doc) => {
						/* if (doc.data().unreadMsgs > 0) { */
						notification.push({ ...doc.data(), id: doc.id });
						/* } */
					});
					setNotifications(notification);
				});
		}
	}, []);

	// useEffect(() => {
	// 	if (unreadNotifications?.length && userData && userData?.id && userData?.password) {
	// 		const batch = firestore.batch();
	// 		unreadNotifications?.map((notification) => {
	// 			batch.update(firestore.collection('notifications').doc(notification?.id), {
	// 				memberNotVu: firebase.firestore.FieldValue.arrayRemove(userData?.id),
	// 			});
	// 		});
	// 		batch.commit();
	// 	}
	// }, []);

	const deleteNotif = (id) => {
		if (unreadNotifications?.length && userData && userData?.id && userData?.password) {
			const batch = firestore.batch();
			unreadNotifications?.map((notification) => {
				notification?.id == id &&
					batch.update(firestore.collection('notifications').doc(notification?.id), {
						memberNotVu: firebase.firestore.FieldValue.arrayRemove(userData?.id),
					});
			});
			batch.commit();
			setNotifications((prev) => prev?.filter((item) => item?.id !== id));
		}
	};
	console.log("unreadNotifications", unreadNotifications)
	return (
		<OffCanvas
			id='notificationCanvas'
			titleId='offcanvasExampleLabel'
			placement='end'
			isOpen={offcanvasStatus}
			setOpen={setOffcanvasStatus}>
			<OffCanvasHeader setOpen={setOffcanvasStatus}>
				<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody>
				{!!notifications?.length &&
					notifications.map((notification, index) => {
						return (
							<Alert
								key={index}
								onClick={() => {
									/* setCard(item);
									setEditModalStatus(true); */
								}}
								// icon={notification?.icon}
								isLight
								color={notification?.color}
								className='flex-nowrap cursor-pointer  align-items-baseline pt-2 pe-1'>
								{/* <div className='row'> */}
								<div className='me-4 col-10'>
									<AlertLink href={notification?.link}>
										<span>Voir</span>
									</AlertLink>
									{React.createElement('div', {
										dangerouslySetInnerHTML: {
											__html: (notification?.text || '').replace(
												/\n/g,
												'<br />',
											),
										},
									})}
									{/* {notification?.text?.replace(
                                        /<br\s*\/?>/g,
                                        '\n',
                                    ).replace(
                                        /<\/?[^>]+(>|$)/g,
                                        '',
                                    )} */}
								</div>
								<div className='col-2 text-center '>
									<Icon
										icon='Close'
										size={'lg'}
										onClick={() => deleteNotif(notification?.id)}></Icon>
								</div>
								{/* </div> */}

								{/* <div>{moment(notification?.createdAt?.seconds * 1000).format('D MMM yyyy HH:mm')}</div> */}
							</Alert>
						);
					})}

				{!!Object.keys(pose).length && (
					<Alert
						className='flex-nowrap cursor-pointer'
						icon='ViewInAr'
						size={'3x'}
						isLight
						color='primary'
						onClick={() => {
							navigate('/' + planningMenu?.path);
							setOffcanvasStatus(false);
						}}>
						<AlertHeading tag='h2' className='h4'>
							{pose?.type_int} {pose?.motif}
							{!(pose?.type_int || pose?.motif) && 'Intervention'}
						</AlertHeading>
						<span className='mb-6'>
							{!!pose?.client && (
								<div className=' col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-grow-1 '>
											<span className='text-muted'>Client :</span>{' '}
											<span className='fw-bold fs-6 mb-0'>
												{pose['client']}
											</span>
										</div>
									</div>
								</div>
							)}
							{!!pose?.type_int && (
								<div className=' col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-grow-1 '>
											<span className='text-muted'>
												Type d'intervention :{' '}
											</span>
											<span className='fw-bold fs-6 mb-0'>
												{pose?.type_int}
											</span>
										</div>
									</div>
								</div>
							)}
							{!!pose?.devis?.length && (
								<div className=' col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-grow-1 '>
											<span className='text-muted'>N° devis : </span>
											<span className='fw-bold fs-6 mb-0'>
												{pose['devis']}
											</span>
										</div>
									</div>
								</div>
							)}
							{!!pose?.Remarque && (
								<div className='col-12'>
									<div className='d-flex '>
										<div className='flex-grow-1 '>
											<span className='text-muted'>Remarque : </span>
											<span className=' fs-6 mb-0'>
												{React.createElement('div', {
													dangerouslySetInnerHTML: {
														__html: (pose?.Remarque || '').replace(
															/\n/g,
															'<br />',
														),
													},
												})}
											</span>
										</div>
									</div>
								</div>
							)}
							{!!pose?.clientData && !!Object.keys(pose?.clientData).length && (
								<>
									{!!pose?.clientData?.name && (
										<div className=' col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-grow-1 '>
													<span className='text-muted'>Client : </span>
													<span className='fw-bold fs-6 mb-0'>
														{pose?.clientData?.name}
													</span>
												</div>
											</div>
										</div>
									)}
									{!!pose?.clientData?.email && (
										<div className=' col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-grow-1 '>
													<span className='text-muted'>E-mail : </span>
													<span className='fw-bold fs-6 mb-0'>
														{pose?.clientData?.email}
													</span>
												</div>
											</div>
										</div>
									)}
									{!!pose?.clientData?.mobile && (
										<div className=' col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-grow-1 '>
													<span className='text-muted'>
														Tél client :{' '}
													</span>
													<span className='fw-bold fs-6 mb-0'>
														{pose?.clientData?.mobile}
													</span>
												</div>
											</div>
										</div>
									)}
									{(!!pose?.clientData?.street ||
										!!pose?.clientData?.zip ||
										!!pose?.clientData?.city ||
										!!pose?.clientData?.street2) && (
											<div className=' col-12'>
												<div className='d-flex align-items-center'>
													<div className='flex-grow-1 '>
														<span className='text-muted'>
															Adresse client :{' '}
														</span>
														<span className='fw-bold fs-6 mb-0'>
															{pose?.clientData?.street +
																' ' +
																pose?.clientData?.zip +
																' ' +
																pose?.clientData?.city}
														</span>
													</div>
												</div>
											</div>
										)}
								</>
							)}
						</span>
						<div className='col-12 d-flex justify-content-start mt-2'>
							{' '}
							<Button
								style={{
									color: 'light',
									hoverShadow: 'default',
								}}
								className='w-auto'
								icon={'cancel'}
								color='primary'
								onClick={() => {
									setPose({});
								}}>
								Annuler
							</Button>
						</div>
					</Alert>
				)}

				{/* {lateTasks?.length > 0 &&
                    lateTasks?.map((item) => (
                        <Alert
                            onClick={() => {
                                setCard(item);
                                setEditModalStatus(true);
                            }}
                            icon='PendingActions'
                            isLight
                            color='danger'
                            className='flex-nowrap cursor-pointer'>
                            <div className='me-4'>{item?.x_name}</div>
                            <div>{moment(item?.x_end_date).format('D MMM HH:mm')}</div>
                        </Alert>
                    ))} */}

				{/* <Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
					</Alert>
					<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
						New products added to stock.
					</Alert>
					<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
						There are products that need to be packaged.
					</Alert>
					<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
						Your food order is waiting for you at the consultation.
					</Alert>
					<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
						Escalator will turn off at 6:00 pm.
					</Alert> */}
			</OffCanvasBody>
		</OffCanvas>
	);
};

export default OffcetNotifications;
