import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../contexts/authContext';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import Button from '../bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../bootstrap/Modal';
import Label from '../bootstrap/forms/Label';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import PoseContext from '../../contexts/poseContext';
import SignatureCanvas from 'react-signature-canvas';
import { convertURL, getReport, getReportProduction } from '../../common/function/functionsUtils';
import { uploadAttachementsTechnicien, uploadSignaturePlanning } from '../../odooApi/planningSlot';

import Spinner from '../bootstrap/Spinner';
import Checks from '../bootstrap/forms/Checks';
import createDecharge, {
	sendDechargeByMail,
	uploadSignatureDecharge,
} from '../../odooApi/decharge';
import moment from 'moment';
import ErrorPopup from '../extras/ErrorPopup';

export default function DechargeDevis({ setOpen, devisAccepte, setAttachmentsRaport, planning }) {
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false);
	const [sigPad, setSigPad] = useState({});
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [stateForm, setStateForm] = useState({});
	const [signature, setSignature] = useState(false);

	console.log('decharge accepted devis ', planning);
	const handleClearSignature = () => {
		sigPad?.clear();
		setDisabledBtn(true);
	};
	const handelCancel = () => {
		setOpen(false);
	};
	const handelSendMail = (id) => {

		sendDechargeByMail(
			userData?.tokenOdoo,
			id,
			() => {
				convertURL(
					getReport(userData, id, 'devis.decharge'),
					'Decharge_client_' + planning?.partner_id?.name,
					(file) => {
						uploadAttachementsTechnicien(
							userData?.tokenOdoo,
							planning?.id,
							[file],
							(result) => {
								const newUploadedAtttachements = [
									...planning.attachment_report,
									{ id: result?.data?.attachment[0], file: file },
								];
								setAttachmentsRaport(newUploadedAtttachements);
								planning.attachment_report = newUploadedAtttachements;

								// handelSendMail();
								//setOpen(false);
							},
							(error) => {
								setSpinner(false);
								setError(error);
							},
						);
					},
					(error) => {
						setError(error);
					},
				);
				setSuccess('E-mail envoyé à ' + planning?.partner_id?.email);
				setSpinner(false);
			},
			(error) => {
				setError(error);
				setSpinner(false);
			},
		);

	};

	const handelCreateDecharge = () => {
		setSpinner(true);
		const newDecharge = {
			planning: planning?.id,
			date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
			name: 'decharge ' + planning?.partner_id?.name,
		};
		createDecharge(
			userData?.password,
			newDecharge,
			(dataDecharge) => {
				if (!!signature) {
					if (!sigPad.isEmpty()) {
						uploadSignatureDecharge(
							userData?.password,
							dataDecharge?.id,
							sigPad?.getTrimmedCanvas().toDataURL('image/png').split(',')[1],
							(result) => {
								handelSendMail(dataDecharge?.id);
							},
						);
					} else {
						handelSendMail(dataDecharge?.id);
					}
				} else {
					handelSendMail(dataDecharge?.id);
				}

				console.log(dataDecharge);
			},
			(error) => {
				setSpinner(false);
				setError(error);
			},
		);
		// convertURL(
		// 	getReportProduction(userData?.password, devisAccepte?.id, 'sale.order', 'decharge'),
		// 	'Decharge_client_' + devisAccepte?.name,
		// 	(file) => {
		// 		uploadAttachementsTechnicien(
		// 			userData?.password,
		// 			planning?.id,
		// 			[file],
		// 			(result) => {
		// 				const newUploadedAtttachements = [
		// 					...planning.attachment_report,
		// 					{ id: result?.data?.attachment[0], file: file },
		// 				];
		// 				setAttachmentsRaport(newUploadedAtttachements);
		// 				planning.attachment_report = newUploadedAtttachements;
		// 				// const newLine = {
		// 				// 	id: devisAccepte?.id,
		// 				// 	decharge: true,
		// 				// };
		// 				// updateSingleDevis(userData?.password, newLine, () => {});
		// 				// handelSendMail();
		// 				//setOpen(false);
		// 			},
		// 			(error) => {
		// 				setSpinner(false);
		// 				setError(error);
		// 			},
		// 		);
		// 	},
		// );
	};

	// const handelCreateDecharge = () => {
	// 	setSpinner(true);
	// 	if (sigPad.isEmpty()) {
	// 		convertURL(
	// 			getReportProduction(userData?.password, devisAccepte?.id, 'sale.order', 'decharge'),
	// 			'Decharge_client_' + devisAccepte?.name,
	// 			(file) => {
	// 				uploadAttachementsTechnicien(
	// 					userData?.password,
	// 					planning?.id,
	// 					[file],
	// 					(result) => {
	// 						const newUploadedAtttachements = [
	// 							...planning.attachment_report,
	// 							{ id: result?.data?.attachment[0], file: file },
	// 						];
	// 						setAttachmentsRaport(newUploadedAtttachements);
	// 						planning.attachment_report = newUploadedAtttachements;
	// 						const newLine = {
	// 							id: devisAccepte?.id,
	// 							decharge: true,
	// 						};
	// 						updateSingleDevis(userData?.password, newLine, () => {});
	// 						handelSendMail();
	// 						//setOpen(false);
	// 					},
	// 					(error) => {
	// 						setSpinner(false);
	// 						setError(error);
	// 					},
	// 				);
	// 			},
	// 		);
	// 	} else {
	// 		uploadSignatureDevis(
	// 			userData?.password,
	// 			devisAccepte?.id,
	// 			sigPad?.getTrimmedCanvas().toDataURL('image/png').split(',')[1],
	// 			(result) => {
	// 				convertURL(
	// 					getReportProduction(
	// 						userData?.password,
	// 						devisAccepte?.id,
	// 						'sale.order',
	// 						'decharge',
	// 					),
	// 					'Decharge_client_' + devisAccepte?.name,
	// 					(file) => {
	// 						uploadAttachementsTechnicien(
	// 							userData?.password,
	// 							planning?.id,

	// 							[file],

	// 							(result) => {
	// 								const newUploadedAtttachements = [
	// 									...planning.attachment_report,
	// 									{ id: result?.data?.attachment[0], file: file },
	// 								];
	// 								setAttachmentsRaport(newUploadedAtttachements);
	// 								planning.attachment_report = newUploadedAtttachements;
	// 								const newLine = {
	// 									id: devisAccepte?.id,
	// 									decharge: true,
	// 								};

	// 								updateSingleDevis(userData?.password, newLine, () => {});
	// 								handelSendMail();
	// 								//setOpen(false);
	// 							},
	// 							(error) => {
	// 								setSpinner(false);
	// 								setError(error);
	// 							},
	// 						);
	// 					},
	// 				);
	// 			},
	// 			(error) => {
	// 				setSpinner(false);
	// 				setError(error);
	// 			},
	// 		);
	// 	}
	// };

	return (
		<>
			<Modal isOpen={true} setIsOpen={() => { }} size={'md'} classNameMd='nv-2'>
				<ModalHeader setIsOpen={() => setOpen(false)}></ModalHeader>
				<ModalBody>
					{/* <CardHeader className='text-primary d-flex justify-content-center'>
						<CardTitle className='text-primary'></CardTitle>
					</CardHeader> */}
					<div className=' text-primary d-flex justify-content-center mb-5'>
						<h4>Formulaire Général De Décharge De Responsabilité</h4>
					</div>
					<div className='px-3'>
						<div className='row mb-3'>
							<div className='col-12'>
								<span>
									Je soussigné (e) M/Mme{' '}
									<strong> {planning?.partner_id?.name},</strong>
								</span>
							</div>
						</div>
						<div className='row mb-3'>
							<span>
								Propriétaire/Locataire du bien situé au{' '}
								<strong>
									{planning?.partner_id?.street_number}{' '}
									{planning?.partner_id?.street} {planning?.partner_id?.zip}{' '}
									{planning?.partner_id?.city}
								</strong>
							</span>
						</div>
						<div className='row mb-3'>
							<span>
								Reconnais avoir pris connaissance des éventuels dommages
								susceptibles d'être occasionnés lors des travaux réalisés par la
								société Store 2000.
							</span>
						</div>
						<div className='row mb-3'>
							<span>
								En apposant ma signature sur cette déclaration, je consens
								volontairement à assumer les risques liés à ces travaux et autorise
								la société Store 2000 à les entreprendre.
							</span>
						</div>
						<div className='row mb-3'>
							<span>
								Je décline toute responsabilité à l'égard de la société Store 2000
								pour tout préjudice éventuel survenant pendant ou après l'exécution
								des travaux.
							</span>
						</div>
						<div className='row mb-3'>
							<span>
								En signe de ma compréhension et de mon accord, je joins ma signature
								à ce document.
							</span>
						</div>
						<CardHeader>
							<CardLabel>
								<CardSubTitle>
									<Checks
										className='fw-bold fs-6'
										type='checkbox'
										id='signature'
										label='Signature'
										name='signature'
										onChange={() => setSignature(!signature)}
										checked={signature}
									/>
								</CardSubTitle>
							</CardLabel>
							{!!signature && (
								<CardActions>
									<Button
										color='primary'
										className='w-auto'
										icon='Replay'
										isLight
										//type='submit'
										onClick={handleClearSignature}>
										Effacer
									</Button>
								</CardActions>
							)}
						</CardHeader>
						{!!signature && (
							<Card shadow={'md'}>
								<div style={{ height: '250px' }}>
									<SignatureCanvas
										penColor='black'
										canvasProps={{
											style: { width: '100%', height: 250 },
											className: 'sigCanvas',
										}}
										height='250'
										backgroundColor='white'
										ref={(ref) => {
											setSigPad(ref);
										}}
										onBegin={() => {
											setDisabledBtn(false);
										}}
									/>
								</div>
							</Card>
						)}
					</div>
					{/* <Card borderColor={'info'} borderSize={1} shadow={'none'} className='p-2'>
						<CardBody>
							<div className='row d-flex justify-content-center'>
								<div className='col-12'>
									<Label>Signature Client</Label>
									<SignatureCanvas
										penColor='black'
										canvasProps={{
											style: { width: '100%' },
											className: 'sigCanvas',
										}}
										backgroundColor='white'
										ref={(ref) => {
											setSigPad(ref);
										}}
										onBegin={() => {
											setDisabledBtn(false);
										}}
									/>
								</div>
							</div>
						</CardBody>
						<CardFooter>
							<CardFooterRight>
								<Button
									color='warning'
									className='w-auto'
									//type='submit'
									onClick={handleClearSignature}>
									Effacer la signature
								</Button>
							</CardFooterRight>
						</CardFooter>
					</Card> */}
					<div className='row d-flex justify-content-end'>
						<div className='w-auto'>
							<Button
								color='primary'
								type='submit'
								icon='Check'
								onClick={handelCreateDecharge}
								isDisable={spinner}>
								{spinner && <Spinner isSmall inButton />}
								{spinner ? 'En cours' : 'Envoyer'}
							</Button>
						</div>

						<div className='w-auto'>
							<Button color='light' icon='Close' onClick={handelCancel}>
								Annuler
							</Button>
						</div>
					</div>
				</ModalBody>
				{/* <ModalFooter>
					<CardFooterRight>
						<Button
							color='primary'
							className='w-auto'
							//type='submit'
							onClick={handelCreateDecharge}
							isDisable={spinner}>
							{spinner && <Spinner isSmall inButton />}
							{spinner ? 'En cours' : 'Envoyer'}
						</Button>
						<Button
							color='danger'
							className='w-auto'
							//type='submit'
							onClick={handelCancel}>
							Annuler
						</Button>
					</CardFooterRight>
				</ModalFooter> */}
			</Modal>

			{!!success && (
				<StatusPopup
					icon='MarkEmailRead'
					color='success'
					title='E-mail envoyé avec succès'
					label={success}
					setOpen={(param) => {
						setSuccess(param);
						setOpen(param);
					}}
				/>
			)}
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
		</>
	);
}
