import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	//SEARCH_METHOD_API=process.env.REACT_APP_SEARCH_METHOD_API,
	PAY_API_URI = '/pay';
export const payFacture = (currentUser, payInfo, fnSucces, fnErr) => {
	const payInfoString = JSON.stringify(payInfo);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PAY_API_URI + '/'}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: payInfoString,
	};
	axios(config)
		.then(function (result) {
			fnSucces(result?.data);
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};
