import { useSocket } from '../contexts/StockSocketContext';

const StockEventsWebSocket = () => {
    const BASIC_API_URL = '/back';
    const PRODUCT_API = process.env.REACT_APP_NODE_PRODUCT_API_URL;
    let reconnectInterval = 1000; // Start with 1 second
    const { addMessage, setSocket, socket } = useSocket();

    // Function to attempt reconnection with exponential backoff
    const attemptReconnect = () => {
        console.log('Reconnecting in', reconnectInterval.current, 'ms...');
        setTimeout(() => {
            console.log('Reconnecting...');
            initWebSocket(); // Try reconnecting
            reconnectInterval = Math.min(reconnectInterval.current * 2, 60000); // Exponential backoff, max 60 seconds
        }, reconnectInterval);
    };

    const initWebSocket = () => {
        const wss = new WebSocket('ws://localhost:443/');
        // (`ws://${BASIC_API_URL}/${PRODUCT_API}`)
        setSocket(wss);

        // Handle incoming messages from the server
        wss.onmessage = (event) => {
            const receivedMessage = event.data;
            addMessage(receivedMessage); // Optional: Add message to context (if required)
        };

        // Handle WebSocket errors
        wss.onerror = (error) => {
            console.error('WebSocket error:', error);
            attemptReconnect();
        };

        // Handle WebSocket connection close
        wss.onclose = () => {
            console.log('WebSocket connection closed, attempting reconnect...');
            attemptReconnect();
        };
    };




    // useEffect(() => {
    //     initWebSocket();
    //     return () => {
    //         if (socket) socket.close();
    //     };
    // }, []);
    return null;

}
export default StockEventsWebSocket;
