import axios from 'axios';

const
  BASIC_API_URL = "/odoo",
  API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
  SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
  ACCOUNT_MOVE_LINE_API_URI = "/account.move.line"
  ;

export const getAccountMoveLinesById = (currentUser, factureId, fnSucces) => {
  const bodyData = JSON.stringify({
    "kwargs": {
      "domain": [
        [
          "move_id",
          "=",
          factureId
        ],
        ["exclude_from_invoice_tab", "!=", "False"]
      ]
    }
  });
  var config = {
    method: 'patch',
    url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACCOUNT_MOVE_LINE_API_URI + SEARCH_METHOD_API}`,
    headers: {
      Authorization: `Basic ${btoa(
        currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
      )}`,
      'Content-Type': 'application/json'
    },
    data: bodyData
  };
  axios(config)
    .then(function (response) {
      fnSucces(response?.data)
    })
    .catch(function (error) {
      console.log(error);
    });

}

export const createInvoiceLine = async (currentUser, newLine, fnSucces, fnError = () => { }) => {
  var data = JSON.stringify(newLine);

  var config = {
    method: 'post',
    url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACCOUNT_MOVE_LINE_API_URI}`,
    headers: {
      Authorization: `Basic ${btoa(
        currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
      )}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };
  axios(config)
    .then(function (response) {
      fnSucces(response?.data);
    })
    .catch(function (error) {
      fnError(error);
    });
};


export const deleteInvoiceLine = async (currentUser, idLine, fnSucces) => {
  const config = {
    method: 'delete',
    url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACCOUNT_MOVE_LINE_API_URI + '/' + idLine}`,
    headers: {
      Authorization: `Basic ${btoa(
        currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
      )}`,
      'Content-Type': 'application/json',
    },
  };

  axios(config)
    .then(function () {
      fnSucces();
    })
    .catch(function (error) {
      console.log(error);
    });
};


export const updateInvoiceLine = async (currentUser, invoiceLine, fnSucces, fnError = () => { }) => {
  const invoiceLineString = JSON.stringify(invoiceLine);

  const config = {
    method: 'put',
    url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACCOUNT_MOVE_LINE_API_URI + '/' + invoiceLine?.id}`,
    headers: {
      Authorization: `Basic ${btoa(
        currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
      )}`,
      'Content-Type': 'application/json',
    },
    data: invoiceLineString,
  };

  axios(config)
    .then(function () {
      fnSucces();
    })
    .catch(function (error) {
      fnError(error);
    });
};