
import React, { createContext, useContext, useMemo, useState } from 'react';
import AuthContext from './authContext';

const CompanieContext = createContext([]);

export const CompanieContextProvider = ({ children }) => {
    const { userData } = useContext(AuthContext)
    const [companie, setCompanie] = useState([userData?.company_id] || 1);

    const value = useMemo(
        () => ({
            companie,
            setCompanie,
        }),
        [companie],
    );
    return <CompanieContext.Provider value={value}>{children}</CompanieContext.Provider>;
};

export default CompanieContext;
