import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	PAY_API_URI = '/payment.justify',
	LIMIT = 20;
export const paymentJustify = (currentUser, payInfo, fnSucces, fnErr) => {
	const payInfoString = JSON.stringify(payInfo);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PAY_API_URI + '/'}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: payInfoString,
	};
	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			fnErr(error);
		});
};

export const updatePaymentJustify = (currentUser, paiement, fnSucces, fnError = () => { }) => {
	const paiementLineString = JSON.stringify(paiement);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PAY_API_URI + '/' + paiement?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: paiementLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};



export const searchPaymentByInvoice = (
	currentUser,
	invoiceId,
	fnSuccess,
	fnError = () => { },
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: [['source', '=', invoiceId]],
			limit: limit,
			page: page,
			order: 'date asc',
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PAY_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchPayments = (
	currentUser,
	filters,
	fnSuccess,
	fnError = () => { },
	signal,
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
			// order: 'date asc',
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PAY_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const uploadAttachmentPaymentJustify = (
	currentUser,
	idPaymentJustify,
	attachement,
	fnc,
	fnError = () => { },
) => {
	let data = new FormData();
	data.append('attachment', attachement);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_binary' +
			PAY_API_URI +
			'/' +
			idPaymentJustify +
			'?token=' +
			currentUser?.tokenOdoo,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnError(error);
		});
};

export const updatePaymentJustifyState = (
	currentUser,
	payment,
	fnSucces,
	fnError = () => { },
) => {
	const paymentLineString = JSON.stringify(payment);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PAY_API_URI + '/' + payment?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: paymentLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
