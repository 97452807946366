import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import DevisTable from './DevisTable';
import { useFormik } from 'formik';
import Icon from '../icon/Icon';
import AuthContext from '../../contexts/authContext';
import { FactureLinesContextProvider } from '../../contexts/factureLineContext';
import {
	deleteFacture,
	getSingleFacture,
	searchFactures,
	sendInvoiceByMail,
	updateFacture,
} from '../../odooApi/accountMove';
import { organizationDataFacturation } from '../../common/function/organizationDataDevis';
import moment from 'moment';
import FactureContext from '../../contexts/facturationContext'; /* 
import { payFacture } from '../../odooApi/pay'; */
import PaymentPopup from './PaymentPopup';
import classNames from 'classnames';
import { priceFormat } from '../../helpers/helpers';
import { imagePayement } from '../../odooApi/accountPayment';
import { getReport } from '../../common/function/functionsUtils';
import showNotification from '../extras/showNotification';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import Spinner from '../bootstrap/Spinner';
import { paymentJustifyAPI } from '../../odooApi/paiementMail';
import { searchPaymentByInvoice, updatePaymentJustifyState } from '../../odooApi/paymentJustify';
import LoadSpinner from '../Spinner/LoadSpinner';
import Alert from '../bootstrap/Alert';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import COLORS from '../../common/data/colorDummyData';
import PaymentJustifyTable from './PaymentJustifyTable';
import ComptabiliserPopup from './ComptabiliserPopup';
import Badge from '../bootstrap/Badge';
import ErrorPopup from '../extras/ErrorPopup';

const FacturePopUp = ({ card, setEditModalStatus, loadFactures = () => { } }) => {
	const { userData } = useContext(AuthContext);
	const { setFacture } = useContext(FactureContext);
	const [paymentModalStatus, setPaymentModalStatus] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [total, setTotal] = useState();
	const [totalUntaxed, setTotalUntaxed] = useState(0);
	const [loading, setLoading] = useState(false);
	const [totalPriceRemise, settotalPriceRemise] = useState(card?.amount_discount);
	const [remise, setRemise] = useState(card?.discount_rate);
	const [acompte, setAcompte] = useState(card?.accompte);

	const updateFactureContext = (values) => {
		setFacture((prevFactures) => [
			...prevFactures.map((group) => {
				if (group.pay === values.invoice_payment_state && group.state === values.state) {
					let cardIndex = group.cards.findIndex((card) => card.id === values.id);
					if (cardIndex !== -1) {
						let newCards = [...group.cards];
						newCards[cardIndex] = values;
						return {
							...group,
							cards: newCards,
						};
					} else {
						return {
							...group,
							cards: [values, ...group.cards],
						};
					}
				}

				return {
					...group,
					cards: group.cards.filter((card) => card.id !== values.id),
				};
			}),
		]);
	};

	const handelUpdate = () => {
		updateFacture(
			userData,
			{
				id: card?.id,
				amount_discount: totalPriceRemise,
				discount_rate: remise,
			},
			() => {
				updateFactureContext({
					...card,
					amount_discount: totalPriceRemise,
					discount_rate: remise,
					total: total,
				});
				setEditModalStatus(false);
			},
			(error) => {
				setError(error);
			},
		);


	};

	const handelDelete = () => {

		deleteFacture(userData, card.id, () => {
			/* const newDevis = {
					id:card.id,
					state:"sale"
				}
				updateSingleDevis(
					userData?.password,
					newDevis,
					()=>{
						
					}
				) */
			searchFactures(
				userData?.password,
				[
					'&',
					[
						'date',
						'>=',
						`${moment(new Date()).startOf('month').format('YYYY-MM-D')} 00:00:00`,
					],
					[
						'date',
						'<',
						`${moment(new Date()).endOf('month').format('YYYY-MM-D')} 23:59:00`,
					],
				],
				(dataResult) => {
					setFacture(
						organizationDataFacturation(dataResult?.elements, () => {
							setEditModalStatus(false);
							loadFactures();
						}),
					);
				},
				() => { },
				null,
				1,
				0,
			);
		});

	};
	const handelSend = () => {
		setLoading(true);
		sendInvoiceByMail(
			userData,
			card?.id,
			() => {
				setSuccess('Email envoyé avec succés');
				setLoading(false);
			},
			(error) => {
				setError(error);
				setLoading(false);
			},
		);
	};

	const formik = useFormik({
		initialValues: {
			cardName: card.name || '',
			state: card?.state || '',
			project_title: card.project_title || '',
			state_devis: card.state_devis || '',
			partner_id: card?.partner_id,
			nom_utilisateur: card?.nom_utilisateur,
		},
		onSubmit: (/* values */) => {
			handelUpdate();
		},
	});

	const factureType = (type) => {
		switch (type) {
			case 'posted':
				return 'Comptabilisée';
			case 'cancel':
				return 'Annulée';
			case 'paid':
				return 'Acquittée';
			default:
				return 'Brouillon';
		}
	};
	return (
		<>
			<Modal
				setIsOpen={() => { }} isOpen={true} size='xl' isScrollable classNameMd='nv-2'>
				<ModalHeader className='px-4 pb-0' setIsOpen={setEditModalStatus}>
					{/* <ModalTitle id='project-edit'>{card.name}</ModalTitle> */}
				</ModalHeader>
				<ModalBody className='pb-0 pt-0'>
					<Card hasTab shadow={'none'} className='pt-0 mb-0'>
						<CardTabItem id='tab' title='Détails'>
							<div className='row'>
								<div className='col-md-7'>
									<Card shadow='sm' stretch>
										<CardHeader className='justify-content-center'>
											<CardLabel>
												<CardTitle className='text-muted'>
													Informations Facture
												</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody className='pb-0'>
											<div className='row g-4'>
												<div className='col-lg-4 col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<Icon
																icon='report'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>Réf</div>
															<div className='fw-bold fs-6 mb-0'>
																{formik.values.cardName}
															</div>
														</div>
													</div>
												</div>
												<div className='col-lg-8 col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<Icon
																icon='ListAlt'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>Titre</div>
															<div className='fw-bold fs-6 mb-0'>
																{formik.values.project_title}
															</div>
														</div>
													</div>
												</div>
												<div className='col-lg-4 col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<Icon
																icon='OpenInNew'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>
																Réf devis
															</div>
															<div className='fw-bold fs-6 mb-0'>
																{card.invoice_origin}
															</div>
														</div>
													</div>
												</div>
												<div className='col-lg-8 col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<Icon
																icon='CustomTech'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>
																Responsable
															</div>
															<div className='fw-bold fs-6 mb-0'>
																{formik.values.nom_utilisateur}
															</div>
														</div>
													</div>
												</div>

												<div className='col-lg-4 col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<Icon
																icon='Today'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>Date</div>
															<div className='fw-bold fs-6 mb-0'>
																{moment(card.date).format(
																	'DD-MM-YYYY',
																)}
															</div>
														</div>
													</div>
												</div>
												<div className='col-lg-8 col-12'>
													<div className='d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<Icon
																icon='Settings'
																size='2x'
																color='primary'
															/>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='text-muted'>Statut</div>
															<div className='fw-bold fs-6 mb-0'>
																{factureType(formik.values.state)}
															</div>
														</div>
													</div>
												</div>
												{/* <FormGroup className='col-6' id='tags' label='Etats'>
											<Select
												ariaLabel='Board select'
												placeholder='Select group'
												onChange={formik.handleChange}
												value={formik.values.state_devis}
												disabled
											>
												{Object.keys(STATE_DEVIS).map((t) => {
													return(
														<Option key={STATE_DEVIS[t].id} value={STATE_DEVIS[t].id}>
															{
																// @ts-ignore
																STATE_DEVIS[t].title
															}
														</Option>
													)
												})}
											</Select>
										</FormGroup> */}
											</div>
										</CardBody>
										<CardFooter>
											<Button
												color='info'
												isLight
												icon={!loading && 'ScheduleSend'}
												className='text-nowrap'
												onClick={handelSend}
												isDisable={loading}>
												{loading && <Spinner isSmall inButton />}
												{loading ? 'En cours' : 'Envoyer'}
											</Button>
											<Button
												tag='a'
												color='primary'
												icon='FileDownload'
												className='text-nowrap'
												isLight
												href={getReport(
													userData,
													card?.id,
													'account.move',
												)}
												target='_blank'>
												Imprimer
											</Button>

											{card?.state == 'draft' &&
												card?.invoice_payment_state == 'not_paid' &&
												(userData?.role
													?.toLowerCase()
													.includes('comptabilite') ||
													userData?.role
														?.toLowerCase()
														.includes('admin')) && (
													<Button
														color='success'
														icon='Verified'
														isLight
														className='text-nowrap'
														onClick={() => setPaymentModalStatus(true)}>
														Comptabiliser
													</Button>
												)}
										</CardFooter>
									</Card>
								</div>
								<div className='col-md-5'>
									<Card className='sm' stretch>
										<CardHeader className='justify-content-center'>
											<CardLabel>
												<CardTitle className='text-muted'>
													Informations Client
												</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											<div className='row g-3'>
												{!!card?.partner_id?.name && (
													<div className='col-12'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='person'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	Nom du client
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{card?.partner_id?.name}
																</div>
															</div>
														</div>
													</div>
												)}
												{!!card?.partner_id?.email && (
													<div className='col-12'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Mail'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	E-mail client
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{card?.partner_id?.email}
																</div>
															</div>
														</div>
													</div>
												)}

												{!!card?.partner_id?.mobile && (
													<div className='col-12'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='PhoneAndroid'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	Mobile
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{card?.partner_id?.mobile}
																</div>
															</div>
														</div>
													</div>
												)}

												{!!card?.partner_id?.phone && (
													<div className='col-12'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Phone'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	Téléphone
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{card?.partner_id?.phone}
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										</CardBody>
									</Card>
								</div>
								<div className='col-md-12'>
									{card?.id && (
										<FactureLinesContextProvider>
											<DevisTable
												cardId={card?.id}
												card={card}
												isDisabled={true}
												totalPriceRemise={totalPriceRemise}
												settotalPriceRemise={settotalPriceRemise}
												remise={remise}
												setRemise={setRemise}
												total={total}
												setTotal={setTotal}
												totalUntaxed={totalUntaxed}
												setTotalUntaxed={setTotalUntaxed}
												acompte={acompte}
											/>
										</FactureLinesContextProvider>
									)}
								</div>
							</div>
						</CardTabItem>

						<CardTabItem id='tab' title='Paiements'>
							<PaymentJustifyTable
								invoiceId={card?.id}
								Invoice={card}
								updateFactureContext={updateFactureContext}
							/>

							{/* {!!card?.paiement_digital?.length && (
								<Card stretch>
									<CardBody>
										<div className='row g-4'>
											{card?.paiement_digital.map(
												({ id, payment_date, journal_id, amount }) => {
													return (
														<div key={id} className='col-12'>
															<div className='row'>
																<div className='col d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<div
																			style={{ width: 100 }}
																			className={classNames(
																				`bg-l10-success text-success py-2 rounded-pill me-3 text-center`,
																			)}>
																			success
																		</div>
																	</div>
																	<div className='flex-grow-1'>
																		<div className='fs-6'>
																			{payment_date}
																		</div>
																	
																	</div>
																	<div className='flex-grow-1'>
																		<div className='fs-3'>
																			{journal_id?.name}
																		</div>
																		
																	</div>
																	<div className='flex-grow-1'>
																		<Button
																			color='info'
																			icon='CreditCard'
																			isLight
																			isLink={true}
																			tag='a'
																			href={imagePayement(
																				userData?.password,
																				id,
																			)}
																			target='_blank'
																			//onClick={() => setModalStatus(true)}
																		>
																			Image
																		</Button>
																		
																	</div>
																</div>

																<div className='col-auto text-end'>
																	<div>
																		<strong>
																			{priceFormat(amount)}
																		</strong>
																	</div>

																	
																</div>
															</div>
														</div>
													);
												},
											)}
										</div>
									</CardBody>
								</Card>
							)} */}
						</CardTabItem>
					</Card>
				</ModalBody>
				{/* {card?.state == 'draft' && (
					<ModalFooter className='px-4 pb-4'>
						<Button
							color='primary'
							className='w-auto'
							type='submit'
							onClick={formik.handleSubmit}>
							Enregistrer
						</Button>
						
					</ModalFooter>
				)} */}
			</Modal>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
			{paymentModalStatus && (
				<ComptabiliserPopup
					setPaymentModalStatus={setPaymentModalStatus}
					amountResidual={card?.amount_residual}
					invoiceId={card?.id}
					Invoice={card}
					setAcompte={setAcompte}
				/>
			)}
			{/* {paymentModalStatus && (
				<PaymentPopup
					setPaymentModalStatus={setPaymentModalStatus}
					amountResidual={card?.amount_residual}
					invoiceId={card?.id}
					Invoice={card}
				/>
			)} */}
		</>
	);
};

FacturePopUp.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	card: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	setEditModalStatus: PropTypes.func.isRequired,
};

export default FacturePopUp;
