import React, { useContext, useState, useEffect } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../bootstrap/Modal';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Button from '../../bootstrap/Button';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import ListGroup, { ListGroupItem } from '../../bootstrap/ListGroup';
import Spinner from '../../bootstrap/Spinner';
import FormGroup from '../../bootstrap/forms/FormGroup';
import SearchAutoComplete from '../../customComponents/SearchAutoComplete';
import LoadSpinner from '../../Spinner/LoadSpinner';
import Alert from '../../bootstrap/Alert';
import useDarkMode from '../../../hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createClientPartner, searchClientPartner } from '../../../odooApi/clientPartner';
import { AutoComplete, getPlaceDetails } from '../../../googleApi/placeAutoComplete';
import AuthContext from '../../../contexts/authContext';
import useOutsideClick from '../../customComponents/SearchAutoComplete/useOutsideClick';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import NavigatePopup from './navigatePopup';
import Label from '../../bootstrap/forms/Label';
import QuestionnairePopup from '../../Simulateur/QuestionnairePopup';
import { clientTable, planningMenu } from '../../../gestioneerMenu';
import { getReport } from '../../../common/function/functionsUtils';
import PoseContext from '../../../contexts/poseContext';
import { searchCountryState } from '../../../odooApi/country';
import { updateClient } from '../../../reminderApi/Clients';
import StatusPopup from '../../PlanningCalendar/StatusPopup';
import ErrorPopup from '../../extras/ErrorPopup';

export default function Appel({ setOpenAppel }) {
	const { darkModeStatus } = useDarkMode();
	const { userData } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [clientIsLoading, setClientIsLoading] = useState(false);
	const [clientData, setClientData] = useState({});

	const [error, setError] = useState(false);
	const [data, setData] = useState([]);
	const [resultSearch, setResultSearch] = useState([]);
	const [selectItem, setSelectItem] = useState();
	const [open, setOpen] = useState(false);
	const [technicien, setTechnicien] = useState();
	const [date, setDate] = useState(new Date());
	const [stateId, setStateId] = useState('');
	const navigate = useNavigate();
	const [filterResult, setfilterResult] = useState({});
	const [openList, setOpenList] = useState(false);
	const [navigationOpen, setNavigationOpen] = useState(false);
	const [questionnaire, setQuestionnaire] = useState(false);
	const { pose, setPose } = useContext(PoseContext);
	const [spinner, setSpinner] = useState(false);
	const motifs = [
		{ id: 1, name: 'Renseignement' },
		{ id: 2, name: 'Nouvelle intervention' },
		{ id: 3, name: 'Réclamation' },
		{ id: 4, name: 'Comptabilité' },
		{ id: 5, name: 'Autres' },
	];

	const formik = useFormik({
		initialValues: {
			id: '',
			name: '',
			email: '',
			mobile: '',
			adrsFact: '',
			street: '',
			street2: '',
			city: '',
			zip: '',
			motif: 'Renseignement',
			motif_id: 1,
		},

		onSubmit: (values) => {
			//handelUpdate(values);
		},
	});
	const handleClickOutside = () => {
		setOpenList(false);
	};

	const ref = useOutsideClick(handleClickOutside);
	const handleChange = (e) => {
		formik.handleChange(e);
		formik?.setFieldValue('name', e?.target?.value);
		setOpenList(true);
		if (e?.target?.value == '') {
			setfilterResult([]);
			formik?.setFieldValue('name', '');
			formik?.setFieldValue('email', '');
			formik?.setFieldValue('mobile', '');
			formik.setFieldValue('adrsFact', '');
			setData([]);
		} else {
			if (userData && userData?.id && userData?.password) {
				setClientIsLoading(true);
				searchClientPartner(
					userData?.password,
					[
						'|',
						['name', 'like', e.target.value],
						'|',
						[
							'name',
							'like',
							e.target.value.charAt(0).toUpperCase() +
							e.target.value.substring(1).toLowerCase(),
						],
						'|',
						['name', 'like', e.target.value.toUpperCase()],
						'|',
						['email', 'like', e.target.value],
						'|',
						['mobile', 'like', e.target.value],
						['phone', 'like', e.target.value],
					],
					({ elements }) => {
						setfilterResult(elements);
						setClientIsLoading(false);
					},
				);
			}
		}
		AutoComplete(e.target.value, (result) => {
			const newLocations = [];
			result?.predictions?.map((item) => {
				newLocations.push({
					id: item?.place_id,
					label: item?.description,
					icon: '',
				});
			});
			setResultSearch(newLocations);
		});
	};

	const handleChangePlace = (e) => {
		formik.handleChange(e);
		AutoComplete(e.target.value, (result) => {
			const newLocations = [];
			result?.predictions?.map((item) => {
				newLocations.push({
					id: item?.place_id,
					label: item?.description,
					icon: '',
				});
			});
			setResultSearch(newLocations);
		});
	};

	const handelSelectItem = (item) => {
		formik.setFieldValue('adrsFact', item?.label);
		getPlaceDetails(item?.id, (result) => {
			setSelectItem(result?.result);
			formik.setFieldValue(
				'city',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('locality'),
				)[0]?.long_name ||
				result?.result?.address_components.filter((item) =>
					item?.types.includes('administrative_area_level_2'),
				)[0]?.long_name ||
				'',
			);
			formik.setFieldValue(
				'zip',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('postal_code'),
				)[0]?.long_name || '',
			);
			formik.setFieldValue(
				'street',
				(result?.result?.address_components.filter((item) =>
					item?.types.includes('street_number'),
				)[0]?.long_name || '') +
				' ' +
				(result?.result?.address_components.filter((item) =>
					item?.types.includes('route'),
				)[0]?.long_name || ''),
			);
			formik.setFieldValue(
				'street2',
				result?.result?.address_components.filter((item) =>
					item?.types.includes('administrative_area_level_3'),
				)[0]?.long_name ||
				result?.result?.address_components.filter((item) =>
					item?.types.includes('administrative_area_level_4'),
				)[0]?.long_name ||
				result?.result?.address_components.filter((item) =>
					item?.types.includes('administrative_area_level_5'),
				)[0]?.long_name ||
				'',
			);
			// !!selectitem?.address_components.filter((item) =>
			// 				item?.types.includes('administrative_area_level_1'),
			// 			)[0]?.long_name && searchCountryState(
			// 	userData?.password,
			// 	[
			// 		[
			// 			'name',
			// 			'=',
			// 			selectItem.address_components.filter((item) =>
			// 				item?.types.includes('administrative_area_level_1'),
			// 			)[0]?.long_name,
			// 		],
			// 	],
			// 	({ elements }) => {

			// 	}, () => {})
		});
	};

	useEffect(() => {
		if (!!formik?.values?.adrsFact?.length) {
			setData([]);
		}
		if (!(formik.values?.adrsFact?.length > 0)) {
			formik.values.zip = '';
			formik.values.street = '';
			formik.values.city = '';
		}
	}, [formik.values.adrsFact]);
	const handleCreateClient = (values, fn) => {
		if (userData && userData?.id && userData?.password) {
			setSpinner(true);
			setError(false);
			if (!!selectItem) {
				searchCountryState(
					userData?.password,
					[
						[
							'name',
							'=',
							selectItem.address_components.filter((item) =>
								item?.types.includes('administrative_area_level_1'),
							)[0]?.long_name,
						],
					],
					({ elements }) => {
						const newUser = {
							name: values.name,
							mobile: values.mobile || '',
							email: values.email || '',
							city: values.city || '',
							zip: values.zip || '',
							street: values.street || '',
							places: selectItem?.url,
							country_id: 75,
							state_id: (!!elements?.length && elements[0]?.id) || '',
						};

						createClientPartner(
							userData,
							newUser,
							(data) => {
								setSpinner(false);
								setError(false);
								setClientData(data);
								fn(data);
							},
							(error) => {
								setSpinner(false);
								setError(error);
							},
						);
					},
					(error) => {
						setSpinner(false);
						setError(error);
					},
				);
			} else {
				const newUser = {
					name: values.name,
					mobile: values.mobile || '',
					email: values.email || '',
					city: values.city || '',
					zip: values.zip || '',
					street: values.street || '',
					country_id: 75,
				};

				createClientPartner(
					userData?.password,
					newUser,
					(data) => {
						setSpinner(false);
						setError(false);
						setClientData(data);
						fn(data);
					},
					(error) => {
						setSpinner(false);
						setError(error);
					},
				);
			}
		}
	};
	const handleUpdateClient = (values, fn) => {
		setSpinner(true);
		setError(false);
		if (!!selectItem) {
			searchCountryState(
				userData?.password,
				[
					[
						'name',
						'=',
						selectItem.address_components.filter((item) =>
							item?.types.includes('administrative_area_level_1'),
						)[0]?.long_name,
					],
				],
				({ elements }) => {
					const newUser = {
						name: formik.values.name,
						mobile: formik.values.mobile || '',
						email: formik.values.email || '',
						city: formik.values.city || '',
						zip: formik.values.zip || '',
						street: formik.values.street || '',
						places: selectItem?.url,
						country_id: 75,
						state_id: (!!elements?.length && elements[0]?.id) || '',
					};
					updateClient(
						userData?.tokenJava,
						clientData?.id,
						newUser,
						(result) => {
							setSpinner(false);
							setError(false);
							setClientData(result);
							fn(result);
						},
						(error) => {
							setSpinner(false);
							setError(error);
						},
					);
				},
				(error) => {
					setSpinner(false);
					setError(error);
				},
			);
		} else {
			const newUser = {
				name: values.name,
				mobile: values.mobile || '',
				email: values.email || '',
				city: values.city || '',
				zip: values.zip || '',
				street: values.street || '',
			};
			updateClient(
				userData?.tokenJava,
				clientData?.id,
				newUser,
				(result) => {
					setSpinner(false);
					setError(false);
					setClientData(result);
					fn(result);
				},
				(error) => {
					setSpinner(false);
					setError(error);
				},
			);
		}
	};

	const handelSelect = (item) => {
		console.log(item);
		setOpenList(false);

		// setSelectedClient(item);
		formik?.setFieldValue('name', item?.name);
		formik?.setFieldValue('email', item?.email);

		formik?.setFieldValue('mobile', !!item?.mobile ? item?.mobile : item?.phone);
		formik.setFieldValue(
			'adrsFact',
			item?.street +
			' ' +
			item?.zip +
			' ' +
			item?.city +
			' ' +
			item?.country_id?.display_name,
		);
		formik.setFieldValue(
			'adrsFact',
			item?.street +
			' ' +
			item?.zip +
			' ' +
			item?.city +
			' ' +
			item?.country_id?.display_name,
		);
		formik.setFieldValue('zip', item?.zip);
		formik.setFieldValue('city', item?.city);
		formik.setFieldValue('street', item?.street);
		setfilterResult([]);
		setClientData(item);
	};

	const handleSubmitAppel = (remarque, idQuestionnaire) => {
		setPose({
			clientData: clientData,
			Remarque: remarque || '',
			attachmentsCommercial: !!idQuestionnaire
				? [getReport(userData, idQuestionnaire, 'add.rapport.intervention')]
				: [],
			motif: formik?.values?.motif || '',
		});
		navigate('/' + planningMenu?.path);
		setOpenAppel(false);
	};

	const handleSubmit = () => {
		if (!!clientData?.id) {
			handleUpdateClient(formik?.values, (result) => {
				if (formik?.values?.motif_id == 1 || formik?.values?.motif_id == 2) {
					setNavigationOpen(true);
				} else {
					setPose({
						clientData: result,
						motif: formik?.values?.motif || '',
					});
					navigate('/' + clientTable?.clientID?.path + '/' + clientData?.id);
					setOpenAppel(false);
				}
			});
		} else {
			handleCreateClient(formik?.values, (result) => {
				if (formik?.values?.motif_id == 1 || formik?.values?.motif_id == 2) {
					setNavigationOpen(true);
				} else {
					setPose({
						clientData: result,
						motif: formik?.values?.motif || '',
					});
					navigate('/' + clientTable?.clientID?.path + '/' + result?.id);
					setOpenAppel(false);
				}
			});
		}
	};
	return (
		<>
			<Modal isOpen={true} setIsOpen={() => { }} size={'lg'}>
				<ModalHeader setIsOpen={() => setOpenAppel(false)}>
					<CardLabel iconColor='primary'>
						<CardTitle>Appel</CardTitle>
					</CardLabel>
				</ModalHeader>
				<ModalBody>
					{/* <Card isCompact shadow={'none'}> */}
					<CardBody>
						<div className='row g-4 '>
							<CardLabel icon='Settings' iconColor='primary'>
								<CardTitle> Motif de l'appel </CardTitle>
							</CardLabel>
							<div className='col-12'>
								<ChecksGroup>
									{!!motifs?.length &&
										motifs?.map((item) => (
											<Checks
												isInline
												type='radio'
												id={'motif-' + item?.id}
												label={item?.name}
												// value={formik.values.motif}
												name='motif'
												onChange={() => {
													// formik.setFieldValue(
													// 	'motif',
													// 	item.id,
													// );
													formik.setFieldValue('motif', item?.name);
													formik.setFieldValue('motif_id', item?.id);

													// formik.setValues({
													// 	motif_id: item?.id,
													// });
												}}
												checked={formik.values.motif == item?.name && true}
											/>
										))}
								</ChecksGroup>
							</div>
						</div>
						<div className='row g-3 mt-3'>
							<CardLabel icon='AccountCircle' iconColor='primary'>
								<CardTitle> Client </CardTitle>
							</CardLabel>
							<div className='row g-4'>
								<div className='col-12 ' ref={ref}>
									<FormGroup
										id='name'
										label='Nom du client'
										className='autocomplete'>
										<Input
											id='name'
											onChange={handleChange}
											onFocus={handleChange}
											value={formik?.values?.name}
											name='name'
											autoComplete='off'
										/>
										{openList && (
											<div
												id='myInputautocomplete-list'
												className='autocomplete-items'>
												<div className='body'>
													{clientIsLoading ? (
														<div>
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />{' '}
															<Spinner isSmall isGrow />
														</div>
													) : (
														<>
															{/* <div

																onClick={() => {
																	setOpenList(false)
																	setfilterResult([]);
																	// formik?.setFieldValue('name', '');
																	formik?.setFieldValue('email', '');
																	formik?.setFieldValue('mobile', '');
																	formik.setFieldValue('adrsFact', '');
																}}>
																<Icon
																	color={'success'}
																	isLight
																	icon={'Add'}
																	size='lg'
																/>
																Ajouter un client ...
															</div> */}
															{!!filterResult.length &&
																filterResult?.map((item) => (
																	<div
																		key={item?.id}
																		onClick={() => {
																			handelSelect(item);
																		}}>
																		<Icon
																			color={'primary'}
																			isLight
																			icon={'Person'}
																			size='lg'
																		/>
																		{item?.name}{' '}
																		<Icon
																			color={'primary'}
																			isLight
																			icon={'Place'}
																			size='lg'
																		/>
																		{item?.street +
																			' ' +
																			item?.zip +
																			' ' +
																			item?.city}
																	</div>
																))}
														</>
													)}
												</div>
											</div>
										)}
									</FormGroup>
								</div>

								<FormGroup id='mobile' label='Téléphone' className='col-lg-6'>
									<Input
										type='number'
										id='mobile'
										name='mobile'
										value={formik?.values?.mobile}
										onChange={formik?.handleChange}
									/>
								</FormGroup>

								<FormGroup className='col-lg-6 ' id='email' label='Adresse e-mail'>
									<Input
										onChange={formik.handleChange}
										value={formik.values.email}
										name='email'
										type='email'
										autoComplete='off'
									/>
								</FormGroup>

								<SearchAutoComplete
									label='Adresse Facturation'
									className='col-12'
									value={formik?.values.adrsFact}
									onChange={handleChangePlace}
									options={resultSearch}
									setSelectItem={handelSelectItem}
								/>
								<FormGroup className='col-lg-6' id='street' label='Rue'>
									<Input
										type='text'
										name='street'
										value={formik?.values?.street}
										onChange={formik?.handleChange}
									/>
								</FormGroup>
								<FormGroup id='zip' className='col-lg-3' label='Code postal'>
									<Input
										type='number'
										name='zip'
										value={formik?.values?.zip}
										onChange={formik?.handleChange}
									/>
								</FormGroup>

								<FormGroup id='city' className='col-lg-3' label='Ville'>
									<Input
										type='text'
										name='city'
										value={formik?.values?.city}
										onChange={formik?.handleChange}
									/>
								</FormGroup>
							</div>
						</div>

						{!!error && (
							<Alert color='warning' isLight icon='Report' className='mt-3'>
								{!!error?.message ? error?.message : error}
							</Alert>
						)}
					</CardBody>
					{/* </Card> */}
				</ModalBody>
				<ModalFooter>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						style={{
							color: darkModeStatus ? 'dark' : 'light',
							hoverShadow: 'default',
							isLight: !darkModeStatus,
						}}
						isLight
						icon={'save'}
						color='primary'
						onClick={handleSubmit}
						isDisable={spinner}>
						{spinner && <Spinner isSmall inButton />}
						{spinner ? 'En cours' : 'Sauvegarder'}
					</Button>
				</ModalFooter>
			</Modal>
			{navigationOpen && (
				<NavigatePopup
					icon='ContactSupport'
					color='warning'
					setOpen={setNavigationOpen}
					title='Etape suivante'
					label='Ouvrir le questionnaire ou aller à la page planning ?'
					btn1='Questionnaire'
					btn2='Planning'
					fn1={() => {
						setQuestionnaire(true);
					}}
					fn2={() => handleSubmitAppel()}
				/>
			)}
			{!!questionnaire && (
				<QuestionnairePopup
					setOpenPopup={setQuestionnaire}
					handleSubmitAppel={handleSubmitAppel}
				/>
			)}
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
			{/* {!!open && (
				<SinglePlanningPopup
					setOpen={setOpen}
					date={date}
					technicien={technicien}
					clientData={clientData}
				/>
			)} */}
		</>
	);
}
