import { useContext, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../bootstrap/Button';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../contexts/authContext';
import { sendDechargeByMail, updateSingleDevis, uploadSignatureDevis } from '../../odooApi/devis';
import { createFacture } from '../../odooApi/accountMove';
import { createAtelier } from '../../odooApi/atelier';
import Label from '../bootstrap/forms/Label';
import Checks from '../bootstrap/forms/Checks';
import Icon from '../icon/Icon';
import { uploadAttachementsTechnicien } from '../../odooApi/planningSlot';
import { convertURL, getReportProduction } from '../../common/function/functionsUtils';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import Spinner from '../bootstrap/Spinner';
import ErrorPopup from '../extras/ErrorPopup';
import DevisContext from '../../contexts/devisContext';

const SignaturePopup = ({ setEditModalStatus, Devis, handleUpdate, remarque }) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [sigPad, setSigPad] = useState({});
	const [disabledBtn, setDisabledBtn] = useState(true);
	const { userData } = useContext(AuthContext);
	const [imageUrl, setimageUrl] = useState();
	const [signature, setSignature] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const { setDevis } = useContext(DevisContext);

	const updateDevisContext = (values) => {
		setDevis((prev) => [
			...prev.map((group) => {
				if (group.state === values.state) {
					let cardIndex = group.cards.findIndex((card) => card.id === values.id);
					if (cardIndex !== -1) {
						let newCards = [...group.cards];
						newCards[cardIndex] = values;
						return {
							...group,
							cards: newCards,
						};
					} else {
						return {
							...group,
							cards: [values, ...group.cards],
						};
					}
				}

				return {
					...group,
					cards: group.cards.filter((card) => card.id !== values.id),
				};
			}),
		]);
	};

	// const handelSendMail = () => {
	// 	if (userData && userData?.id && userData?.password) {
	// 		sendDechargeByMail(
	// 			userData?.password,
	// 			Devis?.id,
	// 			() => {
	// 				setSuccess(
	// 					'E-mail envoyé à ' +
	// 						Devis?.email_client +
	// 						', contient : *' +
	// 						Devis?.name +
	// 						' * Décharge',
	// 				);
	// 				setSpinner(false);
	// 			},
	// 			(error) => {
	// 				setError(error);
	// 				setSpinner(false);
	// 			},
	// 		);
	// 	}
	// };
	const handleClearSignature = () => {
		sigPad?.clear();
		setDisabledBtn(true);
	};

	const confirmSansSignature = () => {
		updateSingleDevis(
			userData,
			{ id: Devis?.id },
			() => {
				setEditModalStatus(false);
				updateDevisContext({
					...Devis,
					invoice_date: Devis?.date_order,
					project_title: Devis?.project_title,

					state: 'done',
				});
				handleUpdate();
			},
			(error) => {
				setSpinner(false);
				setError(error);
			},
		);
	};

	const confirmAvecSignature = () => {
		uploadSignatureDevis(
			userData,
			Devis?.id,
			sigPad?.getTrimmedCanvas().toDataURL('image/png').split(',')[1],
			(result) => {
				updateDevisContext({
					...Devis,
					invoice_date: Devis?.date_order,
					project_title: Devis?.project_title,
					signature: sigPad
						?.getTrimmedCanvas()
						.toDataURL('image/png')
						.split(',')[1],
					state: 'done',
				});
				handleUpdate();

			},
			(error) => {
				setSpinner(false);
				setError(error);
			},
		);
	};
	const handleConfirm = () => {
		//console.log('sigPad()', sigPad?.getTrimmedCanvas().toDataURL('image/png').split(',')[1]);
		setSpinner(true);
		const newLine = {
			id: Devis?.id,
			state: 'done',
			remarque: remarque,
		};
		updateSingleDevis(
			userData,
			newLine,
			() => {

			},
			(error) => {
				setSpinner(false);
				setError(error);
			},
		);
		if (!!signature) {
			if (sigPad.isEmpty()) {
				confirmSansSignature();
			} else {
				confirmAvecSignature();
			}
		} else {
			confirmSansSignature();
		}

	};

	const handleCancel = () => {
		setEditModalStatus(false);
	};

	return (
		<Modal setIsOpen={() => { }} isOpen={true} size='md' isScrollable classNameMd='nv-3'>
			<ModalHeader className='px-4' setIsOpen={setEditModalStatus}>
				<ModalTitle id='project-edit'>
					<Icon icon={'Verified'} size={'2x'} className='me-2'></Icon>Confirmation Client
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<CardHeader>
					<CardLabel>
						<CardSubTitle>
							<Checks
								className='fw-bold fs-6'
								type='checkbox'
								id='signature'
								label='Signature'
								name='signature'
								onChange={() => setSignature(!signature)}
								checked={signature}
							/>
						</CardSubTitle>
					</CardLabel>
					{!!signature && (
						<CardActions>
							<Button
								color='primary'
								className='w-auto'
								icon='Replay'
								isLight
								//type='submit'
								onClick={handleClearSignature}>
								Effacer
							</Button>
						</CardActions>
					)}
				</CardHeader>
				{!!signature && (
					<Card shadow={'md'}>
						<div style={{ height: '250px' }}>
							<SignatureCanvas
								penColor='black'
								canvasProps={{
									style: { width: '100%', height: 250 },
									className: 'sigCanvas',
								}}
								backgroundColor='white'
								ref={(ref) => {
									setSigPad(ref);
								}}
								onBegin={() => {
									setDisabledBtn(false);
								}}
							/>
						</div>
					</Card>
				)}

				<div className='row d-flex justify-content-end'>
					<div className='w-auto'>
						<Button
							color='success'
							type='submit'
							onClick={handleConfirm}
							isDisable={spinner}>
							{spinner && <Spinner isSmall inButton />}
							{spinner ? 'En cours' : 'Confirmer'}
						</Button>
					</div>

					<div className='w-auto'>
						<Button color='danger' onClick={handleCancel}>
							{t('Cancel')}
						</Button>
					</div>
				</div>
			</ModalBody>
			{!!success && (
				<StatusPopup
					icon='MarkEmailRead'
					color='success'
					title='E-mail envoyé avec succès'
					label={success}
					setOpen={(param) => {
						setSuccess(param);
						setEditModalStatus(param);
					}}
				/>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
		</Modal>
	);
};

export default SignaturePopup;
