import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import UserImage from '../../../assets/img/wanna/wanna1.png';
import UserImageWebp from '../../../assets/img/wanna/wanna1.webp';
import SERVICES from '../../../common/data/serviceDummyData';
import logo from '../../../assets/logos/logo-gestioneer.svg';

import { getImageByModule } from '../../../common/function/functionsUtils';
import getUserToken from '../../../odooApi/authenticate';
import Alert from '../../../components/bootstrap/Alert';
import createOrUpdateDocDeviceAuth, { AUTH_STATES } from '../../../common/function/createOrUpdateDocDeviceAuth';
import Icon from '../../../components/icon/Icon';
import { login } from '../../../apis/backend/auth';


const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Accountg,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'></div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { userData, setUserData } = useContext(AuthContext);

	//const [user, setuser] = useState();
	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState(false);
	const [singUpStatus, setSingUpStatus] = useState(!!isSignUp);
	const [isLoading, setisLoading] = useState(false);
	const [deviceId, setDeviceId] = useState('');
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);
	const [userAgent, setUserAgent] = useState();
	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		const generateDeviceId = async () => {
			try {
				// Use the SubtleCrypto API to generate a hash
				const encoder = new TextEncoder();
				setUserAgent(navigator.userAgent);
				const data = encoder.encode(navigator.userAgent + Date.now().toString());
				const hashBuffer = await crypto.subtle.digest('SHA-256', data);

				// Convert the hash buffer to a hex string
				const hashArray = Array.from(new Uint8Array(hashBuffer));
				const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

				setDeviceId(hashHex);
			} catch (error) {
				console.error('Error generating device ID:', error);
			}
		};

		generateDeviceId();
	}, []);


	const formik = useFormik({
		enableReinitialize: false,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Requis';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Requis';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setisLoading(true);
			handleSubmit(values);

		},
	});

	
	const handleSubmitOld = async (values) => {
		await getUserToken(
			values.loginUsername,
			values.loginPassword,
			async (result) => {
				const now = new Date();
				await setUserData({
					id: result?.id || 0,
					username: result?.name || '',
					name: result?.name || '',
					surname: result?.surname || '',
					position: result?.role || '',
					role: result?.role || '',
					employee: result?.employee || '',
					email: result?.email || 'admin@admin.fr',
					src: result?.employee
						? getImageByModule(result?.token, result?.employee, 'hr.employee')
						: UserImage,
					srcSet: result?.employee
						? getImageByModule(result?.token, result?.employee, 'hr.employee')
						: UserImageWebp,
					isOnline: true,
					isReply: true,
					color: 'primary',
					services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
					password: result?.token || '',
					tokenJava: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI1OCJ9.TRoEmcKCim9FUiREB3hb3r1s7Q7Zhm9WSuxyBRs4XwYtoqfkVRhA94VoJOXVVPa3dyT4Ikpa1Rehq5QFeU3OXw',
					voiture: result?.voiture,
					logintime: now.getTime(),
					deviceKey: deviceId,
					company_id: result?.company_id || '',
					company_ids: result?.company_ids
				});
				createOrUpdateDocDeviceAuth(
					result?.id,
					{
						deviceKey: deviceId,
						username: result?.name || '',
						state: /* result?.role == "SuperAdmin" ?  */AUTH_STATES?.login /* : AUTH_STATES?.requestLogin */,
						userImage: result?.employee
							? getImageByModule(result?.token, result?.employee, 'hr.employee')
							: UserImage,
						userAgent: userAgent
					},
					() => {

						

						setisLoading(false);
						//navigate('/');
						window.location.href="/"
					}
				)






			},
			() => {
				formik.setFieldError(
					'loginPassword',
					"Vérifiez votre nom d'utilisateur/mot de passe",
				);
				setisLoading(false);
			},
		);
	}

	const handleSubmit = (values) => {
		//handleSubmitOld(values)
		login(
			{
				userName:values?.loginUsername,
				password:values?.loginPassword
			},
			async (userData)=>{
				
				await setUserData({
					username:userData?.userName,
					...userData
				});
				setisLoading(false);
				//console.log("attttttt",userData);
				//setisLoading(false);
				//navigate('/');
				window.location.href = "/";
			},
			()=>{
				setisLoading(false);
			}
		);
	}
	return (
		<PageWrapper
			isProtected={userData?.id}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-primary': !singUpStatus, 'bg-info': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						{
							userData && userData?.id && userData?.password ?
								<Card className='shadow-3d-dark' data-tour='login-page'>
									<CardBody>
										<Alert

											icon={"AddAlert"}
											isLight
											color={"info"}
											className='flex-nowrap cursor-pointer'
										>
											<div className='me-4'>La demande de connexion a été complétée avec succès. Vous devez attendre que l'administrateur accepte la connexion.</div>
											
										</Alert>
									</CardBody>
								</Card> :

								<Card className='shadow-3d-dark' data-tour='login-page'>
									<CardBody>
										<div className='text-center '>
											{/* <Link
												to='/'
												className={classNames(
													'text-decoration-none  fw-bold display-2',
													{
														'text-dark': !darkModeStatus,
														'text-light': darkModeStatus,
													},
												)}> */}
												{/* <Logo width={200} /> */}
												<img src={logo} alt='logo' width={300} height={200} />
											{/* </Link> */}
										</div>
										
										<div
											className={classNames('rounded-3', {
												'bg-l10-dark': !darkModeStatus,
												'bg-dark': darkModeStatus,
											})}>
											
										</div>

										<LoginHeader isNewUser={singUpStatus} />

										
										<form className='row g-4'>
											<>
												<div className='col-12'>
													<FormGroup
														id='loginUsername'
														isFloating
														label="Nom d'utilisateur"
														className={classNames({
															'd-none': signInPassword,
														})}>
														<Input
															autoComplete='username'
															value={formik.values.loginUsername}
															isTouched={formik.touched.loginUsername}
															invalidFeedback={
																formik.errors.loginUsername
															}
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															onFocus={() => {
																formik.setErrors({});
															}}
														/>
													</FormGroup>
													
													<FormGroup
														id='loginPassword'
														isFloating
														label='Mot de passe'
														className='mt-2 d-flex align-items-center bg-l10-info rounded-2 pe-3'>
														
														<Input
															name='loginPassword'
															className='border-0 bg-transparent'
															type={showPassword ? 'text' : 'password'}
															autoComplete='current-password'
															value={formik.values.loginPassword}
															isTouched={formik.touched.loginPassword}
															invalidFeedback={
																formik.errors.loginPassword
															}
															validFeedback='Bon!'
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
														/>

														<Icon size={"lg"} className='cursor-pointer' icon={'RemoveRedEye'} onClick={() => setShowPassword(!showPassword)}
														></Icon>
														
													</FormGroup>
												</div>
												<div className='col-12'>
													<Button
														color='primary'
														className='w-100 py-3'
														onClick={formik.handleSubmit}
														isDisable={isLoading}>
														{isLoading && <Spinner isSmall inButton />}
														Connexion
													</Button>
													
												</div>
											</>
											
										</form>
									</CardBody>
								</Card>


						}

						
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

LoginHeader.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	isNewUser: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
};

export default Login;
