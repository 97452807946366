import { useFormik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Alert from '../bootstrap/Alert';
import Button from '../bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import PropTypes from 'prop-types';
import { searchJournal } from '../../odooApi/accountJournal';
import AuthContext from '../../contexts/authContext';
import Label from '../bootstrap/forms/Label';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import moment from 'moment';
import { payFacture } from '../../odooApi/pay';
import {
	searchFactures,
	sendInvoicePaiementByMail,
	updateFacture,
} from '../../odooApi/accountMove';
import { organizationDataFacturation } from '../../common/function/organizationDataDevis';
import FactureContext from '../../contexts/facturationContext';
import Icon from '../icon/Icon';
import { uploadAttachmentPayment } from '../../odooApi/accountPayment';
import Spinner from '../bootstrap/Spinner';
import Attachements from '../Attachements/Attachements';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import { paymentJustifyAPI } from '../../odooApi/paiementMail';
import { uploadAttachementsCommercial } from '../../odooApi/planningSlot';
import {
	paymentJustify,
	updatePaymentJustifyState,
	uploadAttachmentPaymentJustify,
} from '../../odooApi/paymentJustify';
import { useNavigate } from 'react-router-dom';
import { MailMenu } from '../../gestioneerMenu';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import ClientMontantContext from '../../contexts/clientMontantContext';
import Badge from '../bootstrap/Badge';
import { ImageIcon } from '../../common/function/functionsUtils';
import DisplayImage from '../Activity/DisplayImage';
import ErrorPopup from '../extras/ErrorPopup';

const PaymentPopup = ({
	setPaymentModalStatus,
	amountResidual,
	invoiceId,
	Invoice,
	loadPaymentData,
}) => {
	const minAmount = 0;
	const navigate = useNavigate();
	const [journals, setJournals] = useState([]);
	const { userData } = useContext(AuthContext);
	const { setFacture } = useContext(FactureContext);
	const [isLoading, setIsLoading] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const { clientMontant, setClientMontant } = useContext(ClientMontantContext);
	const fileInputRef = useRef(null);
	const [popupImage, setPopupImage] = useState(false);
	const [selectedImage, setSelectedImage] = useState(false);

	const formik = useFormik({
		initialValues: {
			amount: amountResidual,
			date: new Date(),
			typePayment: '',
			attachements: [],
			memo: ''
		},
		onSubmit: (values) => {
			setSuccess(false);
			// const payInfo = {
			// 	amount: values?.amount,
			// 	/* payment_date: moment(new Date()).startOf('month').format('YYYY-MM-D'), */
			// 	journal_id: parseInt(values?.typePayment),
			// 	invoice_ids: [invoiceId],
			// 	user_id: userData?.id,
			// };


			setIsLoading(true);
			const payInfo = {
				moyen_paiement: values?.typePayment,
				montant: values?.amount,
				client: Invoice?.partner_id?.id,
				total: Invoice?.amount_total,
				source: invoiceId,
				user_id: parseInt(userData?.odooId),
				memo: values?.memo,
				date_paiement: moment(values?.date).format("YYYY-MM-DD")
			};
			paymentJustify(
				userData,
				payInfo,

				(data) => {
					if (values?.attachements.length > 0) {
						uploadAttachmentPaymentJustify(
							userData,
							data?.id,
							values?.attachements[0],
							(result) => {
								updatePaymentJustifyState(
									userData,
									{
										id: data?.id,
										attachment: result?.data?.attachment[0],
									},
									() => {
										setIsLoading(false);
										//setSuccess('Votre paiement est enregistrer avec success.');

										loadPaymentData && loadPaymentData();
										setPaymentModalStatus(false);
									},
								);
							},
						);
					} else {
						setIsLoading(false);
						//setSuccess('Votre paiement est enregistrer avec success.');

						loadPaymentData && loadPaymentData();
						setPaymentModalStatus(false);
					}
				},
				(error) => {
					console.log('error', error);
					setSuccess(false);
					setIsLoading(false);
					setError(error);
				},
			);
			// payFacture(
			// 	userData,
			// 	payInfo,
			// 	(result) => {
			// 		uploadAttachmentPayment(
			// 			userData,
			// 			result?.id,
			// 			values?.attachements[0],
			// 			() => {
			// 				searchFactures(
			// 					userData,
			// 					[
			// 						'&',
			// 						[
			// 							'date',
			// 							'>=',
			// 							`${moment(new Date())
			// 								.startOf('month')
			// 								.format('YYYY-MM-D')} 00:00:00`,
			// 						],
			// 						'&',
			// 						[
			// 							'date',
			// 							'<',
			// 							`${moment(new Date())
			// 								.endOf('month')
			// 								.format('YYYY-MM-D')} 23:59:00`,
			// 						],
			// 						['type', '=', 'out_invoice'],
			// 					],
			// 					(dataResult) => {
			// 						setFacture(
			// 							organizationDataFacturation(
			// 								dataResult?.elements,
			// 								() => {
			// 									setPaymentModalStatus(false);
			// 								},
			// 							),
			// 						);
			// 						setIsLoading(false);
			// 					},
			// 					() => {},
			// 					null,
			// 					1,
			// 					0,
			// 				);
			// 			},
			// 			(error) => {
			// 				setIsLoading(false);
			// 				setError(error);
			// 				formik.setFieldError('attachements', `Erreur : ${error}`);
			// 			},
			// 		);
			// 	},
			// 	(error) => {
			// 		setIsLoading(false);
			// 		setError(error);
			// 		formik.setFieldError('attachements', `Erreur : ${error}`);
			// 	},
			// );

		},
	});

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	const handleFileInputChange = (e) => {
		const newAttachments = Array.from(e?.target?.files);
		formik?.setFieldValue('attachements', newAttachments);
	};
	const handelBlurAmount = (e) => {
		formik.setFieldValue(
			'amount',
			(e.target.value < minAmount && minAmount) ||
			(e.target.value > amountResidual && amountResidual) ||
			e.target.value,
		);
	};

	useEffect(() => {

		searchJournal(
			userData,
			[['type', 'in', ['cash', 'bank']]],
			(data) => {
				setJournals(data?.elements);
			},
		);

	}, [userData]);

	useEffect(() => {
		const price = (formik?.values?.amount / amountResidual) * 100;
		setClientMontant({ ...clientMontant, montant: price.toFixed(2) });
	}, [formik?.values?.amount]);

	const generateRandomString = (length) => {
		const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		let result = '';

		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * alphabet.length);
			result += alphabet.charAt(randomIndex);
		}

		return result;
	};

	const handelSend = () => {
		setSpinner(true);
		const newRandomString = generateRandomString(30);
		updateFacture(
			userData,
			{
				id: invoiceId,
				access_token: newRandomString,
			},
			() => {
				sendInvoicePaiementByMail(
					userData,
					invoiceId,
					true,
					() => {
						setSuccess('Email envoyé avec succés');
						setSpinner(false);
					},
					(error) => {
						setError(error);
						setSpinner(false);
					},
				);
			},
			(error) => {
				setError(error);
			},
		);
	};
	return (
		<Modal
			setIsOpen={() => { }}
			isOpen={true}
			isStaticBackdrop
			isScrollable
			size='md'
			classNameMd='nv-2'>
			<ModalHeader setIsOpen={setPaymentModalStatus}>
				<ModalTitle>Ajouter un paiement</ModalTitle>
			</ModalHeader>
			<ModalBody>


				<Card isCompact>
					<CardHeader>
						<CardLabel icon='Euro' iconColor='success'>
							<CardTitle>Montant</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<FormGroup className='col-12' id='amount'>
							<Input
								onChange={formik.handleChange}
								onBlur={handelBlurAmount}
								value={formik.values.amount}
								name='amount'
								type='number'
								min={minAmount}
								max={amountResidual}
								autoComplete='off'
							/>
						</FormGroup>
					</CardBody>
				</Card>
				<Card isCompact>
					<CardHeader>
						<CardLabel icon='AccountBalance' iconColor='warning'>
							<CardTitle>Paiement</CardTitle>
						</CardLabel>
						{/* {(formik?.values?.typePayment == 23 ||
							formik?.values?.typePayment == 52) && (
							<CardActions>
								<Button
									icon={!spinner && 'Payment'}
									color='warning'
									//tag='a'
									onClick={() => {
										handelSend();
									}}
									isDisable={spinner}
									// to={`../${MailMenu.paiement.path}/account.move/${invoiceId}/${Invoice?.access_token}`}
								>
									{spinner && <Spinner isSmall inButton />}
									{spinner ? 'En cours' : 'Envoyer un lien de paiement'}
								</Button>
							</CardActions>
						)} */}
					</CardHeader>
					<CardBody>
						<FormGroup className='col-12 py-2'>
							<Label>Moyen de paiement</Label>
							{!!journals?.length && (
								<Select
									ariaLabel='ariaLabel'
									placeholder={'-- aucun --'}
									onChange={formik.handleChange}
									name='typePayment'>
									{journals?.map((journal, idx) => {
										return (
											<Option
												key={idx}
												type='radio'
												name={'typePayment'}
												id={journal?.name}
												value={journal?.id}
												checked={
													journal?.id == formik?.values?.typePayment
												}>
												{journal?.name}
											</Option>
										);
									})}
								</Select>
							)}
						</FormGroup>

						<FormGroup className='col-12 py-2' id='date'>
							<Label>Date d'encaissement</Label>
							<Input
								type={'date'}
								/* onChange={handleChange} */
								value={moment(
									formik.values?.date,
								).format(moment.HTML5_FMT.DATE)}
								name='date'
								autoComplete='off'
								onChange={formik.handleChange}

							//disabled={true}
							/>
						</FormGroup>

						<FormGroup className='col-12 py-2' id='memo'>
							<Label>Mémo</Label>
							<Input
								onChange={formik.handleChange}
								value={formik?.values?.memo}
								name="memo"
								autoComplete="off"

							/>
						</FormGroup>
					</CardBody>
				</Card>
				<Card isCompact>
					<CardHeader>
						<CardLabel icon='AttachFile' iconColor='primary'>
							<CardTitle>Pièce jointe</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-2'>
							{formik?.values?.attachements?.length <= 0 ? (
								<div className='col-12'>
									<div className='py-5 flex-column d-flex justify-content-center align-items-center  rounded-1 w-100 bg-l10-dark border-dashed'>
										<Icon
											icon='CloudDownload'
											size={'2x'}
											onClick={handleButtonClick}
											className='cursor-pointer'></Icon>
										<span>Déposer vos fichiers</span>
									</div>
									<Input
										type='file'
										multiple
										name='attachments'
										onChange={handleFileInputChange}
										style={{ display: 'none' }}
										ref={fileInputRef}
									/>
								</div>
							) : (
								<>
									{!!formik?.values?.attachements?.length &&
										formik?.values?.attachements?.map((file, index) => {
											return (
												<div key={index} className='col-auto'>
													<Badge
														className='px-3 py-2 rounded-1'
														color={'dark'}
														isLight>
														<Icon
															icon={ImageIcon(file?.type)}
															size={'lg'}
															className='me-2 cursor-pointer'></Icon>
														<span
															className='fs-6 fw-semibold cursor-pointer'
															onClick={() => {
																setSelectedImage(file);
																setPopupImage(true);
															}}>
															{file?.name}
														</span>

														<Icon
															icon='Clear'
															size={'md'}
															className='ms-2 cursor-pointer'
															onClick={() => {
																console.log('sbfghsdbghbr');
																formik?.setFieldValue(
																	'attachements',
																	[],
																);
															}}></Icon>
													</Badge>
												</div>
											);
										})}
									<div className='col-auto'>
										{/* <Badge
											className='cursor-pointer px-2 py-2 rounded-1'
											color={'dark'}
											isLight
											onClick={handleButtonClick}>
											<Icon icon='Add' size={'lg'}></Icon>
										</Badge> */}
										<Input
											type='file'
											multiple
											name='attachments'
											onChange={handleFileInputChange}
											style={{ display: 'none' }}
											ref={fileInputRef}
										/>
									</div>
								</>
							)}
						</div>
						{/* <FormGroup className='col-12'>
							<Input
								type='file'
								name='attachements'
								onChange={(e) => {
									const newAttachments = Array.from(e?.target?.files);
									formik?.setFieldValue('attachements', newAttachments);
								}}
							/>

							<div className='row'>
								{formik.values.attachements?.length > 0 &&
									formik.values.attachements?.map((file, index) => {
										return (
											<Attachements
												file={file}
												index={index}
												deleteImage={() =>
													formik?.setFieldValue('attachements', [])
												}
											/>
										);
									})}
							</div>
						</FormGroup> */}
					</CardBody>
				</Card>
			</ModalBody>
			<ModalFooter>
				<Button
					color='primary'
					className='w-auto'
					icon={!isLoading && 'Save'}
					type='submit'
					isDisable={!(formik.values.amount && formik.values.typePayment) || isLoading}
					onClick={formik.handleSubmit}>
					{isLoading && <Spinner isSmall inButton />}
					{isLoading ? 'En cours' : 'Enregistrer'}
				</Button>
			</ModalFooter>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={() => {
						setSuccess(false);
						// setPaymentModalStatus(false);
					}}
				/>
			)}
			{!!popupImage && <DisplayImage setSelectedImage={setPopupImage} file={selectedImage} />}
		</Modal>
	);
};

export default PaymentPopup;
