import React, { useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Wizard, { WizardItem } from '../Wizard';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Label from '../bootstrap/forms/Label';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import Popovers from '../bootstrap/Popovers';
import { useFormik } from 'formik';
import { createRapportIntervention } from '../../odooApi/rapportIntervention';
import AuthContext from '../../contexts/authContext';
import services, {
	aFaireList,
	matieres,
	manoeuvres,
	manoeuvres_manuel,
	manoeuvres_motorise,
	coffres,
	problemeList,
	enroulements,
	lames,
} from '../../common/data/questionnaireData';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import { getReport } from '../../common/function/functionsUtils';
import { useNavigate } from 'react-router-dom';
import { planningMenu } from '../../gestioneerMenu';
import PoseContext from '../../contexts/poseContext';
import Textarea from '../bootstrap/forms/Textarea';
import ErrorPopup from '../extras/ErrorPopup';

export default function QuestionnairePopup({ setOpenPopup, handleSubmitAppel }) {
	const { userData } = useContext(AuthContext);
	const [aFaire, setAFaire] = useState(aFaireList);
	const [problemes, setProblemes] = useState(problemeList);
	const [spinner, setSpinner] = useState(false);
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [stateForm, setStateForm] = useState({});
	const navigate = useNavigate();
	const { pose, setPose } = useContext(PoseContext);
	const [note, setNote] = useState('')

	// const handleChange = (e) => {
	// 	const { value, name } = e.target;
	// 	setStateForm((oldObject) => ({ ...oldObject, [name]: value }));
	// };

	const handelSubmit = () => {
		// Code JavaScript pour convertir le HTML en chaîne


		const data =
			!!stateForm && `<div><br /><strong>Détails</strong><br />${Object.keys(stateForm)
				.map((key) => {
					if (key != 'prb' && key != 'toDo') {
						return `${stateForm[key]?.title} : ${stateForm[key]?.name}`;
					}
				})
				.join('<br />')}${!(stateForm?.prb?.length > 0) ? "" :
					`<strong>Problèmes</strong><br />${stateForm?.prb
						?.map((item) => {
							return `${item?.name}`;
						})
						.join('<br />')}`
			}${!(stateForm?.toDo?.length > 0) ? "" :
				`<br /><br /><strong>Type d'intervention</strong><br />${stateForm?.toDo
					?.map((item) => {
						return `${item?.name} - prix ${item?.price}`;
					})
					.join('<br />')}`
			}${note === "" ? '' :
				`<br /><br /><strong>Notes</strong><br />${note}`}
				</div>`;

		const newData = {
			name: '#####' || '',
			rapport: data,
		};
		setSpinner(true);
		createRapportIntervention(userData, newData, (result) => {
			// window.open(
			// 	getReport(userData?.password, result?.id, 'add.rapport.intervention'),
			// 	'_blank',
			// );
			if (!!handleSubmitAppel) {
				console.log('!!handleSubmitAppel');
				handleSubmitAppel(data, result?.id);
			} else {
				console.log('else !!handleSubmitAppel');
				setPose({
					Remarque: data || '',
					attachmentsCommercial: !!result?.id
						? [getReport(userData, result?.id, 'add.rapport.intervention')]
						: [],
				});
				console.log(pose);
				navigate('/' + planningMenu?.path);
			}

			setOpenPopup(false);
			setSpinner(false);
		});
	};

	const [aFaireChanged, setAFaireChanged] = useState(false);
	const [problemesChanged, setProblemesChanged] = useState(false);

	useEffect(() => {
		if (!!aFaireChanged) {
			stateForm.toDo = aFaire?.filter(
				(item) =>
					item?.checked == true && item?.serviceId?.includes(stateForm?.service?.id),
			);
			setAFaireChanged(false);
		}
		if (!!problemesChanged) {
			stateForm.prb = problemes?.filter(
				(item) =>
					item?.checked == true && item?.serviceId?.includes(stateForm?.service?.id),
			);
			setProblemesChanged(false);
		}
	}, [aFaire, problemes]);
	return (
		<Modal isOpen={true} setIsOpen={() => { }} size={'lg'} classNameMd='nv-3'>
			<ModalHeader setIsOpen={() => setOpenPopup(false)}></ModalHeader>
			<ModalBody>
				<Wizard
					isHeader
					color='info'
					shadow={'none'}
					onSubmit={(e) => {
						handelSubmit();
						e.preventDefault();
					}}>
					<WizardItem id='step1' title='Liste des questions' isCompact>
						<Card shadow={'sm'} isCompact>
							<CardHeader>
								<CardLabel icon='DesignServices' iconColor='warning'>
									<CardTitle> Services </CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody className='pt-0'>
								<div className='row g-4'>
									<div className='col-md-6'>
										<FormGroup>
											<ChecksGroup>
												{!!services?.length &&
													services?.map((item) => (
														<Checks
															isInline
															type='radio'
															id={'service-' + item?.id}
															label={item?.name}
															name='service'
															onChange={() =>
																setStateForm({
																	service: {
																		id: item?.id,
																		name: item?.name,
																		title: 'Service',
																	},
																})
															}
															checked={
																stateForm?.service?.id ==
																item?.id && true
															}
														/>
													))}
											</ChecksGroup>
										</FormGroup>
									</div>
								</div>
							</CardBody>
						</Card>
						{'service' in stateForm && (
							<Card isCompact>
								<CardHeader>
									<CardLabel icon='MiscellaneousServices' iconColor='warning'>
										<CardTitle>Détails</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='pt-0'>
									<div className='row g-4'>
										{!!matieres?.length &&
											!!matieres?.filter((item) =>
												item?.serviceId?.includes(stateForm?.service?.id),
											).length > 0 && (
												<div className='col-12'>
													<FormGroup id='matiere'>
														<Label>Matériau du volet roulant</Label>
														<ChecksGroup id='matiere'>
															{matieres
																?.filter((item) =>
																	item?.serviceId?.includes(
																		stateForm?.service?.id,
																	),
																)
																.map((item) => (
																	<Checks
																		isInline
																		type='radio'
																		id={'matiere-' + item?.id}
																		label={item?.name}
																		name='matiere'
																		//value={item?.name}
																		onChange={() => {
																			console.log(
																				item?.name,
																				item?.serviceId,
																			);
																			setStateForm(
																				(oldObject) => ({
																					...oldObject,
																					matiere: {
																						id: item?.id,
																						name: item?.name,
																						title: 'Matière',
																					},
																				}),
																			);
																		}}
																		checked={
																			stateForm?.matiere
																				?.id == item?.id &&
																			true
																		}
																	/>
																))}
														</ChecksGroup>
													</FormGroup>
												</div>
											)}
										{!!lames?.length &&
											!!lames?.filter((item) =>
												item?.serviceId?.includes(stateForm?.service?.id),
											).length > 0 && (
												<div className='col-md-12'>
													<Label>Type de lames</Label>
													<ChecksGroup>
														{lames
															?.filter((item) =>
																item?.serviceId?.includes(
																	stateForm?.service?.id,
																),
															)
															.map((item) => (
																<Checks
																	isInline
																	type='radio'
																	id={'lame-' + item?.id}
																	label={item?.name}
																	name='lame'
																	//value={item?.name}
																	onChange={() =>
																		setStateForm(
																			(oldObject) => ({
																				...oldObject,
																				lame: {
																					id: item?.id,
																					name: item?.name,
																					title: 'Lame',
																				},
																			}),
																		)
																	}
																	checked={
																		stateForm?.lame?.id ==
																		item?.id && true
																	}
																/>
															))}
													</ChecksGroup>
												</div>
											)}
										{!!manoeuvres?.length &&
											!!manoeuvres?.filter((item) =>
												item?.serviceId?.includes(stateForm?.service?.id),
											).length > 0 && (
												<div className='col-12'>
													<Label>Manoeuvre</Label>
													<ChecksGroup>
														{manoeuvres
															?.filter((item) =>
																item?.serviceId?.includes(
																	stateForm?.service?.id,
																),
															)
															?.map((item) => (
																<Checks
																	isInline
																	type='radio'
																	id={'manoeuvre-' + item?.id}
																	label={item?.name}
																	name='manoeuvre'
																	//value={item?.name}
																	onChange={() =>
																		setStateForm(
																			(oldObject) => ({
																				...oldObject,
																				manoeuvre: {
																					id: item?.id,
																					name: item?.name,
																					title: 'Manoeuvre',
																				},
																			}),
																		)
																	}
																	checked={
																		stateForm?.manoeuvre?.id ==
																		item?.id && true
																	}
																/>
															))}
													</ChecksGroup>
												</div>
											)}
										{!!stateForm?.manoeuvre && (
											<>
												{!!manoeuvres_manuel?.length &&
													!!manoeuvres_manuel
														?.filter((item) =>
															item?.serviceId?.includes(
																stateForm?.service?.id,
															),
														)
														.filter((item) =>
															item?.manoeuvreId?.includes(
																stateForm?.manoeuvre?.id,
															),
														).length > 0 && (
														<div className='col-md-6 d-flex g-3'>
															<Label className='me-3'>
																Manoeuvre{' '}
																{stateForm?.manoeuvre?.name} :
															</Label>
															<ChecksGroup>
																{manoeuvres_manuel
																	?.filter((item) =>
																		item?.serviceId?.includes(
																			stateForm?.service?.id,
																		),
																	)
																	.filter((item) =>
																		item?.manoeuvreId?.includes(
																			stateForm?.manoeuvre
																				?.id,
																		),
																	)
																	.map((item) => (
																		<Checks
																			isInline
																			type='radio'
																			id={
																				'manoeuvre_manuel-' +
																				item?.id
																			}
																			label={item?.name}
																			name='manoeuvre_manuel'
																			//value={item?.name}
																			onChange={() =>
																				setStateForm(
																					(
																						oldObject,
																					) => ({
																						...oldObject,
																						manoeuvre_manuel:
																						{
																							id: item?.id,
																							name: item?.name,
																							title: 'Manoeuvre Manuel',
																						},
																					}),
																				)
																			}
																			checked={
																				stateForm
																					?.manoeuvre_manuel
																					?.id ==
																				item?.id && true
																			}
																		/>
																	))}
															</ChecksGroup>
														</div>
													)}
												{!!manoeuvres_motorise?.length &&
													!!manoeuvres_motorise
														?.filter((item) =>
															item?.serviceId?.includes(
																stateForm?.service?.id,
															),
														)
														.filter((item) =>
															item?.manoeuvreId?.includes(
																stateForm?.manoeuvre?.id,
															),
														).length > 0 && (
														<div className='col-md-6 d-flex g-3'>
															<Label className='me-3'>
																Manoeuvre{' '}
																{stateForm?.manoeuvre?.name} :
															</Label>
															<ChecksGroup>
																{manoeuvres_motorise
																	?.filter((item) =>
																		item?.serviceId?.includes(
																			stateForm?.service?.id,
																		),
																	)
																	.filter((item) =>
																		item?.manoeuvreId?.includes(
																			stateForm?.manoeuvre
																				?.id,
																		),
																	)
																	.map((item) => (
																		<Checks
																			isInline
																			type='radio'
																			id={
																				'manoeuvre_motorise-' +
																				item?.id
																			}
																			label={item?.name}
																			name='manoeuvre_motorise'
																			//value={item?.name}
																			onChange={() =>
																				setStateForm(
																					(
																						oldObject,
																					) => ({
																						...oldObject,
																						manoeuvre_motorise:
																						{
																							id: item?.id,
																							name: item?.name,
																							title: 'Manoeuvre Motorisé',
																						},
																					}),
																				)
																			}
																			checked={
																				stateForm
																					?.manoeuvre_motorise
																					?.id ==
																				item?.id && true
																			}
																		/>
																	))}
															</ChecksGroup>
														</div>
													)}
											</>
										)}

										{!!coffres?.length &&
											!!coffres?.filter((item) =>
												item?.serviceId?.includes(stateForm?.service?.id),
											).length > 0 && (
												<div className='col-md-12'>
													<Label>Coffre</Label>
													<ChecksGroup>
														{coffres
															?.filter((item) =>
																item?.serviceId?.includes(
																	stateForm?.service?.id,
																),
															)
															.map((item) => (
																<Checks
																	isInline
																	type='radio'
																	id={'coffre-' + item?.id}
																	label={item?.name}
																	name='coffre'
																	//value={item?.name}
																	onChange={() =>
																		setStateForm(
																			(oldObject) => ({
																				...oldObject,
																				coffre: {
																					id: item?.id,
																					name: item?.name,
																					title: 'Coffre',
																				},
																			}),
																		)
																	}
																	checked={
																		stateForm?.coffre?.id ==
																		item?.id && true
																	}
																/>
															))}
													</ChecksGroup>
												</div>
											)}
										{!!enroulements?.length &&
											!!enroulements?.filter((item) =>
												item?.serviceId?.includes(stateForm?.service?.id),
											).length > 0 && (
												<div className='col-md-12'>
													<Label>Enroulement</Label>
													<ChecksGroup>
														{enroulements
															?.filter((item) =>
																item?.serviceId?.includes(
																	stateForm?.service?.id,
																),
															)
															.map((item) => (
																<Checks
																	isInline
																	type='radio'
																	id={'enroulement-' + item?.id}
																	label={item?.name}
																	name='enroulement'
																	//value={item?.name}
																	onChange={() =>
																		setStateForm(
																			(oldObject) => ({
																				...oldObject,
																				enroulement: {
																					id: item?.id,
																					name: item?.name,
																					title: 'Enroulement',
																				},
																			}),
																		)
																	}
																	checked={
																		stateForm?.enroulement
																			?.id == item?.id && true
																	}
																/>
															))}
													</ChecksGroup>
												</div>
											)}
										{!!problemes?.length &&
											!!problemes?.filter((item) =>
												item?.serviceId?.includes(stateForm?.service?.id),
											).length > 0 && (
												<div className='col-md-12'>
													<Label>Problème(s)</Label>
													<table>
														<thead>
															<th></th>
															<th></th>
														</thead>
														<tbody>
															<FormGroup>
																<ChecksGroup>
																	{problemes
																		?.filter((item) =>
																			item?.serviceId?.includes(
																				stateForm?.service
																					?.id,
																			),
																		)
																		?.map((item, index) => {
																			return (
																				<tr key={index}>
																					<td>
																						<Checks
																							type='checkbox'
																							id={
																								'problem-' +
																								item?.id
																							}
																							label={
																								item?.name
																							}
																							name={
																								item?.name
																							}
																							onChange={() => {
																								setProblemes(
																									(
																										prev,
																									) =>
																										prev.map(
																											(
																												i,
																											) =>
																												i.id ==
																													item.id
																													? {
																														...i,
																														checked:
																															!i.checked,
																													}
																													: i,
																										),
																								);
																								setProblemesChanged(
																									true,
																								);
																							}}
																							checked={
																								problemes?.find(
																									(
																										i,
																									) =>
																										i?.id ==
																										item?.id &&
																										i?.checked,
																								)
																								// !!item?.checked
																							}
																						/>
																					</td>
																				</tr>
																			);
																		})}
																</ChecksGroup>
															</FormGroup>
														</tbody>
													</table>
												</div>
											)}
									</div>
								</CardBody>
							</Card>
						)}
					</WizardItem>

					<WizardItem id='step2' title="Type d'intervention">
						<Card className='mb-0' isCompact>
							<CardBody className='pt-0 table-responsive'>
								<div className='row g-4'>
									<div className='col-md-12'>
										<table className='table table-modern'>
											<thead>
												<th>Description</th>
												<th>Prix</th>
											</thead>
											<tbody>
												{aFaire
													?.filter((item) =>
														item?.serviceId?.includes(
															stateForm?.service?.id,
														),
													)
													?.map((item, index) => {
														return (
															<tr key={index}>
																<td>
																	<Checks
																		type='checkbox'
																		id={+item?.id}
																		label={item?.name}
																		name={item?.name}
																		onChange={() => {
																			setAFaire((prev) =>
																				prev.map((i) =>
																					i.id == item.id
																						? {
																							...i,
																							checked:
																								!i.checked,
																						}
																						: i,
																				),
																			);
																			setAFaireChanged(true);
																		}}
																		checked={
																			aFaire?.find(
																				(i) =>
																					i?.id ==
																					item?.id &&
																					i?.checked,
																			)
																			// !!item?.checked
																		}
																	/>
																</td>
																<td>{item?.price}</td>
															</tr>
														);
													})}
											</tbody>
										</table>
									</div>
								</div>
							</CardBody>
						</Card>
					</WizardItem>
					<WizardItem id='step3' title='Sommaire'>
						<div className='row pt-5 g-4 align-items-center'>
							<div className='row text-center'>
								<div className='col-12'>
									<h2>Rapport</h2>
								</div>
							</div>
							<div className='col-12'>
								<h4 className='mt-4'>Détails</h4>
								{Object.keys(stateForm).map((key) => {
									if (key != 'prb' && key != 'toDo') {
										return (
											<div className='lead'>
												<span className='text-muted'>
													{stateForm[key]?.title} :
												</span>
												<span className='fw-bold ms-3'>
													{stateForm[key]?.name}
												</span>
											</div>
										);
									}
								})}
								{!!stateForm?.prb?.length && (
									<div className='col-12 '>
										<h4 className='mt-4'>Problèmes</h4>

										{stateForm?.prb?.map((item) => {
											return (
												<div className='lead col-12 mb-2' key={item?.id}>
													<Icon
														icon='Close'
														color='danger'
														className='me-1'
													/>
													{item?.name}
												</div>
											);
										})}
									</div>
								)}
								{!!stateForm?.toDo?.length && (
									<div className='col-12'>
										<h4 className='mt-4'>Travail à faire</h4>

										{stateForm?.toDo?.map((item) => {
											return (
												<div className='lead' key={item?.id}>
													<span>
														<Icon
															icon='Done Outline'
															color='success'
															className='me-1'
														/>
														{item?.name}
													</span>
													<span> - prix {item?.price}</span>
												</div>
											);
										})}
									</div>
								)}
								<h4 className='mt-4'>Notes</h4>
								<FormGroup>
									<Textarea
										id='note'
										name='note'
										placeholder="Notes ..."
										onChange={(e) => setNote(e?.target?.value)}
										value={note}

									/>
								</FormGroup>


							</div>
						</div>
					</WizardItem>
				</Wizard>
			</ModalBody>
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
		</Modal>
	);
}
