import { createContext, useContext, useEffect, useState } from 'react';

// Create Panier Context
const PanierContext = createContext();

// Custom hook to use the Panier Context
export const usePanier = () => {
    return useContext(PanierContext);
};

// Panier Provider Component
export const PanierProvider = ({ children }) => {
    const [paniers, setPaniers] = useState([]);
    const [activePanierId, setActivePanierId] = useState(1);
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalDue, setTotalDue] = useState(0);

    const addToPanier = (product) => {
        setPaniers((prevPaniers) => {
            const updatedPaniers = prevPaniers.map(panier => {
                if (panier.id === activePanierId) {
                    const existingProduct = panier.items.find((item) => item._id === product._id);
                    if (existingProduct) {
                        return {
                            ...panier,
                            items: panier.items.map((item) =>
                                item._id === product._id
                                    ? { ...item, panierQuantity: item.panierQuantity + 1, totalPrice: item?.totalPrice * (item.panierQuantity + 1) }
                                    : item
                            )
                        };
                    }
                    return {
                        ...panier,
                        items: [...panier.items, { ...product, panierQuantity: 1, discountPanier: product?.discount?.value || 0, totalPrice: product?.prix_vente }]
                    };
                }
                return panier;
            });
            return updatedPaniers;
        });
    };

    const addLivraison = (total) => {
        setPaniers((prevPaniers) => {
            const updatedPaniers = prevPaniers.map(panier => {
                if (panier.id === activePanierId) {
                    return {
                        ...panier,
                        livraison: {
                            ...panier?.livraison,
                            prix_vente: total,
                            totalPrice: panier?.livraison?.panierQuantity * total
                        }
                    };
                }
                return panier;
            });
            return updatedPaniers;
        });
    };


    const removeFromPanier = (productId) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? { ...panier, items: panier.items.filter((item) => item._id !== productId) }
                    : panier
            )
        );
    };

    const addQuantity = (productId) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? {
                        ...panier,
                        items: panier.items.map((item) =>
                            item._id === productId
                                ? { ...item, panierQuantity: item.panierQuantity + 1, totalPrice: item?.totalPrice * (item.panierQuantity + 1) }
                                : item
                        )
                    }
                    : panier
            )
        );
    };

    const removeQuantity = (productId) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? {
                        ...panier,
                        items: panier.items.map((item) =>
                            item._id === productId && item.panierQuantity > 1
                                ? { ...item, panierQuantity: item.panierQuantity - 1 }
                                : item
                        ).filter(item => item.panierQuantity > 0)
                    }
                    : panier
            )
        );
    };

    const DeletePanier = () => {
        setPaniers((prevPaniers) =>
            prevPaniers.filter(panier =>
                panier?.id !== activePanierId
            )
        );
        setActivePanierId(activePanierId - 1);

    };

    const DeleteAllPaniers = () => {
        setPaniers([]);
    };

    const clearPanier = () => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? {
                        ...panier,
                        items: [],
                        client: null,
                        payment: [],
                        remise: 0,
                        warehouse: null,
                        livraison: {
                            ...panier?.livraison,
                            prix_vente: 8,
                            totalPrice: 8
                        }
                    }
                    : panier
            )
        );
    };

    const addNewPanier = (sessionId, item = null) => {
        const newPanier = {
            id: paniers.length ? paniers[paniers.length - 1].id + 1 : 1,
            items: [],
            client: null,
            payment: [],
            sessionId: sessionId,
            livraison: item
        };
        setPaniers([...paniers, newPanier]);
        setActivePanierId(newPanier.id);
    };

    const switchPanier = (id) => {
        setActivePanierId(id);
    };

    const updateClient = (client) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? { ...panier, client: client }
                    : panier
            )
        );
    };

    const updatePayment = (index, field, value) => {
        setPaniers((prevPaniers) => {
            const updatedPaniers = prevPaniers.map((panier) => {
                if (panier.id === activePanierId) {
                    const payment = panier.payment[index];
                    const newPayment = { ...payment, [field]: value };
                    if (field === 'received') {
                        newPayment.returned = !!value && ((parseFloat(value)) - parseFloat(payment?.due)).toFixed(2) > 0 ? ((parseFloat(value)) - parseFloat(payment?.due)).toFixed(2) : 0;
                    }
                    return {
                        ...panier,
                        payment: panier.payment.map((p, i) => i === index ? newPayment : p)
                    };
                }
                return panier;
            });
            return updatedPaniers;
        });
    };

    const setPanierRemise = (value) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? { ...panier, remise: parseFloat(value) || 0 }
                    : panier
            )
        );
    };

    const setPanierEntrepot = (value) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? { ...panier, warehouse: value || false }
                    : panier
            )
        );
    };

    const removePayment = (index) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? {
                        ...panier,
                        payment: panier.payment.filter((_, i) => i !== index)
                    }
                    : panier
            )
        );
    };

    const addPayment = (item) => {
        setPaniers((prevPaniers) =>
            prevPaniers?.map(panier =>
                panier.id === activePanierId
                    ? { ...panier, payment: [...panier?.payment, item] }
                    : panier))
    }

    const updateQuantity = (productId, qty) => {
        console.log("qty", "totalPrice", paniers)
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? {
                        ...panier,
                        items: panier.items.map((item) =>
                            item._id === productId
                                ? { ...item, panierQuantity: qty, totalPrice: (item.prix_vente * qty) * (1 - (item?.discountPanier / 100)) }
                                : item
                        )
                    }
                    : panier
            )
        );
    };

    const updateDiscount = (productId, disc) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? {
                        ...panier,
                        items: panier.items.map((item) =>
                            item._id === productId
                                ? {
                                    ...item, discountPanier: disc, totalPrice: (item.prix_vente * item.panierQuantity) * (1 - disc / 100)
                                }
                                : item
                        )
                    }
                    : panier
            )
        );
    };

    const updatePrix = (productId, price) => {
        setPaniers((prevPaniers) =>
            prevPaniers.map(panier =>
                panier.id === activePanierId
                    ? {
                        ...panier,
                        items: panier.items.map((item) =>
                            item._id === productId
                                ? {
                                    ...item, prix_vente: price, totalPrice: (price * item.panierQuantity) * (1 - item?.discountPanier / 100)
                                }
                                : item
                        )
                    }
                    : panier
            )
        );
    };


    useEffect(() => {
        const activePanier = paniers.find(panier => panier.id === activePanierId);
        const total = activePanier ? activePanier.items.reduce((sum, item) => sum + item?.totalPrice, 0) : 0;
        setTotalTTC((total + activePanier?.livraison?.totalPrice) - (activePanier?.remise || 0));
        const totalReceived = activePanier?.payment?.reduce((sum, payment) => sum + parseFloat(payment?.received || 0), 0);
        const due = total - (activePanier?.remise || 0) - totalReceived;
        setTotalDue(due)
    }, [paniers, activePanierId]);

    return (
        <PanierContext.Provider value={{ paniers, DeleteAllPaniers, activePanierId, addLivraison, totalTTC, totalDue, addPayment, removePayment, DeletePanier, addToPanier, removeFromPanier, updateQuantity, addQuantity, removeQuantity, clearPanier, addNewPanier, switchPanier, updateClient, updatePayment, setPanierRemise, setPanierEntrepot, updateDiscount, updatePrix }}>
            {children}
        </PanierContext.Provider>
    );
};