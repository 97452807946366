const verifyAccess = (accesses,access) => {
    if(accesses?.length){
        let newAccess = accesses?.filter(item=>item?.slug==access);
        if(newAccess?.length){
            return true;
        }
    }
    return false
}

export default verifyAccess;