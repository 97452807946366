import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	REACT_APP_API_URL_FUNCTION_UTILS = process.env.REACT_APP_API_URL_FUNCTION_UTILS,
	DEVIS_API_URI = '/sale.order',
	STATISTIQUE_RELANCE_API_URI = '/relance',
	STATISTIQUE_POSE_API_URI = '/pose',
	LIMIT = 0;


const searchDevis = (
	currentUser,
	filters,
	fncSuccess,
	fnError = () => { },
	signal,
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};
	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export default searchDevis;

export const searchRelanceDevis = (currentUser, filters, fncSuccess, fnError = () => { }, signal,
	page = 1,
	limit = LIMIT) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			order: 'date_relance desc',
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getRelanceDevisByUser = (
	currentUser,
	start,
	end,
	id,
	fncSuccess,
	fnError = () => { },
) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + REACT_APP_API_URL_FUNCTION_UTILS + STATISTIQUE_RELANCE_API_URI}${'?start=' +
			start +
			'&end=' +
			end +
			'&token=' +
			currentUser?.tokenOdoo +
			'&user_id=' +
			id +
			'&responsable_intervention=' +
			id
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllRelanceDevis = (currentUser, start, end, fncSuccess, fnError = () => { }) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + REACT_APP_API_URL_FUNCTION_UTILS + STATISTIQUE_RELANCE_API_URI}${'?start=' + start + '&end=' + end + '&token=' + currentUser?.tokenOdoo
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const getRelanceDevis = (currentUser, start, end, fncSuccess, fnError = () => { }) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL +
			REACT_APP_API_URL_FUNCTION_UTILS +
			'/statistique' +
			STATISTIQUE_RELANCE_API_URI
			}${'?start=' + start + '&end=' + end + '&token=' + currentUser?.tokenOdoo}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateSingleDevis = (currentUser, devis, fnSucces, fnError = () => { }) => {
	const devisLineString = JSON.stringify(devis);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_API_URI + '/' + devis?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: devisLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const deleteSingleDevis = (currentUser, idDevis, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_API_URI + '/' + idDevis}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createDevis = (currentUser, newDevis, fnSucces, fnError = () => { }) => {
	const data = JSON.stringify(newDevis);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getSingleDevis = (currentUser, devisId, fnSucces) => {
	const config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_API_URI + '/' + devisId}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const sendDevisByMail = (currentUser, devisId, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + DEVIS_API_URI + '/' + devisId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const sendDechargeByMail = (currentUser, devisId, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + DEVIS_API_URI + '/' + devisId + '/send?decharge=true'
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const sendPaiementDevisByMail = (currentUser, devisId, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + DEVIS_API_URI + '/' + devisId + '/send?paiement=true'
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const imageSignature = (currentUser, idDevis) => {
	return (
		BASIC_API_URL +
		'/api/digital/image' +
		DEVIS_API_URI +
		'/' +
		idDevis +
		'?token=' +
		currentUser?.tokenOdoo
	);
};

export const uploadSignatureDevis = (currentUser, idDevis, image, fnc) => {
	let data = new FormData();
	data.append('signature', image.toString());
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			'/api/digital/web-admin/upload_binary' +
			DEVIS_API_URI +
			'/' +
			idDevis +
			'?token=' +
			currentUser?.tokenOdoo,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			console.log('errorerrorerror', error);
		});
};

export const getPose = (currentUser, fncSuccess, limit, page, order) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + REACT_APP_API_URL_FUNCTION_UTILS + STATISTIQUE_POSE_API_URI}${'?limit=' + limit + '&page=' + page + '&order=' + order + '&token=' + tokenUserCurent
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
		})
		.catch(function (error) {
			console.log(error);
		});
};
