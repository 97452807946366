import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { io, Socket } from 'socket.io-client';

interface Notification {
  senderId: string;
  date: string;
  isRead?: boolean;
  // Add other necessary fields for the notification
}

interface SocketContextType {
  socket: Socket | null;
  notifications: Notification[];
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const SocketProvider = ({ children }: { children: ReactNode }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [firstInteraction, setFirstInteraction] = useState<boolean>(false);

  useEffect(() => {
    const socketInstance = io('http://localhost:4000');

    socketInstance.on('connect', () => {
      console.log('Socket connected:', socketInstance.id);
    });

    socketInstance.on('getNotification', (notification: Notification) => {
      console.log('Received notification:', notification);

      setNotifications(prev => {
        const isDuplicate = prev.some(notif =>
          notif.senderId === notification.senderId && notif.date === notification.date
        );

        if (isDuplicate) {
          console.log('Duplicate notification ignored:', notification);
          return prev;
        }
        return [notification, ...prev];
      });

      // Play the notification sound if the user hasn't interacted yet
      if (!firstInteraction) {
        const audio = new Audio(require('../assets/Chocolate_Notification_Tone.mp3'));
        audio.play().catch(err => console.error('Audio play error:', err));
      }
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
  }, [firstInteraction]);

  useEffect(() => {
    const handleUserInteraction = () => {
      setFirstInteraction(true);
    };

    document.addEventListener('click', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setFirstInteraction(true);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, notifications, setNotifications }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = (): SocketContextType => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};
