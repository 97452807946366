import { useContext, useState, useEffect } from 'react';
import Icon from '../icon/Icon';
import FormGroup from '../bootstrap/forms/FormGroup';
import Textarea from '../bootstrap/forms/Textarea';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import AuthContext from '../../contexts/authContext';
import Select from '../bootstrap/forms/Select';

import {
	deleteAtelierLine,
	getmaterielsByAtelierId,
	searchSimulateur,
	updateAtelier,
	updateatelierLine,
	updateSingleMateriel,
} from '../../odooApi/atelier';
import Option from '../bootstrap/Option';
import { useFormik } from 'formik';
import AchatPopup from './AchatPopup';
import FabricationPopup from './FabricationPopup';
import MaterielPopup from './MaterielPopup';
import Button from '../bootstrap/Button';
import Spinner from '../bootstrap/Spinner';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import { getReport, getReportProduction } from '../../common/function/functionsUtils';
import { createproduction } from '../../odooApi/fabrication';
import Checks from '../bootstrap/forms/Checks';
import useSelectTable from '../../hooks/useSelectTable';
import { dataPagination } from '../PaginationButtons';
import useSortableData from '../../hooks/useSortableData';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import AtelierContext from '../../contexts/atelierContext';

export default function AtelierPopup({ setOpen, data, loadData }) {
	const [simulateur, setSimulateur] = useState([]);
	const [line, setLine] = useState([]);
	const [atelierLines, setAtelierLines] = useState([]);
	const { userData } = useContext(AuthContext);
	const [isLoadingTable, setIsLoadingTable] = useState(true);
	const [achat, setAchat] = useState(false);
	const [materiel, setMateriel] = useState(false);
	const [fabrication, setFabrication] = useState(false);
	const [type, setType] = useState('');
	const [status, setStatus] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [bonFab, setBonFab] = useState();
	const [note, setNote] = useState(data?.note);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [confirm, setConfirm] = useState(false);
	const [itemToDelete, setItemToDelete] = useState({});
	const [devis, setDevis] = useState()
	const { atelier, setAtelier } = useContext(AtelierContext);

	const onCurrentPageData = dataPagination(atelierLines, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageData);
	const TYPE = [
		{
			id: 0,
			value: 'matériel à préparer',
			onClick: () => {
				setType('matériel à préparer');
				setMateriel(true);
			},
		},
		{
			id: 1,
			value: 'order de fabrication',
			onClick: () => {
				setType('order de fabrication');
				setFabrication(true);
			},
		},
		{
			id: 2,
			value: 'commande à passer',
			onClick: () => {
				setType('commande à passer');
				setAchat(true);
			},
		},
	];

	const STATUS = [
		{
			id: 0,
			value: 'Fini',
			onClick: () => {
				setStatus('fini');

			},
		},
	];

	const stateAtelier = [
		{ id: 'non traité', state: 'non traité' },
		{ id: 'en cours', state: 'en cours' },
		{ id: 'fini', state: 'fini' },
	];

	const updateAtelierContext = (values) => {
		setAtelier((prev) => [
			...prev.map((group) => {
				if (group.state === values.state) {
					let cardIndex = group.cards.findIndex((card) => card.id === values.id);
					if (cardIndex !== -1) {
						let newCards = [...group.cards];
						newCards[cardIndex] = values;
						return {
							...group,
							cards: newCards,
						};
					} else {
						return {
							...group,
							cards: [values, ...group.cards],
						};
					}
				}

				return {
					...group,
					cards: group.cards.filter((card) => card.id !== values.id),
				};
			}),
		]);
	};

	const handleChangeType = (e, line) => {
		formik.handleChange(e);
		const etat = TYPE?.find((i) => i?.id == e.target.value);
		if (e.target.value == 2) {
			// achat
			setType(etat?.value);
			setLine([line]);
			setAchat(true);
		} else if (e.target.value == 1) {
			// ordre de fabrication
			setType(etat?.value);
			let FilterSimulateur = [
				'&',
				['devis.id', '=', line?.devis?.id],
				'&',
				['devis_line', 'in', line?.devis?.order_line],
				['produit', '=', line?.product],
			];

			searchSimulateur(userData?.password, FilterSimulateur, ({ elements }) => {
				if (elements.length > 0) {
					setSimulateur(elements);
					console.log(elements);
					//setFabrication(true);
				} else {
					setSimulateur([]);
				}
			});
			setFabrication(true);
			setLine(line);
		} else {
			// matériel a preparer
			setType(etat?.value);
			if (selectTable?.values?.selectedList?.length > 0) {
				setLine(selectTable?.values?.selectedList);
			} else {
				setLine([line]);
			}

			setMateriel(true);
		}
	};


	const handleChangeStateMateriel = (item) => {

		const newMat = {
			id: item?.id,
			state: 'fini',
		};

		updateSingleMateriel(userData?.password, newMat, (result) => {
			loadAteliersData();
		})

	};

	const handelChangeState = (state) => {
		const alltypes = atelierLines?.every((child) => child?.type_produit != '');
		const newLine = {
			id: data?.id,
			state: state,
			note: note,
		};

		alltypes
			? updateAtelier(
				userData?.password,
				newLine,
				(result) => {
					setIsLoading(false);
					loadAteliersData();
					stateAtelier?.map((line) => {
						loadData(line?.state, 5);
					});

					setOpen(false);
				},
				(error) => {
					setError(error);
					setIsLoading(false);
				},
			)
			: setError(true);
	};

	const formik = useFormik({
		initialValues: {
			type: '',
		},

		onSubmit: () => { },
	});

	const handelDelete = (item) => {
		setAtelierLines(atelierLines?.filter((item) => item?.id != line?.id));
		deleteAtelierLine(
			userData?.password,
			item?.id,
			() => {
				loadAteliersData();
			},
			(error) => {
				setError(error);
			},
		);
	};

	const loadAteliersData = () => {
		if (userData && userData?.id && userData?.password) {
			setIsLoadingTable(true);
			getmaterielsByAtelierId(
				userData?.password,
				data?.id,
				({ elements }) => {
					setAtelierLines(elements);
					formik?.setFieldValue('type');

				},
				(error) => {
					setIsLoadingTable(false);
				},
			);
			setIsLoadingTable(false);
		}
	};

	useEffect(() => {
		loadAteliersData();
	}, []);


	useEffect(() => {
		if (selectTable?.values?.selectedList?.length > 0) {
			const selectList = atelierLines?.filter((item) =>
				selectTable?.values?.selectedList.includes(item?.id.toString()),
			);
			setLine(selectList);
		}
	}, [selectTable?.values?.selectedList]);

	useEffect(() => {
		const selectList = atelierLines?.filter((item) => {

		});
	}, []);

	return (
		<>
			<Modal setIsOpen={() => { }} isOpen={true} size={'xl'} classNameMd='nv-1'>
				<ModalHeader
					setIsOpen={() => {
						setOpen(false);
					}}>
					Production {data?.state}

				</ModalHeader>
				<ModalBody className='px-2'>
					<Card shadow={'none'}>
						<CardHeader>
							<CardLabel icon='Article' iconColor='primary'>
								<CardTitle>{data?.name}</CardTitle>
								{/* <CardSubTitle>
									<Button isDisable color='secondary' isLight>
										Production {data?.state}
									</Button>
								</CardSubTitle> */}
							</CardLabel>
							<CardActions>
								<Button
									tag='a'
									icon='FileDownload'
									className='text-nowrap'
									onClick={() => {
										window.open(
											getReport(
												userData,
												data?.devis,
												'sale.order',
											),
											'_blank',
										);
									}}>
									Pièce jointe
								</Button>
							</CardActions>
							{selectTable.values.selectedList.length > 0 && (
								<CardActions>
									<Dropdown>
										<DropdownToggle>
											<Button
												className='calender-btn'
												color='primary'
												isLight
												icon={'view_agenda'}>
												{/* {etat} */} Status
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											{TYPE.map(
												(t) =>
													t.id != 1 && (
														<DropdownItem>
															<Button
																color='link'
																onClick={t.onClick}>
																{t.value}
															</Button>
														</DropdownItem>
													),
											)}
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							)}
						</CardHeader>
						<CardBody>
							{/* <div className='row g-3'> */}
							<div className='table-responsive'>
								<table className='table table-modern table-hover'>
									<thead>
										{data?.state == 'non traité' ? (
											<tr>
												<th>{SelectAllCheck}</th>
												<th>ARTICLE</th>
												<th>STATUS</th>
												<th>TYPE</th>
												<th>PDF</th>
												<th></th>
											</tr>
										) : (
											<tr>
												<th>ARTICLE</th>
												<th>TYPE</th>
												<th>STATUS</th>
												<th>PDF</th>
											</tr>
										)}
									</thead>
									{atelierLines?.length > 0 && (
										<tbody>
											{atelierLines.map((line, idx) => {
												return (
													<tr>
														{data?.state == 'non traité' && (
															<th scope='row'>
																{line.state == 'non traité' ? (
																	<Checks
																		id={line.id.toString()}
																		name='selectedList'
																		value={line?.id}
																		onChange={
																			selectTable.handleChange
																		}
																		checked={selectTable.values.selectedList.includes(
																			line.id.toString(),
																		)}
																	/>
																) : (
																	<Icon
																		icon='DoNotDisturbAlt'
																		size={'lg'}></Icon>
																)}
															</th>
														)}


														{/* <td>
															<div className='devis-desc'>
																{line?.name?.split('-')[1]}

																<div className='text-muted'>
																	<small>{line?.devis?.order_line}</small>
																</div>
															</div>
														</td> */}
														<td className='w-50'>
															<div className='devis-desc'>

																{line?.devis_line?.name ? line?.devis_line?.name : line?.name?.split('-')[1]}


															</div>
														</td>
														{/* <td>
															<span
																className={
																	!!line?.name
																		? 'devis-desc'
																		: undefined
																}>
																{line?.name}
															</span>
														</td> */}

														<td >
															{' '}
															{!selectTable.values.selectedList.includes(
																line.id.toString(),
															) && line.state == 'non traité' ? (
																<FormGroup>
																	<Select
																		ariaLabel='Board select'
																		disabled={
																			line.state == 'fini'
																		}
																		placeholder='-- aucun --'
																		onChange={(e) => {
																			handleChangeType(
																				e,
																				line,
																			);
																		}}
																		value={
																			TYPE?.find(
																				(item) =>
																					line?.type_produit ==
																					item?.value,
																			)?.id
																		}>
																		{Object.keys(TYPE).map(
																			(t) => (
																				<Option
																					key={TYPE[t].id}
																					value={
																						TYPE[t].id
																					}>
																					{
																						TYPE[t]
																							.value
																					}
																				</Option>
																			),
																		)}
																	</Select>
																</FormGroup>
															) : (
																TYPE?.find(
																	(item) =>
																		line?.type_produit ==
																		item?.value,
																)?.value
															)}
														</td>
														<td>
															{/* <Button
																color={
																	line.state == 'non traité'
																		? 'danger'
																		: line.state == 'fini'
																		? 'success'
																		: line.state == 'en cours'
																		? 'primary'
																		: line.color
																}
																isOutline>
																{line?.state}
															</Button> */}
															{line.state == 'en cours' ? (
																<FormGroup>
																	<Select
																		ariaLabel='Board select'
																		disabled={
																			line.state == 'fini'
																		}
																		placeholder={line.state}
																		onChange={() => {
																			handleChangeStateMateriel(line);
																		}}
																		value={
																			STATUS?.find(
																				(item) =>
																					line?.type_produit ==
																					item?.value,
																			)?.id
																		}>
																		{Object.keys(STATUS).map(
																			(t) => (
																				<Option
																					key={STATUS[t].id}
																					value={
																						STATUS[t].id
																					}>
																					{
																						STATUS[t]
																							.value
																					}
																				</Option>
																			),
																		)}
																	</Select>
																</FormGroup>
															) : (
																<small
																	className={`d-block border border-${line.state == 'non traité'
																		? 'danger'
																		: line.state == 'fini'
																			? 'success'
																			: line.state == 'en cours'
																				? 'primary'
																				: line.color
																		} border-2 text-${line.state == 'non traité'
																			? 'danger'
																			: line.state == 'fini'
																				? 'success'
																				: line.state == 'en cours'
																					? 'primary'
																					: line.color
																		}  px-3 py-2 fs-6 rounded-1`}>
																	{line?.state}
																</small>
															)}
														</td>

														<td>
															{line?.type_produit ==
																'order de fabrication' ? (
																<Button
																	tag='a'
																	color='info'
																	icon='Print'
																	isLight
																	className='text-nowrap'
																	href={getReportProduction(
																		userData,
																		line?.production,
																		'mrp.production',
																		'fabrication',
																	)}
																	target='_blank'>
																	{/* Ordre.fabrication */}
																</Button>
															) : (
																'Aucun'
															)}
														</td>
														{data?.state == 'non traité' && (
															<td>
																<Button
																	color='danger'
																	isLight
																	icon='Delete'
																	onClick={() => {
																		setConfirm(true);
																		setItemToDelete(line);
																	}}></Button>
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									)}
								</table>
							</div>
							{/* </div> */}
						</CardBody>
						{data?.state != 'fini' ? (
							<CardFooter>
								<CardFooterLeft>
									<FormGroup>
										<Textarea
											id='note'
											name='note'
											placeholder='Notes ...'
											value={note}
											onChange={(e) => {
												setNote(e.target.value);
											}}
											style={{ width: '425px', height: '130px' }}
										/>
									</FormGroup>
								</CardFooterLeft>
								<CardFooterRight>
									<Button
										color='primary'
										disabled={isLoading}
										onClick={() => handelChangeState('en cours')}>
										{isLoading && <Spinner isSmall inButton />}
										{isLoading ? 'En cours' : 'Sauvegarder'}
									</Button>
									{/* <Button
										color='info'
										disabled={isLoading}
										onClick={() => handelChangeState('fini')}>
										{isLoading && <Spinner isSmall inButton />}
										{isLoading ? 'En cours' : 'Traiter'}
									</Button> */}
								</CardFooterRight>
							</CardFooter>
						) : (
							<CardFooter>
								<CardFooterRight>
									<Button
										tag='a'
										color='primary'
										icon='Print'
										className='text-nowrap'
										href={getReportProduction(
											userData,
											data?.id,
											'hr.atelier',
											'enlevement',
										)}
										target='_blank'>
										Imprimer
									</Button>
									{/* <Button
										color='info'
										disabled={isLoading}
										onClick={() => handelChangeState('fini')}>
										{isLoading && <Spinner isSmall inButton />}
										{isLoading ? 'En cours' : 'Traiter'}
									</Button> */}
								</CardFooterRight>
							</CardFooter>
						)}
					</Card>
				</ModalBody>
				{!!error && (
					<StatusPopup
						icon='Cancel'
						color='danger'
						title={'Verifier les types de fabrications de chaque ligne!'}
						label={!!error?.message ? error.message : error}
						setOpen={setError}
					/>
				)}
			</Modal>
			{!!achat && (
				<AchatPopup
					setAchat={setAchat}
					simulateur={simulateur}
					line={line}
					type={type}
					loadAteliersData={loadAteliersData}
				/>
			)}
			{!!fabrication && (
				<FabricationPopup
					setFabrication={setFabrication}
					simulateur={simulateur}
					line={line}
					type={type}
					setBonFab={setBonFab}
					loadAteliersData={loadAteliersData}
				/>
			)}

			{!!materiel && (
				<MaterielPopup
					setMateriel={setMateriel}
					line={line}
					type={type}
					loadAteliersData={loadAteliersData}
				/>
			)}
			{!!confirm && (
				<ConfirmationPopup
					setOpen={setConfirm}
					label={`Supprimer l'article ${itemToDelete?.name?.split('-')[1]} ?`}
					handleConfirm={() => handelDelete(itemToDelete)}
				/>
			)}
		</>
	);
}
