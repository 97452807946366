import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	ACCOUNT_TAX_API_URI = '/account.tax',
	LIMIT = 0;
export const searchAccountTax = (
	currentUser,
	filters,
	fnSuccess,
	fnError = () => { },
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACCOUNT_TAX_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
			// fnSuccess({
			// 	"records": {
			// 		"total": 1,
			// 		"current_page": 1,
			// 		"limit": 0,
			// 		"all_pages": 0,
			// 		"exist": false,
			// 		"first_element": 0
			// 	},
			// 	"elements":
			// 		[
			// 			{
			// 				"id": 5,
			// 				"name": "19,0%",
			// 				"amount": 19.0
			// 			},
			// 			{
			// 				"id": 637,
			// 				"name": "19,0%",
			// 				"amount": 19.0
			// 			}
			// 		]
			// 	// [
			// 	// 	{
			// 	// 		"id": 637,
			// 	// 		"name": "19%",
			// 	// 		"amount": 19
			// 	// 	}]
			// });
		})
		.catch(function (error) {
			fnError(error);
		});
};
