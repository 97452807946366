import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine, NavigationTitle } from '../Navigation/Navigation';
import User from '../User/User';
import { componentsMenu, dashboardMenu } from '../../menu';

import ThemeContext from '../../contexts/themeContext';
import Card, { CardBody } from '../../components/bootstrap/Card';

import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import AuthContext from '../../contexts/authContext';
import { modules } from '../../gestioneerMenu';
import { useMediaQuery } from 'react-responsive'
import { getSingleCompanyId, searchCompanies } from '../../odooApi/Company';
import Badge from '../../components/bootstrap/Badge';
import CompanieContext from '../../contexts/companieContext';
import Select from '../../components/bootstrap/forms/Select';
import Option from '../../components/bootstrap/Option';
import { getAllParentModules } from '../../apis/backend/modules';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [ modulesMenu, setModulesMenu ] = useState([]);
	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' })
	const isDesktop = !isMobile

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	const { userData } = useContext(AuthContext);
	const role = userData?.role;

	const [societes, setSocietes] = useState([])
	const [selectedName, setSelectedName] = useState("");
	const { companie, setCompanie } = useContext(CompanieContext);



	const onToggle = React.useCallback(() => {
		setAsideStatus(prev => !prev)
	}, [setAsideStatus])

	const updateCompanieContext = (values) => {
		setCompanie({
			...values
		});
	};

	useEffect(() => {
		searchCompanies(
			userData?.password,
			[['id', 'in', userData?.company_ids]],
			({ elements }) => {
				setSocietes(elements);
				const userCompany = elements.find((company) => company.id == userData?.company_id);
				updateCompanieContext(userCompany)
			},
			() => { },
			null,
			1,
			0
		);
	}, [userData?.company_ids]);

	const handleChange = (e) => {
		const selectedCompanyId = e.target.value;
		setSelectedName(selectedCompanyId);
		const userCompany = societes.find((company) => company.id == selectedCompanyId);
		updateCompanieContext(userCompany)
	};


	useEffect(()=>{
		if(userData?.accessToken&&userData?.role=="employe"){
			getAllParentModules(
				userData?.accessToken,
				(modules)=>{
					setModulesMenu(modules);
				}
			)
		}
	},[]);
	
	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: isDesktop || (isMobile && asideStatus) },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>

					<Brand hideToggle={isDesktop} onToggle={onToggle} />
					{!!societes?.length&&role?.toLowerCase() != 'technicien' && role?.toLowerCase() != 'achat' &&
						<Select
							id='company'
							size='md'
							ariaLabel='company'
							value={selectedName}
							onChange={handleChange}
							className={'bg-l10-info rounded-1'}

						>
							{societes?.map((societe) => (
								<Option key={societe?.id} value={societe?.id}>
									{societe?.name}
								</Option>
							))}
						</Select>
					}
				</div>
				<div className='aside-body'>
					{
						!!modulesMenu?.length&&
						modulesMenu.map(module=>{
							if(module?.subModules?.length){
								return(
									<>
									<NavigationLine />
									<ul className='navigation'>
										<NavigationTitle>
											{module.name}
										</NavigationTitle>
										{
											module.subModules?.map(item=>{
												return(
													<Navigation
														key={item.id}
														menu={[item?.id, {id:item?.id,text:item?.name,path:item?.route?.path}]}
														//menu={{`${item?.id}`:{id:"ddddddddc",text:"test",path:"cdcdcd"}}}
														id='aside-demo-pages'
													/>
												)
											})	
										}
									</ul>
									</>
	
								)
							}
							
						})
					}

					{!doc && (
						<>
							{Object.entries(modules).map(([moduleId, moduleItems]) => {
								const hasModulePermission = !!moduleItems.permissions?.includes(
									userData?.role?.toLocaleLowerCase(),
								);

								if (hasModulePermission) {
									return (
										<>
											<NavigationLine />
											<ul className='navigation'>
												<NavigationTitle>
													{moduleItems.text}
												</NavigationTitle>
												{Object.entries(moduleItems).map(
													([submoduleId, submodule]) => {
														
														/* const hasSubmodulePermission =
															!!submodule?.permissions?.includes(
																userData?.role?.toLocaleLowerCase(),
															); */

														if (/* hasSubmodulePermission */true) {
															return (
																<Navigation
																	key={submodule.id}
																	menu={[submoduleId, submodule]}
																	id='aside-demo-pages'
																/>
															);
														}

														//return null; // User doesn't have permission for this submodule
													},
												)}
											</ul>
										</>
									);
								}

								return null; // User doesn't have permission for this module
							})}

							

							
						</>
					)}

					{/* {doc && (
						<>
							<NavigationLine />
							<Navigation menu={componentsMenu} id='aside-menu-two' />
							<NavigationLine />
						</>
					)}

					{asideStatus && doc && (
						<Card className='m-3 '>
							<CardBody className='pt-0'>
								<img
									srcSet={HandWebp}
									src={Hand}
									alt='Hand'
									width={130}
									height={130}
								/>
								<p
									className={classNames('h4', {
										'text-dark': !darkModeStatus,
										'text-light': darkModeStatus,
									})}>
									{t('Everything is ready!')}
								</p>
								<Button
									color='info'
									isLight
									className='w-100'
									onClick={() => setDoc(false)}>
									{t('Demo Pages')}
								</Button>
							</CardBody>
						</Card>
					)} */}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								{/* <span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : undefined}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
									<span className='navigation-link-extra'>
										<Icon
											icon='Circle'
											className={classNames(
												'navigation-notification',
												'text-success',
												'animate__animated animate__heartBeat animate__infinite animate__slower',
											)}
										/>
									</span>
								</span> */}
							</div>
						</div>
					</nav>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
