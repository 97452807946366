import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	ACHAT_LINE_API_URI = '/purchase.order.line';
export const getAchatLinesById = (currentUser, achatId, fnSucces, fnErr) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['order_id', '=', achatId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACHAT_LINE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			fnErr(error);
		});
};

export const updateAchatLine = async (currentUser, line, fnSucces, fnErr) => {
	const datas = JSON.stringify(line);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACHAT_LINE_API_URI + '/' + line?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: datas,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnErr(error);
		});
};

export const deleteAchatLine = async (currentUser, idLine, fnSucces, fnErr) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACHAT_LINE_API_URI + '/' + idLine}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnErr(error);
		});
};

export const createAchatLine = async (currentUser, newLine, fnSucces, fnErr) => {
	var data = JSON.stringify(newLine);

	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACHAT_LINE_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnErr(error);
		});
};
