const axios = require("axios");
const BACK_API_URL = "/back",
  AUTH_URI = "/auth/login";

export const login = (data, fnSuccess, fnError=()=>{}) => {

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BACK_API_URL+AUTH_URI,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  axios
    .request(config)
    .then((response) => {
      fnSuccess(response.data);
    })
    .catch((error) => {
      console.log(error);
      fnError(error);
    });
};
