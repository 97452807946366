import { useTour } from '@reactour/tour';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Appel from '../../../components/Achats/Appel';
import ActivityUpdatePopup from '../../../components/Activity/ActivityUpdatePopup';
import TasksNotifications from '../../../components/Activity/TasksNotifications';
import ChatHeaderIcon from '../../../components/Chats/ChatHeaderIcon';
import NotificationHeaderIcon from '../../../components/Notifications/NotificationHeaderIcon';
import PlanifierPlanning from '../../../components/PlanningCalendar/PlanifierPlanning';
import QuestionnairePopup from '../../../components/Simulateur/QuestionnairePopup';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import AuthContext from '../../../contexts/authContext';
import MissedCallsContext from '../../../contexts/missedCallsContext';
import PoseContext from '../../../contexts/poseContext';
import ThemeContext from '../../../contexts/themeContext';
import { AppelsMenu } from '../../../gestioneerMenu';
import useDarkMode from '../../../hooks/useDarkMode';
import { getLangWithKey } from '../../../lang';
import { HeaderRight } from '../../../layout/Header/Header';
import { getMissedCalls } from '../../../reminderApi/Consumptions';

const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { userData } = useContext(AuthContext);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [openPlanning, setOpenPlanning] = useState(false);
	const [questionnaire, setQuestionnaire] = useState(false);
	const [tasks, setTasks] = useState(false);
	const [openAppel, setOpenAppel] = useState(false);
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	const [card, setCard] = useState();
	const [editModalStatus, setEditModalStatus] = useState(false);
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			'You updated the language of the site. (Only "Aside" was prepared as an example.)',
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});
	const { pose, setPose } = useContext(PoseContext);

	const { setIsOpen } = useTour();
	const [missedCalls, setmissedCalls] = useState(0);
	const [lateTasks, setLateTasks] = useState(0);
	const [createtModalStatus, setCreatetModalStatus] = useState(false);
	useEffect(() => {
		const croneMissedCalls = () => {
			userData?.tokenJava &&
				getMissedCalls(userData?.tokenJava, (result) => {
					setmissedCalls(result);
				});
			// userData?.password &&
			// 	searchActivities(
			// 		userData?.password,
			// 		[
			// 			'&',

			// 			['x_end_date', '<', moment().format('YYYY-MM-DD HH:mm:ss')],
			// 			'&',
			// 			['x_state', '!=', 'done'],
			// 			['x_users_ids', 'in', [userData?.id]],
			// 		],
			// 		(resultData) => {
			// 			setLateTasks(resultData?.elements);
			// 		},
			// 		(error) => {},
			// 		0,
			// 		0,
			// 	);
		};
		croneMissedCalls();
		setInterval(croneMissedCalls, 60000);
	}, []);

	// useEffect(() => {
	// 	if (userData?.role?.toLowerCase() == 'gestionnaire') {
	// 		if ('Notification' in window) {
	// 			Notification.requestPermission().then(function (permission) {
	// 				if (permission === 'granted') {
	// 					if (missedCalls > 0 && userData?.role?.toLowerCase() == 'gestionnaire') {
	// 						var notification = new Notification('Appels manqués', {
	// 							body: `${missedCalls} appels manqués`,
	// 							icon: 'https://cdn-icons-png.flaticon.com/512/3582/3582216.png',
	// 						});
	// 						notification.onclick = () => {
	// 							notification.close();
	// 							window.parent.focus();
	// 							handleOnClickToCallHistoryPage();
	// 						};
	// 					}
	// 				} else if (permission === 'default') {
	// 					requestAndShowPermission();
	// 				}
	// 			});
	// 		}
	// 	}
	// }, [missedCalls]);
	const navigate = useNavigate();
	const { setMissedCalls } = useContext(MissedCallsContext);

	const handleOnClickToCallHistoryPage = useCallback(() => {
		setMissedCalls(true);
		navigate(`../${AppelsMenu.path}`);
	}, [navigate]);
	return (
		<HeaderRight>
			<div className='row g-3 '>
				{beforeChildren}
				{/* Tour Modal */}

				{(userData?.role?.toLowerCase() == 'gestionnaire' ||
					userData?.role?.toLowerCase().includes('admin')) && (
					<>
						<div className='col-auto mt-4'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								style={{
									color: darkModeStatus ? 'dark' : 'light',
									hoverShadow: 'default',
									isLight: !darkModeStatus,
								}}
								{...styledBtn}
								className='btn-only-icon'
								isLight
								icon={'ContactSupport'}
								color='info'
								onClick={() => setQuestionnaire(true)}
							/>
						</div>
						{!!questionnaire && <QuestionnairePopup setOpenPopup={setQuestionnaire} />}
						<div className='col-auto mt-4'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								style={{
									color: darkModeStatus ? 'dark' : 'light',
									hoverShadow: 'default',
									isLight: !darkModeStatus,
								}}
								{...styledBtn}
								isLight
								icon={'Today'}
								color='primary'
								onClick={() => setOpenPlanning(true)}></Button>
							{openPlanning && <PlanifierPlanning setOpenPopup={setOpenPlanning} />}
						</div>
						<div className='col-auto mt-4'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								style={{
									color: darkModeStatus ? 'dark' : 'light',
									hoverShadow: 'default',
									isLight: !darkModeStatus,
								}}
								isLight
								icon={'call'}
								color='success'
								onClick={() => setOpenAppel(true)}></Button>
						</div>
						{!!openAppel && <Appel setOpenAppel={setOpenAppel} />}
						<div className='col-auto mt-4'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading

								{...styledBtn}
								isLight
								icon={'PhoneMissed'}
								color='danger'
								onClick={handleOnClickToCallHistoryPage}
								className='btn-only-icon'></Button>
							{/* <Button
							rounded={'circle'}
							color='danger'
							size={'sm'}
							className={classNames(
								'position-absolute',
								'top-10',
								'start-70',
								// 'translate-middle',
								'animate__animated animate__heartBeat animate__infinite animate__slower',
							)}>
							{missedCalls}
						</Button> */}
							<div
								className={classNames(
									'position-relative',
									'animate__animated animate__infinite animate__slower',
								)}
								style={{ top: '-44px', right: '-28px' }}>
								<Badge rounded={'circle'} color='danger'>
									{missedCalls}
								</Badge>
							</div>

							{/* <Button
							icon={'PhoneMissed'}
							color={'danger'}
							isLight
							onClick={handleOnClickToCallHistoryPage}
							>
							{/* <span
								className={classNames(
									'position-absolute',
									' start-90',
									'translate-middle',
									'badge',
									'rounded-pill',
									'bg-danger',
									'border border-2',
									{
										'border-white': !darkModeStatus,
										'border-dark': darkModeStatus,
									},
								)}>
								2/3
							</span> */}
							{/* {`${missedCalls} appels manqués`} */}
							{/* // </Button>  */}
						</div>
						// <ChatHeaderIcon />
						<div className='col-auto mt-4'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								style={{
									color: darkModeStatus ? 'dark' : 'light',
									hoverShadow: 'default',
									isLight: !darkModeStatus,
								}}
								isLight
								icon={'TaskAlt'}
								color='primary'
								onClick={() => setOffcanvasStatus(true)}></Button>
						</div>
					</>
				)}

				{/* <div className='col-auto mt-4'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>

				</div> */}
				<NotificationHeaderIcon />
				{/* {(userData?.role?.toLowerCase() == 'gestionnaire' ||
					userData?.role?.toLowerCase().includes('admin')) && (
						<>
							<div className='col-auto position-relative mt-4'>
								<Button
									{...styledBtn}
									icon='Notifications'
									onClick={() => setOffcanvasStatus(true)}
									aria-label='Notifications'
								/>

								{lateTasks?.length > 0 && (
									<Icon
										icon='Circle'
										className={classNames(
											'position-absolute start-65',
											'text-danger',
											'animate__animated animate__heartBeat animate__infinite animate__slower',
										)}
									/>
								)}
							</div>
						</>

					)} */}
				{/* 
				<div className='col d-flex align-items-center cursor-pointer'>
					<div className='me-3'>
						<div className='text-end'>
							<div className='fw-bold fs-6 mb-0'>
								{`${userData?.name} ${userData?.surname}`}
							</div>
							<div className='text-muted'>
								<small>{userData?.position}</small>
							</div>
						</div>
					</div>
					<div className='position-relative'>
						<Avatar
							srcSet={userData?.srcSet}
							src={userData?.src}
							size={48}
							color={userData?.color}
							style={{ objectFit: 'cover', objectPosition: 'top' }}
						/>

						<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
							<span className='visually-hidden'>Online user</span>
						</span>
					</div>
				</div> */}

				{/*	Full Screen */}
				{/* <div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div> */}

				{/* Lang Selector */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
							'undefined' ? (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									className='btn-only-icon'
									aria-label='Change language'
									data-tour='lang-selector'>
									<Spinner isSmall inButton='onlyIcon' isGrow />
								</Button>
							) : (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={
										getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
									}
									aria-label='Change language'
									data-tour='lang-selector'
								/>
							)}
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{/* Quick Panel */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button {...styledBtn} icon='Tune' aria-label='Quick menu' />
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
							<div className='row g-0'>
								<div
									className={classNames(
										'col-12',
										'p-4',
										'd-flex justify-content-center',
										'fw-bold fs-5',
										'text-info',
										'border-bottom border-info',
										{
											'bg-l25-info': !darkModeStatus,
											'bg-lo25-info': darkModeStatus,
										},
									)}>
									Quick Panel
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Public' size='3x' color='info' />
										<span>Dealers</span>
										<small className='text-muted'>Options</small>
									</div>
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-bottom',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Upcoming' size='3x' color='success' />
										<span>Inbox</span>
										<small className='text-muted'>Configuration</small>
									</div>
								</div>
								<div
									className={classNames(
										'col-6 p-4 transition-base cursor-pointer bg-light-hover',
										'border-end',
										{ 'border-dark': darkModeStatus },
									)}>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='Print' size='3x' color='danger' />
										<span>Print</span>
										<small className='text-muted'>Settings</small>
									</div>
								</div>
								<div className='col-6 p-4 transition-base cursor-pointer bg-light-hover'>
									<div className='d-flex flex-column align-items-center justify-content-center'>
										<Icon icon='ElectricalServices' size='3x' color='warning' />
										<span>Power</span>
										<small className='text-muted'>Mode</small>
									</div>
								</div>
							</div>
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{afterChildren}
			</div>
			{offcanvasStatus && <TasksNotifications setOffcanvasStatus={setOffcanvasStatus} />}

			{editModalStatus && (
				<ActivityUpdatePopup card={card} setEditModalStatus={setEditModalStatus} />
			)}
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
