import React, { useState } from 'react';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import PropTypes from 'prop-types';
import Icon from '../../icon/Icon';
import useOutsideClick from './useOutsideClick';
import ListGroup, { ListGroupItem } from '../../bootstrap/ListGroup';
import Spinner from '../../bootstrap/Spinner';
import PopUpSeachDetails from '../../Devis/PopUpSeachDetails';
import SearchPopUp from '../../PlanningCalendar/SearchPopUp';

const SearchBox = ({
	value,
	name,
	onChange,
	label,
	options,
	loading = false,
	handelSelectItem,
	title,
	client,
	origin,
	setfilterResult,
	setSearchItem,
	invoice,
	devis,
	atelier,
	achat,
	nomenclature
}) => {
	const [openListItems, setOpenListItems] = useState(false);
	const handelChange = (e) => {
		onChange(e);
		setOpenListItems(true);
	};
	const handelSelect = (item) => {
		handelSelectItem(item);
		setOpenListItems(false);
	};

	return (
		<div
			className='position-relative autocomplete d-flex align-items-center w-100 bg-l25-light rounded-2 px-2'>
			<label className='border-0  me-0' htmlFor='searchInput'>
				<Icon icon='Search' size='2x' color='primary' />
			</label>

			<Input
				placeholder={label}
				onChange={handelChange}
				value={value}
				name={name}
				type='search'
				autoComplete='off'
				className='border-0 bg-transparent w-100'
			/>

			{openListItems &&
				(loading ? (
					<div
						id='myInputautocomplete-list'
						className='autocomplete-items col-12 position-absolute top-100'>
						<div className='body'>
							<div>
								<Spinner isSmall isGrow /> <Spinner isSmall isGrow />{' '}
								<Spinner isSmall isGrow />
							</div>
						</div>
					</div>
				) : (
					!!options?.length && (
						<PopUpSeachDetails filterResult={options} invoice={invoice} devis={devis} atelier={atelier} achat={achat}
							nomenclature={nomenclature} setOpenListItems={setOpenListItems} setfilterResult={setfilterResult} setSearchItem={setSearchItem} />

					)
				))}
		</div>
	);
};

export default SearchBox;

{
	/* <div className='col-auto d-flex align-items-center'>
					<label
						className='border-0 bg-transparent cursor-pointer me-0'
						htmlFor='searchInput'>
						<Icon icon='Search' size='2x' color='primary' />
					</label>

					<Input
						id='searchInput'
						className='border-0 shadow-none bg-transparent w-auto'
						placeholder='Rechercher client...'
						value={client}
						onChange={handleChange}
						autoComplete='off'
					/>
				</div> */
}
// <div  className={className}>

{
	/* <Icon color={'primary'} icon={'Email'} size='lg' />
											<span className='me-2'>
												{item?.email ||
													item?.partner_id?.email ||
													'--aucun--'}
											</span>
											<Icon color={'primary'} icon={'Place'} size='lg' />
											<span>
												{item?.street + ' ' + item?.zip + ' ' + item?.city}
											</span> */
}
