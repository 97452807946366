import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	FACTURATIONS_API_URI = '/account.move',
	LIMIT = 20;

export const getSingleFacture = (currentUser, invoiceId, fnSucces) => {
	const config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FACTURATIONS_API_URI + '/' + invoiceId}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

const getAllFacturations = (currentUser, limit, fncSuccess) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FACTURATIONS_API_URI}${limit ? '?limit=' + limit : ''
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data.elements || []);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export default getAllFacturations;

export const searchFactures = (
	currentUser,
	filters,
	fnSuccess,
	fnError = () => { },
	signal,
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
			// order: 'date asc',
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FACTURATIONS_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};
	console.log('search invoice', config);
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchFacturesByState = (
	currentUser,
	start,
	end,
	state,
	pay,
	fnSuccess,
	fnError = () => { },
	signal,
	page = 1,
	limit = LIMIT,
) => {
	const FilterFactures = [
		'&',
		['date', '>=', start],
		'&',
		['date', '<=', end],
		'&',
		['type', '=', 'out_invoice'],
		'&',
		['state', '=', state],
		['invoice_payment_state', '=', pay],
	];
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: FilterFactures,
			limit: limit,
			page: page,
			// order: 'date asc',
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FACTURATIONS_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createFacture = (currentUser, newFacture, fnSuccess, fnError = () => { }) => {
	var data = JSON.stringify(newFacture);

	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FACTURATIONS_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateFacture = async (currentUser, devis, fnSucces, fnError = () => { }) => {
	const devisLineString = JSON.stringify(devis);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FACTURATIONS_API_URI + '/' + devis?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
		data: devisLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const deleteFacture = async (currentUser, idFacture, fnSucces) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FACTURATIONS_API_URI + '/' + idFacture}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const sendInvoiceByMail = (currentUser, invoiceId, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + FACTURATIONS_API_URI + '/' + invoiceId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const sendInvoiceRelanceByMail = (currentUser, invoiceId, relance, fnSucces) => {
	const config = {
		method: 'get',
		url: `${'/odoo' +
			API_URL_WEB_ADMIN +
			FACTURATIONS_API_URI +
			'/' +
			invoiceId +
			'/send?relance=' +
			relance
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const sendInvoicePaiementByMail = (currentUser, invoiceId, paiement, fnSucces) => {
	const config = {
		method: 'get',
		url: `${'/odoo' +
			API_URL_WEB_ADMIN +
			FACTURATIONS_API_URI +
			'/' +
			invoiceId +
			'/send?paiement=' +
			paiement
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
		});
};



export const uploadAttachementsInvoice = (currentUser, idInvoice, attachements, fnc, fnErr) => {
	let data = new FormData();
	//data.append('image_1920', file);
	Object.keys(attachements)?.map((key) => {
		data.append('attachments', attachements[key]);
	});
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			FACTURATIONS_API_URI +
			'/' +
			idInvoice +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnErr(error);
		});
};