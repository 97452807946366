import { useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Wizard, { WizardItem } from '../../Wizard';
import { createRapportIntervention } from '../../../odooApi/rapportIntervention';
import AuthContext from '../../../contexts/authContext';
import SignatureCanvas from 'react-signature-canvas';

import StatusPopup from '../../PlanningCalendar/StatusPopup';
import { convertURL, getReport } from '../../../common/function/functionsUtils';
import { useNavigate } from 'react-router-dom';
import { planningMenu } from '../../../gestioneerMenu';
import PoseContext from '../../../contexts/poseContext';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Input from '../../bootstrap/forms/Input';
import { getSingleDevis } from '../../../odooApi/devis';
import Textarea from '../../bootstrap/forms/Textarea';
import Label from '../../bootstrap/forms/Label';
import Button from '../../bootstrap/Button';
import moment from 'moment';
import {
	createPVReception,
	sendPVReceptionByMail,
	uploadAttachementsTechnicien,
	uploadSignaturePV,
} from '../../../odooApi/planningSlot';
import { useFormik } from 'formik';
import Spinner from '../../bootstrap/Spinner';
import ErrorPopup from '../../extras/ErrorPopup';

export default function PvReceptionPopup({ setOpen, planning, setAttachmentsRaport }) {
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false);
	const [sigPad, setSigPad] = useState({});
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [devisdata, setDevisData] = useState();
	const [stateForm, setStateForm] = useState({});
	const navigate = useNavigate();
	const { pose, setPose } = useContext(PoseContext);

	const declaration = [
		{
			id: 0,
			name: 'La réception est prononcée sans réserve.',
		},
		{
			id: 1,
			name: "La réception est prononcée avec des réserves, telles qu'indiquées dans l'état ci-dessous.",
		},
		{
			id: 2,
			name: 'La réception est refusée ou différée pour les motifs suivants',
		},
	];

	const handleClearSignature = () => {
		sigPad?.clear();
		setDisabledBtn(true);
	};
	const handelCancel = () => {
		setOpen(false);
	};

	// const handleChange = (e) => {
	// 	const { value, name } = e.target;
	// 	setStateForm((oldObject) => ({ ...oldObject, [name]: value }));
	// };

	const [aFaireChanged, setAFaireChanged] = useState(false);
	const [problemesChanged, setProblemesChanged] = useState(false);

	const handelCreatePV = (values) => {
		setSpinner(true);
		const newpv = {
			name: 'pv devis ' + devisdata?.name + ' intervention ' + planning.name_seq,
			planning: values?.planning,
			devis: devisdata?.id,
			delai: values?.delai,
			date: values?.date,
			rapport: stateForm?.declaration?.name,
			refuse: values?.refuse,
		};

		createPVReception(
			userData?.password,
			newpv,
			(data) => {
				uploadSignaturePV(
					userData?.password,
					data?.id,
					sigPad?.getTrimmedCanvas().toDataURL('image/png').split(',')[1],
					() => {
						sendPVReceptionByMail(
							userData?.password,
							data?.id,
							() => {
								convertURL(
									getReport(userData, data?.id, 'pv.reception'),
									'PV_reception_client',
									(file) => {
										uploadAttachementsTechnicien(
											userData?.password,
											planning?.id,

											[file],

											(result) => {
												const newUploadedAtttachements = [
													...planning.attachment_report,
													{ id: result?.data?.attachment[0], file: file },
												];
												setAttachmentsRaport(newUploadedAtttachements);
												planning.attachment_report = newUploadedAtttachements;
												setSpinner(false);
												setOpen(false);
											},
											(error) => {
												setSpinner(false);
												setError(error);
											},
										);
									},
									(error) => {
										setSpinner(false);
										setError(error);
									},
								);

							})

						// setAttachmentsRaport([getReport(userData?.password, data?.id, 'pv.reception')]);
						// setSuccess('Modifications réussies');
						// setOpen(false);
					},
					(error) => {
						setSpinner(false);
						setError(error);
					},
				);



			},
			(error) => {
				setSpinner(false);
				setError(error);
			},
		);
	};

	const formik = useFormik({
		initialValues: {
			name: '',
			planning: planning?.id,
			devis: devisdata?.id,
			delai: '',
			date: moment(new Date()).format('YYYY-MM-DD'),
			signature: '',
			declaration: '',
			refuse: '',
		},
		onSubmit: (values) => {
			handelCreatePV(values);
		},
	});

	useEffect(() => {
		if (planning?.devis_liste.length > 0) {
			{
				planning?.devis_liste?.map((item) => {
					getSingleDevis(
						userData?.password,
						item,
						(data) => {
							setDevisData(data);
						},
						(error) => {
							console.log('erreur', error);
						},
					);
				});
			}
		}
	}, []);
	return (
		<Modal isOpen={true} setIsOpen={() => { }} size={'lg'} classNameMd='nv-2'>
			<ModalHeader className='px-4 pt-3' setIsOpen={() => setOpen(false)}>
				<div className='w-100 text-muted text-center '>
					<ModalTitle id='project-edit' className='fw-semibold fs-4'>
						PROCÈS-VERBAL DE RÉCEPTION
					</ModalTitle>
				</div>
			</ModalHeader>
			<ModalBody className='px-4'>
				<div className='d-flex mb-4'>
					<span className=' me-1'>Date :</span>
					{moment().format('DD/MM/YYYY HH:mm')}
				</div>
				<div className='mb-4'>
					<span style={{ lineHeight: 2 }}>
						Je soussigné(e), Madame/Monsieur {planning?.partner_id?.name}, agissant en
						qualité de maître d’ouvrage, déclare avoir effectué la visite des travaux
						exécutés par l’entreprise Store 2000, conformément au marché référencé sous
						le devis n° <strong>{devisdata?.name}</strong> daté du{' '}
						{moment(devisdata?.date_order).format('DD/MM/YYYY HH:mm')}, portant sur des
						travaux à l'adresse suivante : {planning?.partner_id?.street}{' '}
						{planning?.partner_id?.city} {planning?.partner_id?.zip}.
					</span>
				</div>

				<div className='mb-4'>
					<span>
						La visite s'est déroulée en présence de Monsieur/Messieurs{' '}
						{planning?.employees_ids?.map(
							(item, index) =>
								item?.name +
								`${index + 1 < planning?.employees_ids?.length ? ', ' : ''}`,
						)}
						, représentant(s) de l'entreprise.
					</span>
				</div>
				<div className='mb-2'>
					<span>
						Je déclare que :
						<small className='text-muted'>(cocher la mention utile)</small>
					</span>
				</div>
				<ChecksGroup className='mb-4'>
					{!!declaration?.length &&
						declaration?.map((item) => (
							<div className='mb-2'>
								<Checks
									type='radio'
									id={'declaration-' + item?.id}
									label={item?.name}
									name='declaration'
									onChange={() =>
										setStateForm({
											declaration: {
												id: item?.id,
												name: item?.name,
											},
										})
									}
									checked={stateForm?.declaration?.id == item?.id && true}
								/>
							</div>
						))}
				</ChecksGroup>
				{stateForm?.declaration?.id == 1 && (
					<div>
						<Label>
							L’entreprise lèvera ces réserves dans un délai de : (inscrire le délai
							ainsi négocié)
						</Label>
						<Input
							name='delai'
							value={formik?.values?.delai}
							onChange={formik?.handleChange}
						/>
					</div>
				)}

				{stateForm?.declaration?.id == 2 && (
					<div>
						<Textarea
							name='refuse'
							value={formik?.values?.refuse}
							onChange={formik?.handleChange}
						/>
					</div>
				)}

				<div className='d-flex justify-content-between my-4 align-items-center px-2'>
					<div className='col-auto fw-semibold fs-5'>Signature</div>
					<div className='col-auto'>
						<Button
							color='primary'
							className='w-auto'
							isLight
							onClick={handleClearSignature}>
							Effacer la signature
						</Button>
					</div>
				</div>
				<Card shadow={'md'}>
					<div style={{ height: '250px' }}>
						<SignatureCanvas
							penColor='black'
							canvasProps={{
								style: { width: '100%', height: 250 },
								className: 'sigCanvas',
							}}
							backgroundColor='white'
							ref={(ref) => {
								setSigPad(ref);
							}}
							onBegin={() => {
								setDisabledBtn(false);
							}}
						/>
					</div>
				</Card>

				{/* <Card borderColor={'info'} borderSize={1} shadow={'none'} className='p-2'>
					<CardBody>
						<div className='row d-flex justify-content-center'>
							<div className='col-12'>
								<Label>Signature Client</Label>
								<SignatureCanvas
									penColor='black'
									canvasProps={{
										style: { width: '100%' },
										className: 'sigCanvas',
									}}
									backgroundColor='white'
									ref={(ref) => {
										setSigPad(ref);
									}}
									onBegin={() => {
										setDisabledBtn(false);
									}}
								/>
							</div>
						</div>
					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<Button
								color='warning'
								className='w-auto'
								//type='submit'
								onClick={handleClearSignature}>
								Effacer la signature
							</Button>
						</CardFooterRight>
					</CardFooter>
				</Card> */}
			</ModalBody>
			<ModalFooter>
				<CardFooterRight>
					<Button
						color='primary'
						className='w-auto'
						//type='submit'
						onClick={formik.handleSubmit}
						isDisable={spinner}>
						{spinner && <Spinner isSmall inButton />}
						{spinner ? 'En cours' : 'Sauvegarder'}
					</Button>
					<Button
						color='danger'
						className='w-auto'
						//type='submit'
						onClick={handelCancel}>
						Annuler
					</Button>
				</CardFooterRight>
			</ModalFooter>
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
		</Modal>
	);
}
