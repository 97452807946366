import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/authContext";
import PoseContext from "../../contexts/poseContext";
import { useSocket } from "../../contexts/StockSocketContext";
import useDarkMode from "../../hooks/useDarkMode";
import ActivityUpdatePopup from "../Activity/ActivityUpdatePopup";
import Badge from "../bootstrap/Badge";
import Button from "../bootstrap/Button";
import OffcetNotifications from "./OffcetNotification";

const NotificationHeaderIcon = () => {
    const { userData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [offcanvasStatus, setOffcanvasStatus] = useState(false);
    const [offcanvasStatusNotif, setOffcanvasStatusNotif] = useState(false);
    const [lateTasks, setLateTasks] = useState(0);
    const { pose, setPose } = useContext(PoseContext);
    const [editModalStatus, setEditModalStatus] = useState(false);
    const { newMessage } = useSocket();


    useEffect(() => {
        // if (userData && userData?.id && userData?.password) {

        //     firestore
        //         .collection('notifications')
        //         .where("memberNotVu", "array-contains", userData?.id)
        //         .limit(10)
        //         .onSnapshot((snapshot) => {
        //             const notification = [];
        //             snapshot.docs.filter((doc) => {
        //                 if (!doc.data().memberVu?.includes(userData?.id)) {
        //                     notification.push({ ...doc.data(), id: doc.id })
        //                 }
        //             })
        //             setUnreadNotifications(notification);
        //         });
        // }

        setUnreadNotifications([newMessage, ...unreadNotifications])

    }, [newMessage]);

    return (
        <>
            <div className='col-auto mt-4'>
                <Button

                    size={"lg"}
                    hoverShadow={"default"}
                    isLight
                    icon={'Notifications'}
                    color='primary'
                    onClick={() => { setOffcanvasStatus(true) }}
                    className='btn-only-icon'

                ></Button>
                {/* <Button
                    //color= {darkModeStatus ? 'dark' : 'light'}
                    hoverShadow={'default'}
                    //isLight= {!darkModeStatus}
                    size='lg'
                    icon='Notifications'
                    onClick={() => setOffcanvasStatus(true)}
                    aria-label='Notifications'
                /> */}

                <div
                    className={classNames(
                        'position-relative',
                        'animate__animated animate__infinite animate__slower',
                    )}
                    style={{ top: '-44px', right: '-28px' }}>
                    <Badge rounded={'circle'} color='danger'>
                        {!!unreadNotifications?.length ? (unreadNotifications?.length <= 9 ? unreadNotifications?.length : "+9") : !!Object.keys(pose).length && "1"}
                    </Badge>
                </div>

            </div>

            {
                offcanvasStatus &&
                <OffcetNotifications
                    setOffcanvasStatus={setOffcanvasStatus}
                    offcanvasStatus={offcanvasStatus}
                    unreadNotifications={unreadNotifications}
                    pose={pose}
                />

            }
            {/* <OffCanvas
                id='notificationCanvas'
                titleId='offcanvasExampleLabel'
                placement='end'
                isOpen={offcanvasStatus}
                setOpen={setOffcanvasStatus}>
                <OffCanvasHeader setOpen={setOffcanvasStatus}>
                    <OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    {!!Object.keys(pose).length && (
                        <Alert
                            className='flex-nowrap cursor-pointer'
                            icon='ViewInAr'
                            size={'3x'}
                            isLight
                            color='primary'
                            onClick={() => {
                                navigate('/' + planningMenu?.path);
                                setOffcanvasStatus(false);
                            }}>
                            <AlertHeading tag='h2' className='h4'>
                                {pose?.type_int} {pose?.motif}
                                {!(pose?.type_int || pose?.motif) && 'Intervention'}
                            </AlertHeading>
                            <span className='mb-6'>
                                {!!pose?.client && (
                                    <div className=' col-12'>
                                        <div className='d-flex align-items-center'>

                                            <div className='flex-grow-1 '>
                                                <span className='text-muted'>Client :</span>{' '}
                                                <span className='fw-bold fs-6 mb-0'>
                                                    {pose['client']}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!!pose?.type_int && (
                                    <div className=' col-12'>
                                        <div className='d-flex align-items-center'>

                                            <div className='flex-grow-1 '>
                                                <span className='text-muted'>
                                                    Type d'intervention :{' '}
                                                </span>
                                                <span className='fw-bold fs-6 mb-0'>
                                                    {pose?.type_int}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!!pose?.devis?.length && (
                                    <div className=' col-12'>
                                        <div className='d-flex align-items-center'>

                                            <div className='flex-grow-1 '>
                                                <span className='text-muted'>N° devis : </span>
                                                <span className='fw-bold fs-6 mb-0'>
                                                    {pose['devis']}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!!pose?.Remarque && (
                                    <div className='col-12'>
                                        <div className='d-flex '>

                                            <div className='flex-grow-1 '>
                                                <span className='text-muted'>Remarque : </span>
                                                <span className=' fs-6 mb-0'>
                                                    {React.createElement('div', {
                                                        dangerouslySetInnerHTML: {
                                                            __html: (pose?.Remarque || '').replace(
                                                                /\n/g,
                                                                '<br />',
                                                            ),
                                                        },
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!!pose?.clientData && !!Object.keys(pose?.clientData).length && (
                                    <>
                                        {!!pose?.clientData?.name && (
                                            <div className=' col-12'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-grow-1 '>
                                                        <span className='text-muted'>
                                                            Client :{' '}
                                                        </span>
                                                        <span className='fw-bold fs-6 mb-0'>
                                                            {pose?.clientData?.name}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!!pose?.clientData?.email && (
                                            <div className=' col-12'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-grow-1 '>
                                                        <span className='text-muted'>
                                                            E-mail :{' '}
                                                        </span>
                                                        <span className='fw-bold fs-6 mb-0'>
                                                            {pose?.clientData?.email}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!!pose?.clientData?.mobile && (
                                            <div className=' col-12'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-grow-1 '>
                                                        <span className='text-muted'>
                                                            Tél client :{' '}
                                                        </span>
                                                        <span className='fw-bold fs-6 mb-0'>
                                                            {pose?.clientData?.mobile}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {(!!pose?.clientData?.street ||
                                            !!pose?.clientData?.zip ||
                                            !!pose?.clientData?.city ||
                                            !!pose?.clientData?.street2) && (
                                                <div className=' col-12'>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='flex-grow-1 '>
                                                            <span className='text-muted'>
                                                                Adresse client :{' '}
                                                            </span>
                                                            <span className='fw-bold fs-6 mb-0'>
                                                                {pose?.clientData?.street +
                                                                    ' ' +
                                                                    pose?.clientData?.zip +
                                                                    ' ' +
                                                                    pose?.clientData?.city}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                    </>
                                )}
                            </span>
                            <div className='col-12 d-flex justify-content-start mt-2'>
                                {' '}
                                <Button
                                    style={{
                                        color: darkModeStatus ? 'dark' : 'light',
                                        hoverShadow: 'default',
                                        isLight: !darkModeStatus,
                                    }}
                                    className='w-auto'
                                    icon={'cancel'}
                                    color='primary'
                                    onClick={() => {
                                        setPose({});
                                    }}>
                                    Annuler
                                </Button>
                            </div>
                        </Alert>
                    )}




                </OffCanvasBody>
            </OffCanvas> */}

            {editModalStatus && (
                <ActivityUpdatePopup card={card} setEditModalStatus={setEditModalStatus} />
            )}
        </>


    )
};

export default NotificationHeaderIcon