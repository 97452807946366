import axios from 'axios';

export const baseUrl = 'http://localhost:3007'; // Adjust base URL as per your backend

const axiosInstance = axios.create({
    baseURL: `${baseUrl}`, // Adjust API path if your backend routes are prefixed with '/api'
    timeout: 10000, // Adjust timeout as needed
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getRequest = async (url) => {
    try {
        const response = await axiosInstance.get(url);
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
};

export const postRequest = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data);
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
};

export const createChat = async (firstId, secondId, fnSuccess) => {
    try {
        const response = await postRequest(`/chats`, {
            firstId,
            secondId,
        });

        if (response.error) {
            console.error('Error creating a chat:', response);
            return;
        }
        fnSuccess(response)
    } catch (error) {
        console.error('Error creating chat:', error);
    }
};

export const getUserChats = async (user, fnSuccess) => {
    try {
        const response = await getRequest(`/chats/${user.id}`);
        fnSuccess(response)
    } catch (error) {
        console.error('Error fetching user chats:', error);
    }
};
/*export const fetchChat = async (firstId, secondId) => {
    try {
      const response = await axios.get(`/chats/find/${firstId}/${secondId}`);
      return response.data;
    } catch (error) {
      console.error("Error finding chat:", error);
      return null;
    }
  };*/
  
export const getMessage = async (currentChat, fnSuccess) => {
    try {
        console.log("currentChat:", currentChat);
        console.log("currentChat._id:", currentChat?._id);

        // Fetch messages from the API
        const response = await getRequest(`/messages/${currentChat?._id}`);
        fnSuccess(response); // Process the fetched messages

    } catch (error) {
        console.error('Error fetching messages:', error);
    }
};
export const fetchChatFromAPI = async (firstId, secondId) => {
    try {
      const response = await getRequest(`/chats/find/${firstId}/${secondId}`);
      const textResponse = await response;
      
      console.log('Full response:', textResponse); // Log the full response
  
     // const data = JSON.parse(textResponse);
     // console.log('Parsed JSON data:', data);
      return textResponse;
    } catch (error) {
      console.error('Error fetching chat:', error);
      throw error;
    }
  }
  


export const sendMessage = async (textMessage, sender, currentChatId, fnSuccess) => {
    if (!textMessage) {
        console.log('You must type something');
        return;
    }

    try {
        const response = await postRequest(`/messages`, {
            chatId: currentChatId,
            senderId: sender,
            text: textMessage,
        });
        fnSuccess(response)

        if (response.error) {
            return;
        }

    } catch (error) {
        console.error('Error sending message:', error);
    }
};

export const updateCurrentChat = chat => {
    // Ensure this function is correctly implemented
    setCurrentChat(chat);
};
