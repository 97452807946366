import { searchCategory } from '../../../../../../../odooApi/categoryProdut';
import { getSingleProduct, searchProduct } from '../../../../../../../odooApi/product';
import prix_VRCMotorise from '../../../../../CalculSimulateurData.js/VRCompletMotorise';
import formInputsTypes from '../../../../simulatorConstant';
import {
	CATEG_ID_AXE,
	CATEG_ID_TANDEM,
	PRODUCT_ID_AXE_MOTORISE_TRADI,
	PRODUCT_ID_COUSSINET,
	PRODUCT_ID_EQUERRE,
	PRODUCT_ID_JAMBE,
} from '../../axe';
import { CATEG_ID_MOTEURS, CATEG_ID_SUPPORT } from '../../axe/motorise';

export const PRODUCT_TEMPL_ID_TAB = 24;
export const PRODUCT_VOLET_COMPLET = 6204;
export const CATEG_TELECOMMANDE = 186;

import Becker from '../../../../../../../assets/img/stock/becker.jpg';
import a_ok from '../../../../../../../assets/img/stock/A-OK.png';
import Somfy from '../../../../../../../assets/img/stock/Somfy.png';
import Bubendorff from '../../../../../../../assets/img/stock/Bubendorff.png';
import Eckermann from '../../../../../../../assets/img/stock/Eckermann.png';
import Gaposa from '../../../../../../../assets/img/stock/Gaposa.png';


//type moteur
import IO from '../../../../../../../assets/img/stock/IO.png';
import RTS from '../../../../../../../assets/img/stock/RTS.png';
import Radio from '../../../../../../../assets/img/stock/Radio.png';
import Filaire from '../../../../../../../assets/img/stock/Filaire.png';
import Solaire from '../../../../../../../assets/img/stock/Solaire.png';

import avec_telecommande from '../../../../../../../assets/img/stock/telecommandes.png';
import sans_telecommande from '../../../../../../../assets/img/stock/sans_tele.png';

import droite from '../../../../../../../assets/img/stock/droite.png';
import gauche from '../../../../../../../assets/img/stock/gauche.png';

const Tandem = {
	id: 'tandem',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Tandem',
	name: 'tandem',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		console.log('currentFormValue', currentFormValue);
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_VRCMotorise[0]?.id) {
			price = prix_VRCMotorise[0]?.price;
		} else if (m2 > prix_VRCMotorise?.length?.id) {
			price = prix_VRCMotorise[prix_VRCMotorise?.length].price;
		} else {
			for (var i = 0; i < prix_VRCMotorise.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_VRCMotorise[i]?.id && m2 <= prix_VRCMotorise[i + 1]?.id) {
					price = prix_VRCMotorise[i + 1]?.price;
				}
			}
		}
		const generateFilter = () => {
			const filters = [
				// "&",
				['categ_id', '=', CATEG_ID_TANDEM],
				// ["diametre", "=", currentFormValue?.axe?.diametre]
			];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), ({ elements }) => {
			getSingleProduct(PRODUCT_ID_EQUERRE, tokenUserCurent, (dataEquerre) => {
				getSingleProduct(PRODUCT_ID_JAMBE, tokenUserCurent, (dataJampe) => {
					getSingleProduct(PRODUCT_ID_COUSSINET, tokenUserCurent, (dataCoussinet) => {
						const generateDescription = (item) => {
							return (
								`${currentFormValue?.product?.value} \n Volet roulant fabriqué en aluminium de haute qualité injecté avec précision pour garantir une solidité exceptionnelle et résistance aux intempéries \n Ce volet roulant motorisé est équipé d'un moteur silencieux et fiable qui permet de l'ouvrir et de le fermer en un seul geste. \n \n` +
								`\n- Couleur: ${currentFormValue?.lameColor?.label} ` +
								`\n- Coulisses: ${currentFormValue?.coulisse?.label || ''} ` +
								`\n- Tablier: ${currentFormValue?.lames?.label} ` +
								`\n- Axe: ${currentFormValue?.axe?.label}` +
								`\n- Moteur: ${currentFormValue?.motors?.label}` +
								`\n${!!currentFormValue?.telecommande?.label
									? '- Télécommande: ' + currentFormValue?.telecommande?.label
									: ''
								}` +
								`\n- Accessoires ` +
								`\n- Dimensions L: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
								` x H: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
								`\n${currentFormValue?.isWithAgraffe?.value == 'oui'
									? '- Verrou: ' +
									(Math.ceil(currentFormValue?.largeur / 1000) + 1) +
									' verrou OCTOCLICK H890C'
									: ''
								} ` +
								`\nConforme à la norme NF EN 13241-1 `
							);
						};

						const options = elements?.length
							? elements.map((item) => ({
								id: item?.id,
								label: item?.display_name,
								product_id: PRODUCT_VOLET_COMPLET,
								description: generateDescription(item),
								value: item?.id,
								production: [
									currentFormValue?.lames?.id,
									currentFormValue?.coulisse?.id,
									currentFormValue?.axe?.id,
									currentFormValue?.motors?.id,
									currentFormValue?.supportMotors?.id,
									item?.id,
								],
								largeur: currentFormValue?.largeur,
								hauteur: currentFormValue?.hauteur,
								verrou: [
									currentFormValue?.agraffe?.id || 0,
									Math.ceil(currentFormValue?.largeur / 1000) + 1,
								],
								lst_price: price,
								// lst_price: parseFloat(
								// 	(
								// 		item?.lst_price +
								// 		(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
								// 			(currentFormValue?.agraffe?.lst_price || 0) +
								// 		currentFormValue?.lames?.lst_price +
								// 		currentFormValue?.lameFinal?.lst_price +
								// 		currentFormValue?.coulisse?.lst_price +
								// 		(currentFormValue?.axe?.lst_price *
								// 			currentFormValue?.largeur) /
								// 			1000 +
								// 		currentFormValue?.motors?.lst_price +
								// 		(currentFormValue?.telecommande?.lst_price || 0) +
								// 		currentFormValue?.supportMotors?.lst_price +
								// 		dataEquerre?.lst_price +
								// 		dataCoussinet?.lst_price +
								// 		dataJampe?.lst_price
								// 	).toFixed(2),
								// ),
							}))
							: [];
						fnSuccess(options);
					});
				});
			});
		});
	},
};

const supportMotors = {
	id: 'VR-TR-AXE-SM-API',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sélectionner un support moteur',
	name: 'supportMotors',
	className: 'd-none',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_VRCMotorise[0]?.id) {
			price = prix_VRCMotorise[0]?.price;
		} else if (m2 > prix_VRCMotorise?.length?.id) {
			price = prix_VRCMotorise[prix_VRCMotorise?.length].price;
		} else {
			for (var i = 0; i < prix_VRCMotorise.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_VRCMotorise[i]?.id && m2 <= prix_VRCMotorise[i + 1]?.id) {
					price = prix_VRCMotorise[i + 1]?.price;
				}
			}
		}
		searchProduct(
			tokenUserCurent,
			[
				'&',
				['categ_id', '=', CATEG_ID_SUPPORT],
				['brand_id', '=', currentFormValue?.Moteur?.value],
			],
			async ({ elements }) => {
				const generateDescription = () => {
					return (
						`${currentFormValue?.product?.value} \n Volet roulant fabriqué en aluminium de haute qualité injecté avec précision pour garantir une solidité exceptionnelle et résistance aux intempéries \n Ce volet roulant motorisé est équipé d'un moteur silencieux et fiable qui permet de l'ouvrir et de le fermer en un seul geste. \n \n` +
						`\n- Couleur: ${currentFormValue?.lameColor?.label} ` +
						`\n- ${currentFormValue?.coulisse?.label || ''} ` +
						`\n- Tablier: ${currentFormValue?.lames?.label} ` +
						`\n- Axe: ${currentFormValue?.axe?.label}` +
						`\n- Moteur: ${currentFormValue?.Moteur?.id} ${currentFormValue?.typeMotor?.id} ${currentFormValue?.puissance?.value}` +
						`\n- Sortie de câble ${currentFormValue?.sortie_cable?.value}` +
						`\n${!!currentFormValue?.telecommande?.value
							? '- ' + currentFormValue?.telecommande?.value
							: ''
						}` +
						`\n- Accessoires ` +
						`\n- Dimensions L: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
						` x H: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
						`\n${currentFormValue?.isWithAgraffe?.value == 'oui'
							? '- ' +
							(Math.ceil(currentFormValue?.largeur / 1000) + 1) +
							' verrous'
							: ''
						} ` +
						`\nConforme à la norme NF EN 13241-1 `
					);
				};

				const options = elements?.length
					? elements.map((item) => ({
						id: item?.id,
						label: item?.display_name,

						product_id: PRODUCT_VOLET_COMPLET,
						description: generateDescription(),
						value: item?.id,
						production: [
							currentFormValue?.lames?.id,
							currentFormValue?.coulisse?.id,
							currentFormValue?.axe?.id,
							//currentFormValue?.motors?.id,
							item?.id,
						],
						largeur: currentFormValue?.largeur,
						hauteur: currentFormValue?.hauteur,
						verrou: [
							currentFormValue?.agraffe?.id || 0,
							Math.ceil(currentFormValue?.largeur / 1000) + 1,
						],
						lst_price: price,
						checked: true
						// lst_price: parseFloat(
						// 	(
						// 		item?.lst_price +
						// 		(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
						// 			(currentFormValue?.agraffe?.lst_price || 0) +
						// 		currentFormValue?.lames?.lst_price +
						// 		currentFormValue?.lameFinal?.lst_price +
						// 		currentFormValue?.coulisse?.lst_price +
						// 		(currentFormValue?.axe?.lst_price *
						// 			currentFormValue?.largeur) /
						// 			1000 +
						// 		currentFormValue?.motors?.lst_price +
						// 		(currentFormValue?.telecommande?.lst_price || 0) +
						// 		currentFormValue?.supportMotors?.lst_price +
						// 		dataEquerre?.lst_price +
						// 		dataCoussinet?.lst_price +
						// 		dataJampe?.lst_price
						// 	).toFixed(2),
						// ),
					}))
					: [];
				fnSuccess(options);
				// const options = elements?.length
				// 	? elements.map((item) => ({
				// 			id: item?.id,
				// 			label: item?.display_name,
				// 			product_id: PRODUCT_ID_AXE_MOTORISE_TRADI,

				// 			value: item?.id,
				// 			lst_price: item?.lst_price,
				// 	  }))
				// 	: [];
				// fnSuccess(options);
			},
		);
	},
};
const telecommandeRadio = {
	id: 'VR-TR-AXE-M-API',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner une télécommande',
	name: 'telecommande',
	nextStepData: supportMotors,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		searchCategory(
			tokenUserCurent,
			[['parent_id', '=', CATEG_TELECOMMANDE]],
			({ elements }) => {
				const filterData = elements.filter(
					(i) => i.name == currentFormValue?.motors?.brand_id?.name,
				);
				searchProduct(
					tokenUserCurent,
					[['categ_id', '=', filterData[0]?.id]],
					async (e) => {
						const options = e?.elements?.length
							? e?.elements?.map((item) => ({
								id: item?.id,
								label: item?.display_name,
								value: item?.id,
								lst_price: item?.lst_price,
							}))
							: [];
						fnSuccess(options);
					},
				);
			},
		);
	},
};

/*
const motorsRadio = {
	id:"VR-TR-AXE-M-API",
	type:formInputsTypes?.SELECT_INPUT,
	label:"Sélectionner un moteur",
	name:"motors",
	nextStepData:telecommandeRadio,
	nextStep:false,
	options:null,
	fetchData: (tokenUserCurent,currentFormValue,fnSuccess) => {
		const generateFilter = (idCat) => {
			const weight = (currentFormValue?.lameFinal?.weight*((currentFormValue?.largeur*(currentFormValue?.lameTaille=="BP43R"?41:55))/1000))+
				(currentFormValue?.lame?.weight*((currentFormValue?.largeur*currentFormValue?.currentFormValue?.hauteur)/1000));
			const filters = [
				"&",
				["categ_id","=",idCat],
				//"&",
				["tag_ids","=",parseInt(currentFormValue?.typeMotor?.value)],
				["weight",">",parseFloat(weight.toFixed(2))]
			]
			return(
				filters
			)
		}
	    
		searchCategory(
			tokenUserCurent,
			["&",["parent_id","=",currentFormValue?.marqueMoteur?.id],["name","=","Moteur"]],
			(results)=>{
				searchProduct(
					tokenUserCurent,
					generateFilter(results[0].id),
					(motorsData)=>{
						const options = motorsData?.length? motorsData.map((item=>({
							id:item?.id,
							label:item?.display_name,
							value:item?.id,
							lst_price:item?.lst_price
						   
						}))):
						[];
						fnSuccess(options);
					}
				)
			    
			}
		)
	}
} */

const sortie_cable = {
	id: 'MD',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sortie de câble( vue en face)',
	name: 'sortie_cable',
	className: 'col-6',
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [

		{
			id: 'gauche',
			value: 'gauche',
			label: '',
			imageUrl: gauche,
			nextStep: supportMotors,
		},
		{
			id: 'droite',
			value: 'droite',
			label: '',
			imageUrl: droite,
			nextStep: supportMotors,
		}
	],
};


const mural = {
	id: 'MD',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Avec / sans bouton mural',
	name: 'telecommande',
	className: 'col-6',
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'telecommande_non',
			value: 'Sans bouton mural',
			label: '',
			imageUrl: sans_telecommande,
			nextStep: sortie_cable,
		},
		{
			id: 'telecommande_oui',
			value: 'Avec bouton mural',
			label: '',
			imageUrl: avec_telecommande,
			nextStep: sortie_cable,
		},
	],
};

const telecommande = {
	id: 'MD',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Avec / sans télécommande',
	name: 'telecommande',
	className: 'col-6',
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'telecommande_non',
			value: 'Sans télécommande',
			label: '',
			imageUrl: sans_telecommande,
			nextStep: sortie_cable,
		},
		{
			id: 'telecommande_oui',
			value: 'Avec télécommande',
			label: '',
			imageUrl: avec_telecommande,
			nextStep: sortie_cable,
		},
	],
};


const motorsradio = {
	id: 'VR-TR-AXE-MI',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner un moteur',
	name: 'motors',
	nextStepData: telecommande,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = ['&', ['categ_id.name', 'like', currentFormValue?.Moteur?.value + ' ' + currentFormValue?.typeMotor?.label], ['tag_ids', '=', currentFormValue?.typeMotor?.value]];

			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(currentFormValue?.marqueMoteur?.id),
			({ elements }) => {
				const options = elements?.length
					? elements.map((item) => {
						const { product_template_attribute_value_ids } = item;
						let name = item?.name + ' ';
						product_template_attribute_value_ids.map(
							(i) => (name += '( ' + i.name + ' )'),
						);
						return {
							id: item?.id,
							label: name,
							value: item?.id,
							lst_price: item?.lst_price,
							brand_id: item?.brand_id,
						};
					})
					: // "'const options = elements?.length
					// 	? elements.map((item) => ({
					// 			id: item?.id,
					// 			label: item?.display_name,
					// 			value: item?.id,
					// 			lst_price: item?.lst_price,
					// 			brand_id: item?.brand_id,
					// 	  }))'"
					[];
				fnSuccess(options);
			},
		);
	},
};

const motors = {
	id: 'VR-TR-AXE-M-API',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Sélectionner un moteur',
	name: 'motors',
	nextStepData: mural,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = ['&', ['categ_id.name', 'like', currentFormValue?.Moteur?.value + ' ' + currentFormValue?.typeMotor?.label], ['tag_ids', '=', currentFormValue?.typeMotor?.value]];

			return filters;
		};

		searchProduct(
			tokenUserCurent,
			generateFilter(currentFormValue?.marqueMoteur?.id),
			({ elements }) => {
				const options = elements?.length
					? elements.map((item) => {
						const { product_template_attribute_value_ids } = item;
						let name = item?.name + ' ';
						product_template_attribute_value_ids.map(
							(i) => (name += '( ' + i.name + ' )'),
						);
						return {
							id: item?.id,
							label: name,
							value: item?.id,
							lst_price: item?.lst_price,
							brand_id: item?.brand_id,
						};
					})
					: // "'const options = elements?.length
					// 	? elements.map((item) => ({
					// 			id: item?.id,
					// 			label: item?.display_name,
					// 			value: item?.id,
					// 			lst_price: item?.lst_price,
					// 			brand_id: item?.brand_id,
					// 	  }))'"
					[];
				fnSuccess(options);
			},
		);
	},
};

/* const typeMoteur = {
	id:"VR-TR-AXE-MM-TM",
	type:formInputsTypes?.RADIO_INPUT,
	label:"Sélectionner un type de moteur",
	name:"typeMotor",
	nextStepData:motors,
	nextStep:false,
	options:[
		{
			id:"VR-TR-AXE-MM-TM-FL",
			label:"Filaire",
			value:"1",
			nextStep:motors
		},
		{
			id:"VR-TR-AXE-MM-R",
			label:"Radio",
			value:"2",
			nextStep:motorsRadio
		},
		{
			id:"VR-TR-AXE-MM-TM-S",
			label:"Solaire",
			value:"3",
			nextStep:motors
		}
	],
}

const typeMoteurBubendorff = {
	id:"VR-TR-AXE-MM-TM",
	type:formInputsTypes?.RADIO_INPUT,
	label:"Sélectionner un type de moteur",
	name:"typeMotor",
	nextStepData:motors,
	nextStep:false,
	options:[
		{
			id:"VR-TR-AXE-MM-TM-FL",
			label:"Filaire",
			value:"1",
			nextStep:motors
		},
		{
			id:"VR-TR-AXE-MM-R",
			label:"Radio",
			value:"2",
			nextStep:motors
		},
		{
			id:"VR-TR-AXE-MM-TM-S",
			label:"Solaire",
			value:"3",
			nextStep:motors
		}
	],
} */

const marqueMoteur = {
	id: 'VR-TR-AXE-MM-API',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sélectionner la marque de moteur',
	name: 'marqueMoteur',
	nextStepData: motors,
	className: 'd-none',
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = ['&', ['parent_id', '=', CATEG_ID_MOTEURS], ['name', 'like', currentFormValue?.Moteur?.value + ' ' + currentFormValue?.typeMotor?.label]];

			return filters;
		};
		searchCategory(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const options = elements?.length
				? elements?.map((item) => ({
					id: item?.id,
					label: item?.name,
					value: item?.id,
					checked: true,
					/* nextStep:item?.name=="Bubendorff"?typeMoteurBubendorff:typeMoteur */
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const puissanceMoteur = {
	id: "puissance",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner la puissance de moteur",
	name: "puissance",
	nextStepData: telecommande,
	nextStep: false,
	options: [
		{
			id: "6",
			label: "6 Nm",
			value: "6 Nm",
		},
		{
			id: "9",
			label: "9 Nm",
			value: "9 Nm",
		},
		{
			id: "12",
			label: "12 Nm",
			value: "12 Nm",
		},
		{
			id: "20",
			label: "20 Nm",
			value: "20 Nm",
		},
		{
			id: "30",
			label: "30 Nm",
			value: "30 Nm",
		},
		{
			id: "40",
			label: "40 Nm",
			value: "40 Nm",
		},
		{
			id: "50",
			label: "50 Nm",
			value: "50 Nm",
		},

	],
}

const typeMoteurRadioFilaire = {
	id: "VR-TR-AXE-MM-TM",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner un type de moteur",
	name: "typeMotor",
	nextStepData: false,
	classGroup: "SProduit",
	nextStep: false,
	options: [
		{
			id: "Filaire",
			label: "",
			imageUrl: Filaire,
			value: 1,
			nextStep: puissanceMoteur
		},
		{
			id: "Radio",
			label: "",
			imageUrl: Radio,
			value: 2,
			nextStep: puissanceMoteur
		},

	],
}
const typeMoteurRadioFilaireSolaire = {
	id: "VR-TR-AXE-MM-TM",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner un type de moteur",
	name: "typeMotor",
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: "Filaire",
			label: "",
			imageUrl: Filaire,
			value: 1,
			nextStep: puissanceMoteur
		},
		{
			id: "Radio",
			label: "",
			imageUrl: Radio,
			value: 2,
			nextStep: puissanceMoteur
		},
		{
			id: "Solaire",
			label: "",
			imageUrl: Solaire,
			value: 3,
			nextStep: motors
		}
	],
}
const typeMoteursomfy = {
	id: "VR-TR-AXE-MM-TM",
	type: formInputsTypes?.RADIO_INPUT,
	label: "Sélectionner un type de moteur",
	name: "typeMotor",
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: "Radio RTS",
			label: "",
			imageUrl: RTS,
			value: 5,
			nextStep: puissanceMoteur
		},
		{
			id: "Radio IO",
			label: "",
			imageUrl: IO,
			value: 4,
			nextStep: puissanceMoteur
		},
		{
			id: "Filaire",
			label: "",
			imageUrl: Filaire,
			value: 1,
			nextStep: puissanceMoteur
		},
		{
			id: "Solaire",
			label: "",
			imageUrl: Solaire,
			value: 3,
			nextStep: motors
		}
	],
}
const Moteur = {
	id: 'Moteur',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sélectionner la marque de moteur',
	name: 'Moteur',
	classGroup: "SProduit",
	nextStepData: false,
	nextStep: false,
	options: [
		{
			id: 'Becker',
			label: '',
			value: 'Becker',
			imageUrl: Becker,
			nextStep: typeMoteurRadioFilaireSolaire,
		},
		{
			id: 'Somfy',
			label: '',
			value: 'Somfy',
			imageUrl: Somfy,
			nextStep: typeMoteursomfy,
		},
		{
			id: 'Bubendorff',
			label: '',
			value: 'Bubendorff',
			imageUrl: Bubendorff,
			nextStep: typeMoteurRadioFilaire,
		},
		{
			id: 'AOK',
			label: '',
			value: 'A-OK',
			imageUrl: a_ok,
			nextStep: typeMoteurRadioFilaireSolaire,
		},
		{
			id: 'Eckermann',
			label: '',
			value: 'Eckermann',
			imageUrl: Eckermann,
			nextStep: typeMoteurRadioFilaire,
		},
		{
			id: 'Gaposa',
			label: '',
			value: 'Gaposa',
			imageUrl: Gaposa,
			nextStep: typeMoteurRadioFilaireSolaire,
		},
	],

};

/* const axeVoletRoulantMo = {
	id:"VR-TR-AXE-API",
	type:formInputsTypes?.SELECT_INPUT,
	label:"Sélectionner un axe",
	name:"axe",
	nextStepData:marqueMoteur,
	nextStep:false,
	options:null,
	fetchData: (tokenUserCurent,currentFormValue,fnSuccess) => {
		const generateFilter = () => {
			const filters = [
				["categ_id","=",CATEG_ID_AXE]
			]
		    
			return(
				filters
			)
		}
		searchProduct(
			tokenUserCurent,
			generateFilter(currentFormValue),
			({elements})=>{
				const options = elements?.length? elements.map((item=>({
					id:item?.id,
					label:item?.display_name,
					value:item?.id,
					lst_price:item?.lst_price,
					diametre:item?.diametre
				}))):
				[];
				fnSuccess(options);
			}
		)
	}
    
} */

export default Moteur;
