const BASIC_API_URL = process.env.REACT_APP_BASIC_API_URL,
	API_URL_FUNCTION_UTILS = process.env.REACT_APP_API_URL_FUNCTION_UTILS,
	IMAGE_API_URI = process.env.REACT_APP_IMAGE_API_URI,
	REPORT_API_URI = process.env.REACT_APP_API_REPORT_API_URI,
	REPORT_PLANNING_API_URI = process.env.REACT_APP_API_REPORT_PLANNING_API_URI;
export const getImageByModule = (tokenUserCurent, idProduct, module) => {
	return (
		BASIC_API_URL +
		API_URL_FUNCTION_UTILS +
		IMAGE_API_URI +
		`${module}/${idProduct}?token=${tokenUserCurent}`
	);
};

export const getReport = (tokenUserCurent, idProduct, module) => {
	return (
		BASIC_API_URL +
		API_URL_FUNCTION_UTILS +
		REPORT_API_URI +
		`/${tokenUserCurent?.company?.userName}/${module}/${idProduct}?token=${tokenUserCurent?.tokenOdoo}`
	);
};

export const getReportProduction = (tokenUserCurent, idProduct, module, fab) => {
	return (
		BASIC_API_URL +
		API_URL_FUNCTION_UTILS +
		REPORT_API_URI +
		`/${tokenUserCurent?.company?.userName}/${module}/${idProduct}?fab=${fab}&token=${tokenUserCurent?.tokenOdoo}`
	);
};
export const getReportPlanning = (tokenUserCurent, idProduct) => {
	return (
		BASIC_API_URL +
		API_URL_FUNCTION_UTILS +
		REPORT_PLANNING_API_URI +
		`/${tokenUserCurent?.company?.userName}/${idProduct}?token=${tokenUserCurent?.tokenOdoo}`
	);
};

export const formatDate = (d = new Date()) => {
	//var d = new Date;
	const padLeft = function (number, base, chr) {
		var len = String(base || 10).length - String(number).length + 1;
		return len > 0 ? new Array(len).join(chr || '0') + number : number;
	};
	const dformat =
		[d.getFullYear(), padLeft(d.getMonth() + 1), padLeft(d.getDate())].join('-') +
		' ' +
		[padLeft(d.getHours()), padLeft(d.getMinutes()), padLeft(d.getSeconds())].join(':');
	return dformat;
};

export function getPreviousDay(date = new Date()) {
	const previous = new Date(date.getTime());
	previous.setDate(date.getDate() - 1);

	return previous;
}

export const convertURL = async (url, name, fnSuccess, fnError = () => { }) => {
	try {
		const response = await fetch(url);
		const blob = await response.blob();

		const file = new File([blob], name + '.pdf', {
			type: 'application/pdf',
		});

		fnSuccess(file);
	} catch (error) {
		fnError(error);
	}
};

export const convertIMG = async (url, name, fnSuccess, fnError = () => { }) => {
	try {
		const response = await fetch(url);
		console.log('url', url);
		const blob = await response.blob();
		const img = new File([blob], name + '.png', {
			type: 'image/png',
		});

		fnSuccess(img);
	} catch (error) {
		fnError(error);
	}
};

export function capitalizeWords(str) {
	return str.replace(/\b\w/g, (match) => match.toUpperCase());
}
export const ImageIcon = (fileType) => {
	const image = ['image', 'jpeg', 'jpg', 'png', 'ico'];
	const pdf = ['application', 'pdf'];
	const video = [, 'video', 'mp4', 'mov', 'mkv'];
	if (image.some((type) => fileType.includes(type))) {
		return 'InsertPhoto';
	} else if (video.some((type) => fileType.includes(type))) {
		return 'OndemandVideo';
	} else if (pdf.some((type) => fileType.includes(type))) {
		return 'PictureAsPdf';
	} else {
		return 'AttachFile';
	}
};

const today = new Date()
export const dateOptions = [
	{
		value: 'today',
		label: "Aujourd'hui",
		startDate: new Date().toISOString(),
		endDate: new Date().toISOString(),
	},
	{
		value: 'last_7_days',
		label: 'Les 7 derniers jours',
		startDate: new Date(today.setDate(today.getDate() - 7)).toISOString(),
		endDate: new Date().toISOString(),
	},
	{
		value: 'last_30_days',
		label: 'Les 30 derniers jours',
		startDate: new Date(today.setDate(today.getDate() - 30)).toISOString(),
		endDate: new Date().toISOString(),
	},
	{
		value: 'last_365_days',
		label: 'Derniers 365 jours',
		startDate: new Date(today.setDate(today.getDate() - 365)).toISOString(),
		endDate: new Date().toISOString(),
	},

	{
		value: 'this_week',
		label: 'Cette semaine',
		startDate: new Date(today.setDate(today.getDate() - today.getDay())).toISOString(),
		endDate: new Date().toISOString(),
	},
	{
		value: 'this_month',
		label: 'Ce mois',
		startDate: new Date(today.setDate(1)).toISOString(),
		endDate: new Date().toISOString(),
	},
	{
		value: 'this_quarter',
		label: 'Ce trimestre',
		startDate: new Date(today.setMonth(today.getMonth() - (today.getMonth() % 3))).toISOString(),
		endDate: new Date().toISOString(),
	},
	{
		value: 'this_year',
		label: 'Cette année',
		startDate: new Date(today.setFullYear(today.getFullYear(), 0, 1)).toISOString(),
		endDate: new Date().toISOString(),
	},
	{
		value: 'yesterday',
		label: 'Hier',
		startDate: new Date(today.setDate(today.getDate() - 1)).toISOString(),
		endDate: new Date(today.setDate(today.getDate() - 1)).toISOString(),
	},
	{
		value: 'last_week',
		label: 'La semaine dernière',
		startDate: new Date(today.setDate(today.getDate() - today.getDay() - 7)).toISOString(),
		endDate: new Date(today.setDate(today.getDate() - today.getDay() - 1)).toISOString(),
	},
	{
		value: 'last_month',
		label: 'Le mois dernier',
		startDate: new Date(today.setMonth(today.getMonth() - 1, 1)).toISOString(),
		endDate: new Date(today.setMonth(today.getMonth(), 0)).toISOString(),
	},
	{
		value: 'last_quarter',
		label: 'Dernier trimestre',
		startDate: new Date(today.setMonth(today.getMonth() - 3, 1)).toISOString(),
		endDate: new Date(today.setMonth(today.getMonth(), 0)).toISOString(),
	},
	{
		value: 'last_year',
		label: "L'année dernière",
		startDate: new Date(today.setFullYear(today.getFullYear() - 1, 0, 1)).toISOString(),
		endDate: new Date(today.setFullYear(today.getFullYear(), 0, 0)).toISOString(),
	},
];

export const formattedNumber = (number = 0) => { return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }
