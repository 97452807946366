import { getSingleProduct, searchProduct } from '../../../../../../../odooApi/product';
import { searchProductAttribute } from '../../../../../../../odooApi/productAttribute';
import { searchProductData } from '../../../../../../../odooApi/productData';
import { getSingleProductTemplate } from '../../../../../../../odooApi/productTemplate';
import { prix_VRCManuelReno } from '../../../../../CalculSimulateurData.js/VRCompletManuel';
import formInputsTypes from '../../../../simulatorConstant';
import {
	CATEG_ID_AXE,
	CATEG_ID_EMBOU,
	PRODUCT_ID_AXE_MANUEL_TRADI,
	PRODUCT_ID_PLAQUE,
	PRODUCT_ID_VR_COMPLET,
} from '../../axe';
import { PRODUCT_ID_TABLIER_TRADI } from '../../tablier';

const PRODUCT_TEMPL_ID_COFFRE = 126;
const PRODUCT_TEMPL_ID_CAISSON = 127;
export const PRODUCT_TEMPL_ID_TAB = 24;
export const PRODUCT_VOLET_COMPLET = 6204;

const embout = {
	id: 'embout',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Embout',
	name: 'embout',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let price = 0;

		const m2 = (currentFormValue?.largeur * currentFormValue?.hauteur) / 1000;
		if (m2 <= prix_VRCManuelReno[0]?.id) {
			price = prix_VRCManuelReno[0]?.price;
		} else if (m2 > prix_VRCManuelReno?.length?.id) {
			price = prix_VRCManuelReno[prix_VRCManuelReno?.length].price;
		} else {
			for (var i = 0; i < prix_VRCManuelReno.length - 1; i++) {
				// console.log('prix', prix[i]);
				if (m2 > prix_VRCManuelReno[i]?.id && m2 <= prix_VRCManuelReno[i + 1]?.id) {
					price = prix_VRCManuelReno[i + 1]?.price;
				}
			}
		}
		const generateFilter = () => {
			const filters = [
				//"&",
				['categ_id', '=', CATEG_ID_EMBOU],
				//["diametre","=",currentFormValue?.axe?.diametre]
			];

			return filters;
		};

		searchProduct(tokenUserCurent, generateFilter(), ({ elements }) => {
			getSingleProduct(PRODUCT_ID_PLAQUE, tokenUserCurent, (dataPlaque) => {
				searchProduct(
					tokenUserCurent,
					[
						'&',
						['product_tmpl_id', '=', PRODUCT_TEMPL_ID_CAISSON],
						'&',
						[
							'product_template_attribute_value_ids.name',
							'=',
							currentFormValue?.coffreColor?.label,
						],
						['Taille', '=', currentFormValue?.coffre?.Taille],
					],
					(dataCaisson) => {
						const caisson = dataCaisson?.elements[0];
						const generateDescription = (item) => {
							return (
								`${currentFormValue?.product?.value} \n Volet roulant fabriqué en aluminium de haute qualité injecté avec précision pour garantir une solidité exceptionnelle et résistance aux intempéries et aux agressions extérieures. \n Ce volet roulant manuel est équipé d'un mécanisme de commande convivial qui permet de l'ouvrir et de le fermer en un seul geste. \n Vous pouvez compter sur sa simplicité d'utilisation au quotidien. \n \n` +
								`\n- Coulisse: ${currentFormValue?.coulisse?.label || ''} ` +
								`\n- Coffre: ${currentFormValue?.coffre?.label}` +
								`\n- Tablier ${currentFormValue?.lames?.label}` +
								`\n- Axe: ${currentFormValue?.axe?.label} ` +
								`\n- Manoeuvre Manuel: ${currentFormValue?.manoeuvreManuel?.label}` +
								// `\n- Treuil: ${currentFormValue?.treuil?.label} ` +
								`\n- Accessoires ` +
								`\n- Dimensions L: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
								` x H: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
								`${currentFormValue?.isWithAgraffe?.value == 'oui'
									? '\n- Verrou: ' +
									(Math.ceil(currentFormValue?.largeur / 1000) + 1) +
									' ' +
									currentFormValue?.agraffe?.label
									: ''
								}` +
								`\nConforme à la norme NF EN 13241-1 `
							);
						};

						const options = elements?.length
							? elements.map((item) => ({
								id: item?.id,
								product_id: PRODUCT_VOLET_COMPLET,
								description: generateDescription(item),
								production: [
									currentFormValue?.lames?.id,
									currentFormValue?.coulisse?.id,
									currentFormValue?.axe?.id,
									currentFormValue?.manoeuvreManuel?.id,
									currentFormValue?.treuil?.id,
									currentFormValue?.coffre?.id,
									item?.id,
								],
								largeur: currentFormValue?.largeur,
								hauteur: currentFormValue?.hauteur,
								verrou: [
									currentFormValue?.agraffe?.id || 0,
									Math.ceil(currentFormValue?.largeur / 1000) + 1,
								],
								label: item?.display_name,
								value: item?.id,
								lst_price: price,
								// lst_price: parseFloat(
								// 	(
								// 		item?.lst_price +
								// 		(Math.ceil(currentFormValue?.largeur / 1000) + 1) *
								// 			(currentFormValue?.agraffe?.lst_price || 0) +
								// 		currentFormValue?.lames?.lst_price +
								// 		currentFormValue?.lameFinal?.lst_price +
								// 		currentFormValue?.coulisse?.lst_price +
								// 		(currentFormValue?.coffre?.lst_price *
								// 			currentFormValue?.largeur) /
								// 			1000 +
								// 		(caisson?.lst_price * currentFormValue?.largeur) /
								// 			1000 +
								// 		(currentFormValue?.axe?.lst_price *
								// 			currentFormValue?.largeur) /
								// 			1000 +
								// 		currentFormValue?.manoeuvreManuel?.lst_price +
								// 		currentFormValue?.treuil?.lst_price +
								// 		dataPlaque.lst_price
								// 	).toFixed(2),
								// ),
							}))
							: [];
						fnSuccess(options);
					},
				);
			});
		});
	},
};

const axeTreuil = {
	id: 'treuil',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Treuil',
	name: 'treuil',
	nextStepData: embout,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', 97]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(currentFormValue), async ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => ({
					id: item?.id,
					product_id: PRODUCT_ID_AXE_MANUEL_TRADI,
					label: item?.display_name,
					value: item?.id,
					lst_price: item?.lst_price,
				}))
				: [];
			fnSuccess(options);
		});
	},
};

const manoeuvreManuel = {
	id: 'manoeuvreManuel',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Manœuvre manuel',
	name: 'manoeuvreManuel',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', 101]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(currentFormValue), ({ elements }) => {
			const generateDescription = (item) => {
				return (
					`${currentFormValue?.product?.value} \n Volet roulant fabriqué en aluminium de haute qualité injecté avec précision pour garantir une solidité exceptionnelle et résistance aux intempéries et aux agressions extérieures. \n Ce volet roulant manuel est équipé d'un mécanisme de commande convivial qui permet de l'ouvrir et de le fermer en un seul geste. \n Vous pouvez compter sur sa simplicité d'utilisation au quotidien. \n \n` +
					`\n- ${currentFormValue?.coulisse?.label || ''} ` +
					`\n- ${currentFormValue?.coffre?.label}` +
					`\n- Tablier ${currentFormValue?.lames?.label}` +
					// `\n- Axe: ${currentFormValue?.axe?.label} ` +
					`\n- Manoeuvre Manuel: ${item?.name}` +
					// `\n- Treuil: ${currentFormValue?.treuil?.label} ` +
					`\n- Accessoires ` +
					`\n- Dimensions L: ${currentFormValue?.largeur}mm (${currentFormValue?.typeMesureLargeur?.value})` +
					` x H: ${currentFormValue?.hauteur}mm (${currentFormValue?.typeMesureHauteur?.value})` +
					`${currentFormValue?.isWithAgraffe?.value == 'oui'
						? '\n- ' +
						(Math.ceil(currentFormValue?.largeur / 1000) + 1) +
						' verrous'
						: ''
					}` +
					`\nConforme à la norme NF EN 13241-1 `
				);
			};

			const options = elements?.length

				? elements.map((item) => (
					{

						id: item?.id,
						product_id: PRODUCT_VOLET_COMPLET,
						description: generateDescription(item),
						production: [
							currentFormValue?.lames?.id,
							currentFormValue?.coulisse?.id,
							//currentFormValue?.axe?.id,
							currentFormValue?.coffre?.id,
							item?.id,
						],
						largeur: currentFormValue?.largeur,
						hauteur: currentFormValue?.hauteur,
						verrou: [
							4503,
							Math.ceil(currentFormValue?.largeur / 1000) + 1,
						],
						label: item?.name,
						value: item?.id,
						lst_price: item?.lst_price,

					}))
				: [];
			fnSuccess(options);
			// const options = elements?.length
			// 	? elements.map((item) => ({
			// 			id: item?.id,
			// 			label: item?.display_name,
			// 			value: item?.id,
			// 			lst_price: item?.lst_price,
			// 	  }))
			// 	: [];
			// fnSuccess(options);
		});
	},
};

const coffre = {
	id: 'VR-TR-TB-COFFRE',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'coffre',
	className: 'col-6',
	label: 'Coffre',
	defaultValue: 0,
	nextStepData: manoeuvreManuel,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSucces) => {
		searchProductData(
			tokenUserCurent,
			[
				'&',
				['marque', '=', 'Lames'],
				'&',
				['Type', '=', currentFormValue?.lameTaille?.label],
				// '&',
				// ['diametre', '=', currentFormValue?.axe?.diametre],
				['Hauteur', '>=', parseInt(currentFormValue?.hauteur)],
			],
			({ elements }) => {
				const generateFilter = () => {
					const filters = [
						'&',
						['product_tmpl_id', '=', PRODUCT_TEMPL_ID_COFFRE],
						'&',
						[
							'product_template_attribute_value_ids.name',
							'=',
							currentFormValue?.coffreColor?.label,
						],
						['Taille', '=', elements[0]?.couple],
					];
					return filters;
				};

				elements[0]?.couple &&
					searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
						const options = elements?.length
							? elements.map((item) => {
								const { product_template_attribute_value_ids } = item;
								let name = 'Coffre aluminium ';
								product_template_attribute_value_ids.map(
									(i) => (name += i.name + ' '),
								);
								return {
									id: item?.id,
									label: name,
									value: item?.id,
									Taille: item?.Taille,
									checked: true,
									lst_price: parseFloat(
										(
											(item?.lst_price * currentFormValue?.largeur) /
											1000
										).toFixed(2),
									),
								};
							})
							: [];
						// const options = elements?.length
						// 	? elements.map((item) => {
						// 			return {
						// 				id: item?.id,
						// 				label: item?.display_name,
						// 				value: item?.id,
						// 				Taille: item?.Taille,
						// 				checked: true,
						// 				lst_price: parseFloat(
						// 					(
						// 						(item?.lst_price * currentFormValue?.largeur) /
						// 						1000
						// 					).toFixed(2),
						// 				),
						// 			};
						// 	  })
						//	: [];
						fnSucces(options);
					});
			},
		);
	},
};

const coffreColor = {
	id: 'VR-TR-TB-CFC',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'coffreColor',
	classGroup: "Simulateur_color",
	label: 'Couleur de coffre',
	defaultValue: 0,
	nextStepData: coffre,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		getSingleProductTemplate(tokenUserCurent, PRODUCT_TEMPL_ID_TAB, ({ data }) => {
			const options = data.attribute_line_ids[0]?.value_ids?.length
				? data.attribute_line_ids[0]?.value_ids.map((item) => {
					return {
						id: item?.id,
						label: item?.name,
						product_id: PRODUCT_ID_TABLIER_TRADI,
						value: item?.id,
						color: item?.html_color,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

export default coffreColor;
