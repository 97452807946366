import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FC, ReactNode, useContext } from 'react';
import { PanierProvider } from '../../contexts/PanierContext';
import { ActivitiesContextProvider } from '../../contexts/activityContext';
import { ClientMontantContextProvider } from '../../contexts/clientMontantContext';
import { MissedCallsContextProvider } from '../../contexts/missedCallsContext';
import { PoseContextProvider } from '../../contexts/poseContext';
import ThemeContext from '../../contexts/themeContext';
import Content from '../Content/Content';
import FooterRoutes from '../Footer/FooterRoutes';
import HeaderRoutes from '../Header/HeaderRoutes';
import WrapperOverlay from './WrapperOverlay';

interface IWrapperContainerProps {
	children: ReactNode;
	className?: string;
}
export const WrapperContainer: FC<IWrapperContainerProps> = ({ children, className, ...props }) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames(
				'bg-l25-light',
				'wrapper',
				{ 'wrapper-right-panel-active': rightPanel },
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};
WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
WrapperContainer.defaultProps = {
	className: undefined,
};

const Wrapper = () => {
	return (
		<>
			<PanierProvider>
				<ActivitiesContextProvider>
					<PoseContextProvider>
						<MissedCallsContextProvider>
							<ClientMontantContextProvider>
								<WrapperContainer>
									<HeaderRoutes />
									<Content />
									<FooterRoutes />
								</WrapperContainer>
							</ClientMontantContextProvider>
						</MissedCallsContextProvider>
					</PoseContextProvider>
				</ActivitiesContextProvider>
			</PanierProvider>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
