import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import AuthContext from '../../../contexts/authContext';
import { searchAPI } from '../../../nodeApi/cruds';
import Button from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Spinner from '../../bootstrap/Spinner';
import SearchClient from '../../Clients/SearchClient';
import useOutsideClick from '../../customComponents/SearchAutoComplete/useOutsideClick';
import Icon from '../../icon/Icon';

export default function SearchBoxClient({ setSelectedClient, selectedClient, handelSelect }) {
    const { userData } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [clientIsLoading, setClientIsLoading] = useState(false);
    const [clientData, setClientData] = useState({});
    const [filterResult, setfilterResult] = useState({});
    const inputRef = useRef(null);
    const [clientValues, setClientValues] = useState({});
    const [searchItem, setSearchItem] = useState('');
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [openListItems, setOpenListItems] = useState(false);
    const [updateClientModal, setUpdateClientModal] = useState(false)
    const [newClient, setNewClient] = useState(false)

    const handleClickOutside = () => {
        setOpenList(false);
    };
    const ref = useOutsideClick(handleClickOutside);


    const formik = useFormik({
        initialValues: {
            name: selectedClient?.name || '',

        },

    });

    const handelChange = (e) => {
        formik?.setFieldValue('name', e?.target?.value);
        //setSearchItem(e.target.value);
        setOpenListItems(true);
    };


    const handelCreateClient = () => {
        setSelectedClient({});
        setUpdateClientModal(true);
        setNewClient(true)
    }

    const handelSelectItem = (item) => {
        formik?.setFieldValue('name', item.name);
        setfilterResult([]);
        setSelectedClient(item);
        setSearchIsLoading(false)
        setOpenListItems(false)


    };


    const loadData = async (item = '') => {
        setSearchIsLoading(true);
        const result = await searchAPI(userData?.accessToken, "customer", !!item ? item : {}, 1, 10, () => { }, () => { }, null);
        const customers = result?.elements || [];
        setfilterResult(customers);
        setSearchIsLoading(false);

    };

    useEffect(() => {

        if (formik?.values?.name === '') {
            setfilterResult([]);
            setSearchIsLoading(false);
        } else {

            loadData(!!formik?.values?.name ? {
                "$or": [
                    { "name": { "$regex": `${formik?.values?.name}`, "$options": "i" } },
                    { "email": { "$regex": `${formik?.values?.name}`, "$options": "i" } },
                    { "phone": { "$regex": `${formik?.values?.name}`, "$options": "i" } }
                ]
            } : {});
        }


    }, [formik?.values?.name]);

    return (
        <>

            <div ref={ref} className='row'>
                <FormGroup className='autocomplete col-lg-8'>
                    <div className='d-flex align-items-center bg-l10-brand-two rounded-2' style={{height:'20px'}}>
                        <Input
                            ref={inputRef}
                            onChange={handelChange}
                            //onFocus={handleChangeClient}
                            value={formik?.values?.name}
                            placeholder='Rechercher...'
                            type='text'
                            autoComplete='off'
                            className='border-0 bg-transparent'
                        />

                    </div>
                    {openListItems &&
                        (searchIsLoading ? (
                            <div
                                id='myInputautocomplete-list'
                                className='autocomplete-items col-12 position-absolute'>
                                <div className='body'>
                                    <div>
                                        <Spinner isSmall isGrow /> <Spinner isSmall isGrow />{' '}
                                        <Spinner isSmall isGrow />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                id='myInputautocomplete-list'
                                className='autocomplete-items position-absolute'>
                                <div className='body'>
                                    {
                                        !!filterResult?.length && (
                                            filterResult?.map((item) => (

                                                <div
                                                    key={item?.id}
                                                    onClick={() => {
                                                        handelSelectItem(item);
                                                    }}>
                                                    <Icon
                                                        color={'primary'}
                                                        isLight
                                                        icon={'Person'}
                                                        size='lg'
                                                    />
                                                    {item?.name}{' '}
                                                    <Icon
                                                        color={'primary'}
                                                        isLight
                                                        icon={'Place'}
                                                        size='lg'
                                                    />
                                                    {item?.street +
                                                        ' ' +
                                                        item?.zip +
                                                        ' ' +
                                                        item?.city}
                                                </div>


                                            ))
                                        )}
                                    <Button className='col-12'
                                        color='dark'
                                        isLight
                                        onClick={() => { handelCreateClient(); setOpenListItems(false) }}>Crèer et modifier ...</Button>

                                </div>
                            </div>

                        ))}


                </FormGroup>

                {Object.keys(selectedClient).length > 0 && (
                    <div className='col-lg-4'>
                        <Button
                            icon='Edit'
                            isLight
                            color='primary'
                            onClick={() => { setUpdateClientModal(true) }}>
                            Modifier
                        </Button>
                    </div>
                )}

            </div>

            {updateClientModal && (
                <SearchClient
                    setCreateClientModalStatus={setUpdateClientModal}
                    handelSelect={handelSelect}
                    data={Object.keys(selectedClient).length ? selectedClient : { name: formik?.values?.name }}
                    onChange={handelChange}
                />
            )}

        </>
    )
}
