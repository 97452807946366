const axios = require("axios");

const BACK_API_URL = "/back",
  MODULE_URI = "/module";

export const createModule = (
  tokenUserCurent,
  data,
  fnSuccess,
  fnError = () => {}
) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: BACK_API_URL + MODULE_URI,
    headers: {
      Authorization: `Basic ${tokenUserCurent}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  axios
    .request(config)
    .then((response) => {
      fnSuccess(response.data);
    })
    .catch((error) => {
      console.log(error);
      fnError(error);
    });
};


export const getAllParentModules = (tokenUserCurent, fnSuccess, fnError=()=>{}) => {

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: BACK_API_URL+MODULE_URI,
    headers: {
      'Authorization': `Basic ${tokenUserCurent}`, 
      "Content-Type": "application/json",
    },
    
  };

  axios
    .request(config)
    .then((response) => {
      fnSuccess(response.data);
    })
    .catch((error) => {
      console.log(error);
      fnError(error);
    });
};



export const updateModule = (tokenUserCurent, idModule, data, fnSuccess, fnError=()=>{}) => {

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: BACK_API_URL+MODULE_URI+"/"+idModule,
      headers: {
          'Authorization': `Basic ${tokenUserCurent}`, 
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data)
    };
  
    axios
      .request(config)
      .then((response) => {
        fnSuccess(response.data);
      })
      .catch((error) => {
        console.log(error);
        fnError(error);
      });
};

export const deleteModule = (tokenUserCurent, idModule, fnSuccess, fnError=()=>{}) => {

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: BACK_API_URL+MODULE_URI+"/"+idModule,
      headers: {
          'Authorization': `Basic ${tokenUserCurent}`, 
        "Content-Type": "application/json",
      },
      
    };
  
    axios
      .request(config)
      .then((response) => {
        fnSuccess(response.data);
      })
      .catch((error) => {
        console.log(error);
        fnError(error);
      });
};


export const getModuleById = (tokenUserCurent, idModule, fnSuccess, fnError=()=>{}) => {

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: BACK_API_URL+MODULE_URI+"/"+idModule,
      headers: {
          'Authorization': `Basic ${tokenUserCurent}`, 
        "Content-Type": "application/json",
      },
      
    };
  
    axios
      .request(config)
      .then((response) => {
        fnSuccess(response.data);
      })
      .catch((error) => {
        console.log(error);
        fnError(error);
      });
};