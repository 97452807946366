import axios from 'axios';
const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = '/web/image',
	URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	IMAGE_API_URI = '/ir.attachment';

const fetchImage = (fncSuccess) => {
	var config = {
		method: 'GET',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + '?model=res.users&id=2&field=image_1920'}`,
		headers: {
			Authorization: 'Basic QXJ0aXNhbjJnbzoxNGE3Yjg3MC1hY2Y2LTQxNTItOGYxNS1hM2YwYTk4OTM5NmQ=',
		},
	};

	axios(config)
		.then((response) => {
			/* let blob = new Blob(
		[JSON.stringify(response.data)],
		{ type:"image/PNG",endings: 'native' }
	  ); */

			let base64ToString = Buffer.from(response.data, 'base64').toString();
			console.log('responseresponse', base64ToString);

			fncSuccess(base64ToString);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export default fetchImage;

export const deleteImageAPI = (currentUser, idImage, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + URL_WEB_ADMIN + IMAGE_API_URI + '/' + idImage}`,
		headers: {
			Authorization: `Basic ${btoa(
				currentUser?.company?.userName + ':' + currentUser?.tokenOdoo,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
