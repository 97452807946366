import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Textarea from '../bootstrap/forms/Textarea';
import AuthContext from '../../contexts/authContext';
import { createDevisLine, deleteDevisLine, getDevisLinesById, updateDevisLine } from '../../odooApi/lineDevis';
import DevisLinesContext from '../../contexts/devisLinesContext';
import CardAddProdectLineDevis from './CardAddProdectLineDevis';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import { searchAccountTax } from '../../odooApi/accountTax';
import Icon from '../icon/Icon';
import { useTranslation } from 'react-i18next';
import { searchProduct } from '../../odooApi/product';
import Spinner from '../bootstrap/Spinner';
import { createInvoiceLine, deleteInvoiceLine, getAccountMoveLinesById, updateInvoiceLine } from '../../odooApi/accountMoveLine';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import FactureLinesContext from '../../contexts/factureLineContext';
import AddProductPopUp from '../Achats/SingleAchat/LinesTable/AddProduct';

const PopUpUpdateAndCreateLineDevis = ({ setisOpen, data, setEditModalLineStatus, create, loadData, moveLine }) => {
    const { userData } = useContext(AuthContext);
    const { setDevisLines } = useContext(FactureLinesContext);
    const [selectProduct, setSelectProduct] = useState({});
    const [taxs, setTaxs] = useState([5]);
    const ref = useRef(null);
    const [datas, setDatas] = useState([]);
    const [openList, setOpenList] = useState(false);
    const { t } = useTranslation(['translation', 'menu']);

    const [createProductModalStatus, setCreateProductModalStatus] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {

        searchAccountTax(
            userData,
            [
                "&",
                ["type_tax_use", "=", "sale"],
                ["active", "=", true]
            ],
            (taxsData) => {
                setTaxs(taxsData?.elements);
            }
        );


    }, [userData])
    const handelDelete = () => {

        deleteInvoiceLine(
            userData,
            moveLine?.id,
            () => {
                setEditModalLineStatus(false);

            }
        );


    }
    const handelUpdate = (values) => {
        const newLineDevis = {
            id: values.id,
            name: values?.name,
            price_unit: values?.price_unit,
            discount: values?.discount,
            quantity: values?.product_uom_qty,

        }
        updateInvoiceLine(
            userData,
            newLineDevis,
            () => {
                getDevisLinesById(
                    userData,
                    moveId,
                    ({ elements }) => {

                        setEditModalLineStatus(false);

                    }
                );

            }
        )


    }
    const handelCreate = (values) => {

        const newLine = {
            move_id: parseInt(data),
            product_id: selectProduct?.id,
            name: values?.name || (create && selectProduct?.display_name) || "",
            quantity: values?.product_uom_qty || 1,
            product_uom_id: 1,
            price_unit: values?.price_unit || create && selectProduct?.lst_price || 0,
            discount: values?.discount,
            tax_ids: [1],
            account_id: 653,
            amount_currency: - values?.price_unit,
            currency_id: 1

        }

        createInvoiceLine(
            userData,
            newLine,
            () => {
                getAccountMoveLinesById(
                    userData,
                    data,
                    ({ elements }) => {
                        setEditModalLineStatus(false);

                    }
                );


            }
        );

    }
    const formik = useFormik({
        initialValues: {
            id: moveLine?.id,
            display_name: moveLine?.display_name || "",
            name: moveLine?.name,
            price_unit: moveLine?.price_unit || 0,
            product_uom_qty: moveLine?.product_uom_qty || 1,
            discount: moveLine?.discount?.toFixed(2) || 0,
            price_subtotal: moveLine?.price_subtotal,
            nom_produit: moveLine?.nom_produit,
            tax_ids: !!moveLine?.tax_id?.length ? moveLine?.tax_id[0] : null
        },

        onSubmit: (values) => {

            if (create) {
                handelCreate(values);
            } else {
                handelUpdate(values)

            }


        },
    });


    const calculSubTotal = () => {
        const tvaPercentage = !!taxs?.length && taxs?.filter(tax => tax.id == (formik?.values?.tax_id || !!taxs?.length && taxs[0]?.id))[0]?.amount;
        const discountPrrice = ((formik?.values?.product_uom_qty * (formik?.values?.price_unit || create && selectProduct?.lst_price || 0)) / 100) *
            (100 - formik?.values?.discount);

        return ((discountPrrice / 100) * (100 + (tvaPercentage || 0))).toFixed(2) || 0
    }


    const handleChangeFilter = (filter) => {
        setSpinner(true);
        searchProduct(
            userData,
            filter,
            ({ elements }) => {
                setDatas(elements);
                setSpinner(false);
                setError(false);
            },
            (error) => {
                setError(error);
            },
            1,
            10,
        );
    };
    const handleChangeSearch = (e) => {
        setOpenList(true);
        formik?.setFieldValue('searchInput', e?.target?.value);
        if (e?.target?.value == '') {
            setDatas([]);
        } else {
            handleChangeFilter([
                '|',
                ['name', 'like', e.target.value],
                '|',
                [
                    'name',
                    'like',
                    e.target.value.charAt(0).toUpperCase() +
                    e.target.value.substring(1).toLowerCase(),
                ],
                '|',

                ['name', 'like', e.target.value.toUpperCase()],
                '|',
                ['default_code', 'like', e.target.value],
                '|',
                [
                    'default_code',
                    'like',
                    e.target.value.charAt(0).toUpperCase() +
                    e.target.value.substring(1).toLowerCase(),
                ],
                ['default_code', 'like', e.target.value.toUpperCase()],
            ]);
        }
    };
    const handleSelectedProduct = (item) => {
        setSelectProduct(item);
        formik.setValues({
            name: item?.name
        })
        setOpenList(false);
        setDatas([]);
    };
    return (
        <Modal setIsOpen={() => { }} isOpen={true} isScrollable classNameMd='nv-3'>
            <ModalHeader
                className='px-4 pb-0'
                setIsOpen={() => {
                    setEditModalLineStatus(false);
                }}></ModalHeader>
            <ModalBody className='pt-0 h-fluid-min-100'>
                <Card shadow='none'>
                    <CardHeader className='pt-0'>
                        <CardLabel icon='Info' iconColor='primary'>
                            <CardTitle>
                                {create ? 'Ajouter une nouvelle ligne' : t('Line Details')}
                            </CardTitle>
                        </CardLabel>
                        <CardActions>
                            {!data?.id && (
                                <Button
                                    icon='AddCircleOutline'
                                    color='success'
                                    isLight
                                    onClick={() => {
                                        setCreateProductModalStatus(true);
                                    }}>
                                    Créer un produit
                                </Button>
                            )}
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <div className='row g-4'>
                            {!data?.id && (
                                <div ref={ref} className='row justify-content-end mt-2'>
                                    <FormGroup
                                        className='autocomplete'
                                    //id='name'
                                    >
                                        <div className='d-flex align-items-center bg-l10-brand-two rounded-2 shadow-sm'>
                                            <label
                                                className='border-0 ms-2 me-0'
                                                htmlFor='searchInput'>
                                                <Icon icon='Search' size='2x' color='primary' />
                                            </label>

                                            <Input
                                                ref={ref}
                                                value={formik.values.searchInput}
                                                onChange={handleChangeSearch}
                                                placeholder='Rechercher...'
                                                type='text'
                                                autoComplete='off'
                                                className='border-0 bg-transparent'
                                            />
                                        </div>

                                        {openList && (
                                            <div
                                                id='myInputautocomplete-list'
                                                className='autocomplete-items position-absolute'>
                                                <div className='body'>
                                                    {spinner ? (
                                                        <div>
                                                            <Spinner isSmall isGrow />{' '}
                                                            <Spinner isSmall isGrow />{' '}
                                                            <Spinner isSmall isGrow />
                                                        </div>
                                                    ) : (
                                                        !!datas?.length &&
                                                        datas?.map((item) => (
                                                            <div
                                                                key={item?.id}
                                                                onClick={() => {
                                                                    handleSelectedProduct(item);
                                                                }}>
                                                                <Icon
                                                                    color={'primary'}
                                                                    icon={
                                                                        'ProductionQuantityLimits'
                                                                    }
                                                                    size='lg'
                                                                    className='me-2'
                                                                />
                                                                {item?.name}
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </FormGroup>
                                </div>
                            )}
                            {data?.id && (
                                <FormGroup className='col-12' id='ref' label={t('ref')}>
                                    <Input
                                        onChange={formik.handleChange}
                                        value={data?.id}
                                        disabled
                                    />
                                </FormGroup>
                            )}
                            {(!!selectProduct || !!data?.id) && (
                                <>
                                    {' '}
                                    <FormGroup
                                        className='col-12'
                                        id='name'
                                        label={t('Description')}>
                                        <Textarea
                                            onChange={formik.handleChange}
                                            value={formik?.values?.name}
                                            rows={6}
                                            name='name'
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='col-6'
                                        id='price_unit'
                                        label={t('Price unite')}>
                                        <Input
                                            type='number'
                                            onChange={formik.handleChange}
                                            value={formik?.values?.price_unit}
                                            name='price_unit'
                                            min={0}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='col-6'
                                        id='description'
                                        label={t('Quantity')}>
                                        <Input
                                            type='number'
                                            onChange={formik.handleChange}
                                            value={formik?.values?.product_uom_qty}
                                            name='product_uom_qty'
                                            min={1}
                                        />
                                    </FormGroup>

                                    <FormGroup
                                        className='col-12'
                                        id='description'
                                        label={t('Sous-total')}>
                                        <Input
                                            value={
                                                calculSubTotal() > 0
                                                    ? calculSubTotal().toLocaleString('fr-FR', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    })
                                                    : '0'.toLocaleString('fr-FR', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    })
                                            }
                                            disabled
                                        />
                                    </FormGroup>
                                </>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>

            <ModalFooter>
                {(!!selectProduct || !!data?.id) && (
                    <Button
                        color='primary'
                        className='w-100'
                        type='submit'
                        onClick={formik.handleSubmit}
                        disabled={isLoading}>
                        {isLoading && <Spinner isSmall inButton />}
                        {isLoading ? 'En cours' : 'Sauvegarder'}
                    </Button>
                )}

                {!create && (
                    <Button
                        color='danger'
                        className='w-100'
                        onClick={() => setIsConfirmed(true)}>
                        {t('Delete')}
                    </Button>
                )}
                {isConfirmed && (
                    <ConfirmationPopup
                        handleConfirm={handelDelete}
                        setOpen={setIsConfirmed}
                        title={'Supprimer la ligne de facture ?'}
                    />
                )}
            </ModalFooter>


            {!!error && <ErrorPopup error={error} setOpen={setError} />}
            {createProductModalStatus && (
                <AddProductPopUp
                    setCreateProductModalStatus={setCreateProductModalStatus}
                    handleSelectedProduct={handleSelectedProduct}
                />
            )}
        </Modal>
    )
}

PopUpUpdateAndCreateLineDevis.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    // @ts-ignore
    data: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    setEditModalLineStatus: PropTypes.func.isRequired,
    create: PropTypes.bool,

};

export default PopUpUpdateAndCreateLineDevis;