import { createContext, useContext, useState } from 'react';

// Create a context for WebSocket messages
const StockSocketContext = createContext();

export const useSocket = () => useContext(StockSocketContext);

// Create a provider to wrap the app
export const StockSocketProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState([]);
    const [socket, setSocket] = useState(null);


    // Function to add a new message to the state
    const addMessage = (message) => {
        setNewMessage(message)
        setMessages((prevMessages) => [...prevMessages, message]);
    };

    const getLastMessage = () => {
        return messages.length > 0 ? messages[messages.length - 1] : null;
    };

    const sendMessage = (message) => {
        if (socket) {
            socket.send(message); // Send the input value to the server
        }
    };

    return (
        <StockSocketContext.Provider value={{ messages, addMessage, socket, setSocket, getLastMessage, sendMessage, newMessage }}>
            {children}
        </StockSocketContext.Provider>
    );
};
